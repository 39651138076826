<template>
  <div>
    <h3 class='border-bottom pb-1 mt-3 mb-0'>Subscription Types</h3>

    <table class="w-100 mb-3">
      <tbody>
        <tr v-for="subscriptionType in searchResults" class='border-bottom' :key="subscriptionType.uuid">
          <td>
            <b-link v-b-modal="'modal-' + subscriptionType.uuid">
              {{ subscriptionType.name }}
            </b-link>
            <subscription-type-modal :uuid="subscriptionType.uuid" />
          </td>
          <td>
            <p v-if="subscriptionType.display_name">
              Display name: {{ subscriptionType.display_name }}
            </p>
          </td>
          <td>
            <p v-if="subscriptionType.pool_name">
              Pool name: {{ subscriptionType.pool_name }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import subscriptionTypeModal from '@/components/globals/subscription-type-modal.vue';

export default {
  name: 'EndpointSearchResults',

  components: {
    subscriptionTypeModal,
  },

  props: {
    searchResults: [],
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/custom/searches.scss';
</style>
