<template>
  <div class="container">
    <div class="row flex-center py-5">
      <div class="col-12">
        <div class="card">
          <div v-if="message" class="card-body p-7">
            <span class="display-1 d-flex flex-center mb-3">
              404
            </span>
            <span class="d-flex flex-center">
              {{ message }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'notFound',

  props: {
    message: { type: String },
  },

  computed: {
    ...mapGetters(
      'sessions', [
        'currentUser',
      ],
    ),
  },

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
      if (!this.message) {
        let redirectTo = 'me';

        if (!this.currentUser) {
          redirectTo = 'signIn';
        } else if (this.currentUser.is_admin) {
          redirectTo = 'admins'
        }

        this.$router.push({ name: redirectTo });
      }
    }
  }
};
</script>
