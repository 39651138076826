<template>
  <div class="table-responsive scrollbar">
    <div class="form-group">
      <b-form v-on:submit.prevent="searchRateTiers">
        <div class="form-group">
          <b-input
            v-model="searchRateTiersTerm"
            placeholder="Search by name"
            type="search"
          ></b-input>
        </div>
      </b-form>
    </div>

    <table class="table table-sm table-hover overflow-hidden mb-3">
      <thead>
        <tr class="btn-reveal-trigger">
          <th scope="col">Name</th>
          <th># Endpoints</th>
          <th class="text-right"># Tokens</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="3">
            <div class="text-center my-4">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </td>
        </tr>
        <template v-if="!isLoading">
          <tr v-for="rt in displayedItems" :key="rt.uuid" class="btn-reveal-trigger">
            <td>
              <router-link :to="{ name: 'editRateTier', params: { uuid: rt.uuid } }">
                {{ rt.name }}
              </router-link>
            </td>
            <td>
              {{ rt.active_endpoints_count }}
            </td>
            <td class="text-right">
              {{ rt.active_tokens_count }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <div v-if="!isLoading" class="overflow-auto">
      <b-pagination-nav
        v-if="rateTiers"
        :link-gen="linkGen"
        v-model="currentPage"
        :number-of-pages="totalPages"
        use-router
      />
    </div>

    <p v-if="rateTiers" class="fs--1">
      {{ totalCount }} Rate Tier{{ totalCount > 1 ? 's' : '' }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RateTiersTable',

  data() {
    return {
      searchRateTiersTerm: null,
      filteredRateTiers: [],
      currentPage: 1,
      perPage: 25
    }
  },

  created() {
    this.setFilteredRateTiers();

    if (this.$route.query.rate_tiers_page) {
      this.currentPage = parseInt(this.$route.query.rate_tiers_page);
    }
  },

  watch: {
    rateTiers: 'setFilteredRateTiers',
    searchRateTiersTerm: 'searchRateTiers'
  },

  computed: {
    ...mapState('rateTiers', ['rateTiers', 'isLoading']),

    totalCount() {
      return this.filteredRateTiers.length;
    },

    totalPages() {
      return Math.ceil(Math.max(this.totalCount, 1) / this.perPage);
    },

    displayedItems() {
      if (this.filteredRateTiers) {
        const startIndex = (this.currentPage - 1) * this.perPage;
        const endIndex = startIndex + this.perPage;
        return this.filteredRateTiers.slice(startIndex, endIndex);
      }
    }
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?rate_tiers_page=${pageNum}`;
    },

    searchRateTiers() {
      this.currentPage = 1;

      this.filteredRateTiers = this.rateTiers.filter(rateTier =>
        rateTier.name.toLowerCase().includes(this.searchRateTiersTerm.toLowerCase())
      );
    },

    setFilteredRateTiers() {
      if (this.rateTiers.length > 0) {
        this.filteredRateTiers = this.rateTiers;
      }
    }
  }
}
</script>
