<template>
  <section class="bg-light pt-6 pb-4 light">
    <div class="container-md">
      <div class="row">
        <div class="col-lg-4">
          <triton-logo :height="60"></triton-logo>
          <p class="text-600 col-lg-7">Fast, reliable and scalable RPC access</p>
        </div>

        <div class="col-lg-4"></div>

        <div class="col-lg-4 text-right">
          <div class="mt-5 mt-lg-0">
            <div class="mt-5 mt-md-0">
              <ul class="list-unstyled">
                <li v-if="currentUser">
                  <h5 class="fs-0 mb-3">
                    <router-link
                      :to="{ name: 'manageUserRpc', params: { uuid: currentUser.uuid } }"
                      class="text-600"
                    >
                      Manage My RPC
                    </router-link>
                  </h5>
                </li>

                <li v-if="hasAccountWithHelio">
                  <h5 class="fs-0 mb-3">
                    <a href="https://docs.triton.one/customers/payments" target="blank" class="text-600">
                      Payment help
                    </a>
                  </h5>
                </li>

                <li v-if="currentUser">
                  <h5 class="fs-0 mb-3">
                    <a href="https://docs.triton.one/rest-api-documentation/introduction" target="_blank" class="text-600">
                      REST API Docs
                    </a>
                  </h5>

                  <h5 class="fs-0 mb-3">
                    <a href="https://docs.triton.one" target="_blank" class="text-600">
                      General Support & Info
                    </a>
                  </h5>

                  <h5 class="fs-0 mb-3">
                    <a href="mailto:help@triton.one" target="_blank" class="text-600">
                      Email us
                    </a>
                  </h5>
                </li>

                <li v-if="currentUser && currentUser.is_admin">
                  <h5 class="fs-0 mb-3">
                    <a href="/admins" class="text-600">
                      Admin
                    </a>
                  </h5>
                </li>

                <li>
                  <h5 class="fs-0 mb-3">
                    <a v-if="currentUser" class="text-600" @click="openSignOutConfirmation">
                      Sign out
                    </a>
                    <router-link v-else to="/users/sign-in" class="text-600">
                      Sign In
                    </router-link>
                  </h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          © {{ new Date().getFullYear() }} RPC Pool by Triton
        </div>

        <div class="col text-right mt-3">
          <a class="mr-3 text-gray-500" href="https://t.me/joinchat/K0ONdq7fE4s0Mjdl">
            <span class="sr-only">Telegram</span>
            <svg width="32px" height="32px" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" color="#505050">
              <title>Telegram icon</title>
              <path d="M23.91 3.79L20.3 20.84c-.25 1.21-.98 1.5-2 .94l-5.5-4.07-2.66 2.57c-.3.3-.55.56-1.1.56-.72 0-.6-.27-.84-.95L6.3 13.7l-5.45-1.7c-1.18-.35-1.19-1.16.26-1.75l21.26-8.2c.97-.43 1.9.24 1.53 1.73z">
              </path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('sessions', [
      'currentUser',
      'hasAccountWithHelio'
    ]),
  },

  methods: {
    openSignOutConfirmation() {
      this.$bvModal.msgBoxConfirm("Are you sure you want to sign out?", {
        okVariant: 'primary',
        okTitle: 'Yes',
      })
      .then(value => {
        if (value === true) {
          this.$root.$bvToast.toast(`You successfully signed out`, {
            title: 'Signed out',
            variant: 'default',
            autoHideDelay: 100
          });

          this.signOut();
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    async signOut() {
      this.$router.push('/users/sign-in');
      await this.$store.dispatch('sessions/signOut');
    }
  }
}
</script>
