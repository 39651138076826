const rpcFormattedEndpoints = {
  methods: {
    collectActiveEndpoints(subscriptions) {
      const formattedEndpoints = [];

      subscriptions.forEach((subscription) => {
        subscription.endpoints.forEach((endpoint) => {
          if (endpoint.is_active) {
            const origins = endpoint.allowed_origins
              .filter(allowedOrigin => allowedOrigin.is_active)
              .map(allowedOrigin => allowedOrigin.value)
              .join(', ');

            endpoint.values.forEach((endpointValue) => {
              formattedEndpoints.push({
                name: endpoint.name,
                value: endpointValue,
                origins: origins
              });
            })
          }
        });
      });

      return formattedEndpoints;
    },
  }
};

export default rpcFormattedEndpoints;
