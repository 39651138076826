import http from '@/services/http';

function sharedOptions() {
  return {
    dataZoom: [
      {type: 'inside', throttle: 50},
      {type: 'slider'}
    ],
    textStyle: {
      fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    }
  }
}

function tooltipOptions(params) {
  let html = `<p class="mb-1"><b>${params[0].axisValueLabel}</b></p>`;

  if (params.length > 15) {
    html += `
            <p class="mb-1">
              Showing only top 15 values of ${params.length} total. Filter to see more
            </p>
            `
  } else if (params.length > 1) {
    html += `
            <p class="mb-1">
              ${params.length} values in chart
            </p>
            `
  }

  html += `<hr class="mt-1 mb-2" />`

  html += params.sort((a, b) => _.toNumber(b.value[1]) - _.toNumber(a.value[1])).map((el) => {
    return `
            <p class="mb-1">
              <span class="tooltip-circle" style="background-color:${el.color}"></span>
              <span>${el.seriesName}</span>
              <span class="ml-3 float-right"><b>${el.value[1]}</b></span>
            </p>
            `
  }).slice(0, 15).join(" ");

  return html;
}

function formatTime(strTime) {
  // Dummy way to detect between unix ts (golang) and formatted ts (ruby)
  // @TODO make ruby return complete ones?
  var temp;
  if (strTime.length > 6) {
    temp = new Date(Date.parse(strTime));
  } else {
    temp = new Date(parseInt(strTime) * 1000);
  }
  return temp.toISOString();
}

function formatMetricValueInMilliseconds(value) {
  try {
    // return `${(value * 1000).toFixed(2)} ms`;
    return Number(`${(value * 1000).toFixed(2)}`);
  } catch (e) {
    console.log("Error for format millis value:", value);
  }
  return value;
}

function formatMetricValue(value) {
  return parseFloat(value).toFixed(2)
}

function roundTo5Minutes(timestamp) {
  const date = new Date(timestamp);
  const minutes = 5 * Math.round(date.getMinutes() / 5);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.toISOString();
}

function createHeatmapChartConfig(yMin, yMax, leValues, timestamps, matrix,
                                  {
                                    title = '',
                                    subtext = '',
                                    name = ''
                                  } = {}) {
  return {
    title: {
      text: title,
      subtext: subtext,
      left: 'left'
    },
    grid: {
      borderWidth: 0,
      backgroundColor: '#414f70',
      show: true
    },
    tooltip: {
      position: 'top',
      mode: 'single',
      yHistogram: false,
      showColorScale: false
    },
    xAxis: {
      type: 'category',
      data: timestamps,
      splitArea: {
        show: true
      }
    },
    yAxis: {
      type: 'category',
      data: leValues,
      unit: 'short',
      splitArea: {
        show: true
      }
    },
    visualMap: {
      min: yMin,
      max: yMax,
      calculable: true,
      realtime: false,
      orient: 'horizontal',
      left: 'center',
      bottom: 0,
      inRange: {
        color: [
          '#52638c',
          '#637297',
          '#7482a3',
          '#8591ae',
          '#97a1ba',
          '#a8b1c5',
          '#b9c0d1',
          '#cbd0dc',
          '#dcdfe8',
          '#edeff3',
          '#ffffff',
        ]
      },
      outOfRange: {
        color: ['#414f70']  // doesn't seem to do anything

      }
    },
    series: [
      {
        name: name,
        type: 'heatmap',
        data: matrix,
        label: {
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
}

function createPieChartConfig(seriesData, {
  title = '',
  subtext = '',
  name = ''
} = {}) {

  return {
    title: {
      text: title,
      subtext: subtext,
      left: 'left'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'horizontal',
      left: 'center',
      bottom: 10
    },
    series: [
      {
        name: name,
        type: 'pie',
        radius: '50%',
        data: seriesData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };
}

function formatToK(number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + ' M';
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + ' K';
  }
  return number.toString();
}

function calculatePercentile(data, percentile = 50) {
  // Sort the data
  const sortedData = data.map(item => parseFloat(item[1])).filter(val => !isNaN(val));

  // Calculate the index for the given percentile
  const index = (percentile / 100) * (sortedData.length - 1);
  const lowerIndex = Math.floor(index);
  const upperIndex = Math.ceil(index);

  if (lowerIndex === upperIndex) {
    return Number(sortedData[lowerIndex]);
  } else {
    // Interpolate between the two nearest values
    const lowerValue = sortedData[lowerIndex];
    const upperValue = sortedData[upperIndex];
    const fraction = index - lowerIndex;
    return Number(lowerValue + (upperValue - lowerValue) * fraction);
  }
}

function createPercentileConfig(dataset, series, percentile = 50, percentileValue) {
  return {
    title: {text: "", subtext: ""},
    toolbox: {
      left: 'center',
      itemSize: 15,
      top: 25,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: false,
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    textStyle: sharedOptions.textStyle,
    dataset,
    series,
    graphic: [
      {
        type: 'group',
        left: 15,
        top: '15%',
        children: [
          {
            type: 'rect',
            z: 100,
            left: 0,
            top: 'middle',
            shape: {
              width: 75,
              height: 60
            },
            style: {
              fill: 'transparent',
              // stroke: '#555',
              // lineWidth: 1,
            }
          },
          {
            type: 'text',
            z: 100,
            left: 0,
            top: 'middle',
            style: {
              fill: '#333',
              width: 50,
              overflow: 'break',
              text: `p${percentile}`,
              font: 'bold 26px sans-serif'
            }
          }
        ]
      },
      {
        type: 'group',
        left: 10,
        top: '30%',
        width: '100%',
        children: [
          {
            type: 'rect',
            z: 100,
            left: 'center',
            top: 'middle',
            shape: {
              width: 100,
              height: 60
            },
            style: {
              fill: 'transparent',
            }
          },
          {
            type: 'text',
            z: 100,
            left: 20,
            top: '5%',
            resizable: true,
            style: {
              fill: '#333',
              width: '100%',
              height: 60,
              text: percentileValue,
              fontSize: '25',
              fontWeight: 'bold',
              textAlign: 'center',
              textVerticalAlign: 'middle',
              minFontSize: 55,
            }
          }
        ]
      }
    ],
    xAxis: [
      {
        gridIndex: 0,
        scale: false,
        type: 'category',
        axisTick: {show: false},
        axisLabel: {show: false},
        axisLine: {show: false},
      }
    ],
    yAxis: [
      {
        gridIndex: 0,
        axisLabel: {show: false},
        axisTick: {show: false},
        axisLine: {show: false},
        splitLine: {
          show: false
        },
      }
    ],
    grid: [
      {
        left: 10,
        right: 10,
        top: percentile === 50 ? '90%' : '65%',
        bottom: 10,
      }
    ],
    hasTextResize: true
  };
}

// fetch and chart creation

async function fetchRpcRequests(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/get_rps`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    const metricsData = data[0].values.map((element) => {
      element[0] = formatTime(element[0]);
      element[1] = formatMetricValue(element[1]);
      return element;
    });

    return metricsData;
  }
}


async function fetchRpcRequestsByOrigin(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/get_origins`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  return response.data.data;
}


async function fetchRpcRequestsByMethod(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/get_rps_by_method`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  return response.data.data;
}


async function fetchRpcRequestsByStatus(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/get_rps_by_status`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  return response.data.data;
}


async function fetchDasByMethod(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/das_by_method`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  return response.data.data;
}


async function fetchTrafficInMbits(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/get_endpoint_traffic`, // rename to get_traffic
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    return data[0].values.map((element) => {
      element[0] = formatTime(element[0]);
      element[1] = formatMetricValue(element[1]) / 1000000;
      return element;
    });
  }
}


async function fetchEgressBytes(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/egress_bytes`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    return data[0].values.map((element) => {
      element[0] = formatTime(element[0]);
      element[1] = formatMetricValue(element[1]);
      return element;
    });
  }
}


async function fetchEgressChargeable(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/egress_chargeable`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );
  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    return data[0].values.map((element) => {
      element[0] = formatTime(element[0]);
      element[1] = formatMetricValue(element[1]);
      return element;
    });
  }
}


async function fetchTrafficByMethod(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/traffic_by_method`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
      }
    }
  );

  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    return data.map((element) => {
      const convertedValues = element.values.map((value) => {
        return [value[0], formatMetricValue(value[1]) / 1000000];
      })

      return {metric: element.metric, values: convertedValues};
    });
  }
}


async function fetchResponseTimeByMethod(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/get_duration`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
        quantile: options.quantile
      }
    }
  );

  return response.data.data;
}


async function fetchResponseTimeByHost(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/duration_by_host`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_uuid: options.subscriptionUuid,
        endpoint_uuid: options.endpointUuid,
        token_uuid: options.tokenUuid,
        quantile: options.quantile
      }
    }
  );

  return response.data.data;
}


async function fetchLandedVsNotLanded(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/landed_vs_not_landed`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchTotalReceivedTransactions(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/total_received_transactions`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchTransactionOptimisationPreflight(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_preflight`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchPriorityFeesForLanded(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/priority_fees_for_landed`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    return data.map((element) => {
      const convertedValues = element.values.map((value) => {
        return [value[0], formatMetricValue(value[1]) / 1000000];
      })

      return {metric: element.metric, values: convertedValues};
    });
  }
}


async function fetchComputeBudgetForLanded(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/compute_budget_for_landed`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  const data = response.data.data;

  if (data.length === 0) {
    return [];
  } else {
    return data.map((element) => {
      const convertedValues = element.values.map((value) => {
        return [value[0], formatMetricValue(value[1]) / 1000000];
      })

      return {metric: element.metric, values: convertedValues};
    });
  }
}


async function fetchTransactionOptimisationPriorityFees(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_priority_fees`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}

async function fetchTransactionOptimisationPriorityFeesMaxRetries(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_priority_fees_max_retries`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchTransactionOptimisationPriorityFeesSubmitted(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_priority_fees_submitted`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchPoolLandedRate(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/pool_landed_rate`,

    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchTransactionOptimisationPriorityFeesLanded(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_priority_fees_landed`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchTransactionOptimisationComputeBudgetSubmitted(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_compute_budget_submitted`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


async function fetchTransactionOptimisationComputeBudgetLanded(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_compute_budget_landed`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}

async function fetchTransactionOptimisationPriorityFeesPool(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_priority_fees_pool`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}

async function fetchTransactionOptimisationPriorityFeesGlobal(options) {
  const response = await http.get(
    `accounts/${options.accountUuid}/metrics/transaction_optimisation_priority_fees_global`,
    {
      params: {
        starts_at: options.startDate,
        ends_at: options.endDate,
        subscription_type_uuid: options.subscriptionTypeUuid,
        subscription_uuid: options.subscriptionUuid,
      }
    }
  );

  return response.data.data;
}


function rpcRequestByOriginOption(chartData) {
  const seriesData = chartData.map((el) => {
    return {
      name: el.metric.origin + " " + el.metric.status,
      type: "line",
      areaStyle: {},
      showSymbol: false,
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
  });

  const legendData = seriesData.map(item => item.name);

  return {
    title: {text: "RPC requests by origin"},
    textStyle: sharedOptions.textStyle,
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {},
      data: legendData,
    },
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {type: 'value', axisLabel: {formatter: '{value} req/s'}},
    series: seriesData,
    hasLegendClick: true,
  };
}

function rpcRequestOption(chartData) {
  return {
    title: {text: "RPC requests"},
    legend: {show: false},
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    dataset: {source: chartData, dimensions: ['timestamp', 'rps']},
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} req/s'}
    },
    grid: {
      top: 110,
      left: 75,
      right: 15,
      height: 160
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      right: '0',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    textStyle: sharedOptions.textStyle,
    series: [
      {
        name: 'rps',
        type: 'line',
        encode: {x: 'timestamp', y: 'rps'},
        areaStyle: {color: '#00f', opacity: 0.5},
        showSymbol: false,
      }
    ]
  };
}

function trafficInMbitsOption(chartData) {
  return {
    title: {text: "Endpoint traffic in Mbits"},
    legend: {show: false},
    grid: {
      top: 110,
      left: 75,
      right: 15,
      height: 160
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      right: '0',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    dataset: {source: chartData, dimensions: ['timestamp', 'mbps']},
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} mbps'}
    },
    textStyle: sharedOptions.textStyle,
    series: [
      {
        name: 'mbps',
        type: 'line',
        encode: {x: 'timestamp', y: 'mbps'},
        areaStyle: {color: '#00f', opacity: 0.5},
        showSymbol: false,
      }
    ]
  };
}

function requestsByMethodOption(chartData) {
  var series = chartData.map((el) => {
    var series = {
      name: el.metric.rpc_method,
      type: "line",
      showSymbol: false,
      areaStyle: {},
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
    return series;
  });

  const legendData = series.map(item => item.name);

  return {
    title: {text: "RPC requests by method"},
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {},
      data: legendData,
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} req/s'}
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true,
  };
}

function dasRequestsByMethodOption(chartData) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.rpc_method,
      type: "line",
      showSymbol: false,
      areaStyle: {},
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
  });

  return {
    title: {text: "DAS requests by method"},
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {}
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} req/s'}
    },
    textStyle: sharedOptions.textStyle,
    series: series
  };
}

function rpcRequestByStatusOption(chartData) {
  var series = chartData.map((el) => {
    var series = {
      name: el.metric.status,
      type: "bar",
      showSymbol: false,
      large: true,
      areaStyle: {},
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
    return series;
  });

  const legendData = series.map(item => item.name);

  return {
    title: {text: "RPC requests by status"},
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {},
      data: legendData,
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} req/s'}
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true,
  };
}

function trafficByMethodOption(chartData) {
  var series = chartData.map((el) => {
    var series = {
      name: el.metric.rpc_method,
      type: "line",
      showSymbol: false,
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
    return series;
  });

  const legendData = series.map(item => item.name);

  return {
    title: {text: "Endpoint traffic by method"},
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {},
      data: legendData,
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} mbps'},
      scale: true
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true,
  };
}

function responseTimeOption(chartData, overrides, quantile) {
  let text;
  if (quantile === "0.5") {
    text = "Median"
  } else if (quantile === "0.9") {
    text = "P90";
  } else if (quantile === "0.95") {
    text = "P95";
  } else {
    text = parseInt(quantile) * 100 + "th percentile"
  }

  const series = chartData.map(el => ({
    name: el.metric.rpc_method,
    type: "line",
    showSymbol: false,
    data: el.values.map(([time, value]) => [
      formatTime(time),
      formatMetricValueInMilliseconds(value)
    ])
  }));

  const legendData = series.map(item => item.name);

  return {
    title: {text: text + " response by method"},
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {},
      data: legendData
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} ms'}
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true,
  };
}

function responseTimeByHostOption(chartData) {
  var series = chartData.map((el) => {
    var series = {
      name: el.metric.nodename,
      type: "line",
      showSymbol: false,
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValueInMilliseconds(element[1]);
        return element;
      })
    };
    return series;
  });

  const legendData = series.map(item => item.name);

  return {
    title: {text: "Median response by host"},
    grid: {
      left: '3%',
      right: '30%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      right: 10,
      orient: 'vertical',
      top: "center",
      align: 'right',
      width: '150px',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {},
      data: legendData,
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value} ms'}
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true,
  };
}

function egressBytesOption(chartData) {
  let yAxisUnits = 'KB';

  let maxValue = 0;
  if (chartData.length > 0) {
    maxValue = _.maxBy(chartData, (el) => {
      return parseInt(el[1]);
    })[1];
  }

  if (maxValue > 1000) {
    yAxisUnits = 'MB';
    chartData = chartData.map((el) => {
      el[1] = (parseInt(el[1]) / 1000);
      return el;
    });
  }

  return {
    title: {text: "Chargeable data use"},
    legend: {show: false},
    grid: {
      top: 110,
      left: 75,
      right: 15,
      height: 160
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      right: '0',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    dataset: {source: chartData, dimensions: ['timestamp', yAxisUnits]},
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: `{value} ${yAxisUnits}`}
    },
    textStyle: sharedOptions.textStyle,
    series: [
      {
        name: yAxisUnits,
        type: 'line',
        encode: {x: 'timestamp', y: yAxisUnits},
        areaStyle: {color: '#00f', opacity: 0.5},
        showSymbol: false,
      }
    ]
  };
}

function egressChargeableOption(chartData) {
  return {
    title: {text: "Chargeable data use"},
    legend: {show: false},
    grid: {
      top: 110,
      left: 75,
      right: 15,
      height: 160
    },
    dataZoom: sharedOptions.dataZoom,
    toolbox: {
      right: '0',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    dataset: {source: chartData, dimensions: ['timestamp', '$']},
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '${value}'}
    },
    textStyle: sharedOptions.textStyle,
    series: [
      {
        name: 'chargeable',
        type: 'line',
        encode: {x: 'timestamp', y: '$'},
        areaStyle: {color: '#00f', opacity: 0.5},
        showSymbol: false,
      }
    ]
  };
}

function landedVsNotLandedOption(chartData) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.landed_not_landed,
      type: "line",
      showSymbol: false,
      areaStyle: null,
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
  });

  const legendData = series.map(item => item.name);

  return {
    title: {text: "Landed vs not landed"},
    grid: {
      left: '3%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: {
      ...{
        xAxisIndex: 0,
        start: 0,
        end: 100,
        bottom: '10%', // Ensure it doesn't overlap with the legend
      },
      ...sharedOptions.dataZoom
    },
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      left: 'center',
      orient: 'horizontal',
      bottom: 10,
      align: 'auto',
      icon: "rect",
      selectedMode: 'multiple',
      data: legendData,
      selected: legendData.reduce((acc, name) => {
        acc[name] = true;
        return acc;
      }, {})
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value}'}
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true
  };
}

function priorityFeesForLandedOption(chartData, overrides = {}) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.percentile,
      type: "line",
      showSymbol: false,
      areaStyle: null,
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
  });

  let title;
  if (overrides.title) {
    title = overrides.title;
  } else {
    title = {text: "Priority fees for landed (global)"};
  }

  const legendData = series.map(item => item.name);

  return {
    title: title,
    grid: {
      left: '3%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: {
      ...{
        xAxisIndex: 0,
        start: 0,
        end: 100,
        bottom: '10%', // Ensure it doesn't overlap with the legend
      },
      ...sharedOptions.dataZoom
    },
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      left: 'center',
      orient: 'horizontal',
      bottom: 10,
      align: 'auto',
      icon: "rect",
      selectedMode: 'multiple',
      data: legendData,
      selected: legendData.reduce((acc, name) => {
        acc[name] = true;
        return acc;
      }, {})
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value) {
          if (value > 0) {
            return value + ' Mil';
          } else {
            return value;
          }
        }
      }
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true
  };
}

function totalReceivedTransactions(chartData) {
  let series = chartData.map((el) => {
    return {
      name: el.metric[Object.keys(el.metric)[0]],
      type: "line",
      showSymbol: false,
      areaStyle: null,
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    };
  });

  const legendData = series.map(item => item.name);

  return {
    title: {text: "Total received transactions"},
    grid: {
      left: '3%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: {
      ...{
        xAxisIndex: 0,
        start: 0,
        end: 100,
        bottom: '10%',
      },
      ...sharedOptions.dataZoom
    },
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      left: 'center',
      orient: 'horizontal',
      bottom: 10,
      align: 'auto',
      icon: "rect",
      selectedMode: 'multiple',
      data: legendData,
      selected: legendData.reduce((acc, name) => {
        acc[name] = true;
        return acc;
      }, {})
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value}'}
    },
    textStyle: sharedOptions.textStyle,
    series: series,
    hasLegendClick: true
  };
}

function poolLandedRateOption(chartData) {
  var series = chartData.map((el) => {
    var series = {
      name: el.metric.rpc_method,
      type: "line",
      showSymbol: false,
      areaStyle: {},
      data: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1] * 100);
        return element;
      })
    };
    return series;
  });

  return {
    title: {text: "Pool landing rate (estimated)"},
    grid: {
      left: '3%',
      bottom: '50',
      top: "110",
      containLabel: true
    },
    dataZoom: {
      ...{
        xAxisIndex: 0,
        start: 0,
        end: 100,
        bottom: '10%', // Ensure it doesn't overlap with the legend
      },
      ...sharedOptions.dataZoom
    },
    toolbox: {
      left: 'center',
      itemSize: 25,
      top: 55,
      feature: {
        dataZoom: {yAxisIndex: 'none'},
        restore: {}
      }
    },
    legend: {
      show: true,
      type: 'scroll',
      left: 'center',
      orient: 'horizontal',
      bottom: 10,
      align: 'auto',
      icon: "rect",
      selectedMode: 'multiple',
      selected: {}
    },
    tooltip: {
      trigger: 'axis',
      formatter: tooltipOptions
    },
    xAxis: {type: 'time'},
    yAxis: {
      type: 'value',
      axisLabel: {formatter: '{value}%'}
    },
    textStyle: sharedOptions.textStyle,
    series: series
  };
}

// Pie charts
function transactionOptimisationPreflight(chartData) {
  const aggregatedData = chartData.reduce((acc, el) => {
    const metricName = Object.values(el.metric)[0];
    // value is parsed as the 1th element - if the data on the controller changes,
    // console log el.values to adjust
    const totalValue = el.values.reduce((sum, [, value]) => sum + parseFloat(value), 0);

    acc[metricName] = (acc[metricName] || 0) + totalValue;
    return acc;
  }, {});

  const seriesData = Object.entries(aggregatedData).map(([name, value]) => ({
    value: parseFloat(value.toFixed(2)),
    name: name
  }));

  return createPieChartConfig(seriesData, {subtext: 'Preflight', name: 'Transactions'});
}

export const transactionOptimisationPriorityFees = function (chartData) {
  const aggregatedData = chartData.reduce((acc, el) => {
    const metricName = Object.values(el.metric)[0];
    // value is parsed as the 1th element - if the data on the controller changes,
    // console log el.values to adjust
    const totalValue = el.values.reduce((sum, [, value]) => sum + parseFloat(value), 0);

    acc[metricName] = (acc[metricName] || 0) + totalValue;
    return acc;
  }, {});

  const seriesData = Object.entries(aggregatedData).map(([name, value]) => ({
    value: parseFloat(value.toFixed(2)),
    name: name
  }));

  return createPieChartConfig(seriesData, {
    subtext: 'Priority fees / Compute budget specified',
    name: 'Transactions budget specified'
  });
}

export const transactionOptimisationPriorityFeesMaxRetries = function (chartData) {
  const aggregatedData = chartData.reduce((acc, el) => {
    const metricName = Object.values(el.metric)[0];
    // value is parsed as the 1th element - if the data on the controller changes,
    // console log el.values to adjust
    const totalValue = el.values.reduce((sum, [, value]) => sum + parseFloat(value), 0);

    acc[metricName] = (acc[metricName] || 0) + totalValue;
    return acc;
  }, {});

  const seriesData = Object.entries(aggregatedData).map(([name, value]) => ({
    value: parseFloat(value.toFixed(2)),
    name: name
  }));

  return createPieChartConfig(seriesData, {
    subtext: 'Priority fees / Maximum Retries',
    name: 'Transactions max retries'
  });
}

// Heat maps
export const transactionOptimisationPriorityFeesSubmitted = function (chartData) {
  // since we're building a matrix, we have to parse nested values
  // console.log("transactionOptimisationPriorityFeesSubmitted:", JSON.stringify(chartData));
  const data = chartData[0].values;
  // data is: [ {metric:{},values:[]}, {metric:{},values:[]} ]

  const timestamps = [...new Set(data.flatMap(d => d.values.map(v => v[0])))];

  // sort metrics by 'le' value
  data.sort((a, b) => {
    if (a.metric.le === "+Inf") return 1;
    if (b.metric.le === "+Inf") return -1;
    return parseFloat(a.metric.le) - parseFloat(b.metric.le);
  });

  const leValues = data.map(d => Number(d.metric.le));
  leValues.pop(); // last value is always +Inf, so we pop it

  const matrix = [];
  let yMin = Infinity;
  let yMax = -Infinity;

  for (let i = 0; i < data.length - 1; i++) {
    const currentLe = data[i];
    const nextLe = data[i + 1];

    currentLe.values.forEach((value, yidx) => {
      const currentValue = Number(value[1]);
      const nextValue = Number(nextLe.values[yidx][1]);
      const difference = nextValue - currentValue;

      matrix.push([yidx, i, difference || '-']);

      yMin = Math.min(yMin, difference);
      yMax = Math.max(yMax, difference);
    });
  }

  // Process timestamps and values
  const timeMap = new Map();
  data.forEach((series, yIndex) => {
    series.values.forEach(([timestamp, value]) => {
      const roundedTime = roundTo5Minutes(timestamp);
      if (!timeMap.has(roundedTime)) {
        timeMap.set(roundedTime, new Array(leValues.length).fill(null));
      }
      timeMap.get(roundedTime)[yIndex] = parseInt(value);
    });
  });

  // Sort timestamps and create the final data structure
  const sortedTimestamps = Array.from(timeMap.keys()).sort();

  return createHeatmapChartConfig(
    yMin,
    yMax,
    leValues,
    timestamps,
    matrix,
    {title: 'Priority fees over time - submitted', name: 'Priority fees'}
  );
};

export const transactionOptimisationPriorityFeesLanded = function (chartData) {
  // since we're building a matrix, we have to parse nested values
  const data = chartData[0].values;
  // data is: [ {metric:{},values:[]}, {metric:{},values:[]} ]

  const timestamps = [...new Set(data.flatMap(d => d.values.map(v => v[0])))];

  // sort metrics by 'le' value
  data.sort((a, b) => {
    if (a.metric.le === "+Inf") return 1;
    if (b.metric.le === "+Inf") return -1;
    return parseFloat(a.metric.le) - parseFloat(b.metric.le);
  });

  const leValues = data.map(d => Number(d.metric.le));
  leValues.pop(); // last value is always +Inf, so we pop it

  const matrix = [];

  for (let i = 0; i < data.length - 1; i++) {
    const currentLe = data[i];
    const nextLe = data[i + 1];

    currentLe.values.forEach((value, yidx) => {
      const currentValue = Number(value[1]);
      const nextValue = Number(nextLe.values[yidx][1]);
      const difference = nextValue - currentValue;

      matrix.push([yidx, i, difference || '-']);
    });
  }

  // get min and max for the visual map
  const vals = data.flatMap(obj => obj.values.map(yobj => Number(yobj[1] || 0)));
  const yMin = Math.min.apply(null, vals);
  const yMax = Math.max.apply(null, vals);

  // Process timestamps and values
  const timeMap = new Map();
  data.forEach((series, yIndex) => {
    series.values.forEach(([timestamp, value]) => {
      const roundedTime = roundTo5Minutes(timestamp);
      if (!timeMap.has(roundedTime)) {
        timeMap.set(roundedTime, new Array(leValues.length).fill(null));
      }
      timeMap.get(roundedTime)[yIndex] = parseInt(value);
    });
  });

  // Sort timestamps and create the final data structure
  const sortedTimestamps = Array.from(timeMap.keys()).sort();

  return createHeatmapChartConfig(
    yMin,
    yMax,
    leValues,
    timestamps,
    matrix,
    {title: 'Priority fees over time - landed', name: 'Priority fees landed'}
  );
}

export const transactionOptimisationComputeBudgetSubmitted = function (chartData) {
  // since we're building a matrix, we have to parse nested values
  const data = chartData[0].values;
  // data is: [ {metric:{},values:[]}, {metric:{},values:[]} ]

  const timestamps = [...new Set(data.flatMap(d => d.values.map(v => v[0])))];

  // sort metrics by 'le' value
  data.sort((a, b) => {
    if (a.metric.le === "+Inf") return 1;
    if (b.metric.le === "+Inf") return -1;
    return parseFloat(a.metric.le) - parseFloat(b.metric.le);
  });

  const leValues = data.map(d => Number(d.metric.le));
  leValues.pop(); // last value is always +Inf, so we pop it

  const matrix = [];

  for (let i = 0; i < data.length - 1; i++) {
    const currentLe = data[i];
    const nextLe = data[i + 1];

    currentLe.values.forEach((value, yidx) => {
      const currentValue = Number(value[1]);
      const nextValue = Number(nextLe.values[yidx][1]);
      const difference = nextValue - currentValue;

      matrix.push([yidx, i, difference || '-']);
    });
  }

  // get min and max for the visual map
  const vals = data.flatMap(obj => obj.values.map(yobj => Number(yobj[1] || 0)));
  const yMin = Math.min.apply(null, vals);
  const yMax = Math.max.apply(null, vals);

  // Process timestamps and values
  const timeMap = new Map();
  data.forEach((series, yIndex) => {
    series.values.forEach(([timestamp, value]) => {
      const roundedTime = roundTo5Minutes(timestamp);
      if (!timeMap.has(roundedTime)) {
        timeMap.set(roundedTime, new Array(leValues.length).fill(null));
      }
      timeMap.get(roundedTime)[yIndex] = parseInt(value);
    });
  });

  // Sort timestamps and create the final data structure
  const sortedTimestamps = Array.from(timeMap.keys()).sort();

  return createHeatmapChartConfig(
    yMin,
    yMax,
    leValues,
    timestamps,
    matrix,
    {title: 'Compute budget over time - Submitted', name: 'Compute budget submitted'}
  );
}

export const transactionOptimisationComputeBudgetLanded = function (chartData) {
  // since we're building a matrix, we have to parse nested values
  const data = chartData[0].values;
  // data is: [ {metric:{},values:[]}, {metric:{},values:[]} ]

  const timestamps = [...new Set(data.flatMap(d => d.values.map(v => v[0])))];

  // sort metrics by 'le' value
  data.sort((a, b) => {
    if (a.metric.le === "+Inf") return 1;
    if (b.metric.le === "+Inf") return -1;
    return parseFloat(a.metric.le) - parseFloat(b.metric.le);
  });

  const leValues = data.map(d => Number(d.metric.le));
  leValues.pop(); // last value is always +Inf, so we pop it

  const matrix = [];

  for (let i = 0; i < data.length - 1; i++) {
    const currentLe = data[i];
    const nextLe = data[i + 1];

    currentLe.values.forEach((value, yidx) => {
      // Sometimes we can get an error when we try to look ahead to the next non-existent yidx value
      if (nextLe.values[yidx] && nextLe.values[yidx][1]) {
        const currentValue = Number(value[1]);
        const nextValue = Number(nextLe.values[yidx][1]);
        const difference = nextValue - currentValue;

        matrix.push([yidx, i, difference || '-']);
      }
    });
  }

  // get min and max for the visual map
  const vals = data.flatMap(obj => obj.values.map(yobj => Number(yobj[1] || 0)));
  const yMin = Math.min.apply(null, vals);
  const yMax = Math.max.apply(null, vals);

  // Process timestamps and values
  const timeMap = new Map();
  data.forEach((series, yIndex) => {
    series.values.forEach(([timestamp, value]) => {
      const roundedTime = roundTo5Minutes(timestamp);
      if (!timeMap.has(roundedTime)) {
        timeMap.set(roundedTime, new Array(leValues.length).fill(null));
      }
      timeMap.get(roundedTime)[yIndex] = parseInt(value);
    });
  });

  // Sort timestamps and create the final data structure
  const sortedTimestamps = Array.from(timeMap.keys()).sort();

  return createHeatmapChartConfig(
    yMin,
    yMax,
    leValues,
    timestamps,
    matrix,
    {title: 'Compute budget over time - landed', name: 'Compute budget landed'}
  );
}

export const transactionOptimisationPriorityFeesPool50 = function (chartData) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.rpc_method,
      type: "line",
      xAxisIndex: 0,
      yAxisIndex: 0,
      datasetIndex: 0,
      color: '#4266bb',
      areaStyle: {color: '#4266bb', opacity: 0.25},
    };
  });

  const dataset = chartData.map((el) => {
    return {
      source: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    }
  });

  const p50 = calculatePercentile(dataset[0].source);
  const p50Value = formatToK(p50);

  return createPercentileConfig(dataset, series, 50, p50Value);
}

export const transactionOptimisationPriorityFeesPool90 = function (chartData) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.rpc_method,
      type: "line",
      xAxisIndex: 0,
      yAxisIndex: 0,
      datasetIndex: 0,
      color: '#4266bb',
      areaStyle: {color: '#4266bb', opacity: 0.25},
    };
  });

  const dataset = {
    source: chartData[1].values.map((element) => {
      element[0] = formatTime(element[0]);
      element[1] = formatMetricValue(element[1]);
      return element;
    })
  }

  const p90 = calculatePercentile(dataset.source, 90);
  const p90Value = formatToK(p90);

  return createPercentileConfig(dataset, series, 90, p90Value);
}

export const transactionOptimisationPriorityFeesGlobal50 = function (chartData) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.rpc_method,
      type: "line",
      xAxisIndex: 0,
      yAxisIndex: 0,
      datasetIndex: 0,
      color: '#4266bb',
      areaStyle: {color: '#4266bb', opacity: 0.25},
    };
  });

  const dataset = chartData.map((el) => {
    return {
      source: el.values.map((element) => {
        element[0] = formatTime(element[0]);
        element[1] = formatMetricValue(element[1]);
        return element;
      })
    }
  });

  const p50 = calculatePercentile(dataset[0].source);
  const p50Value = formatToK(p50);

  return createPercentileConfig(dataset, series, 50, p50Value);
}

export const transactionOptimisationPriorityFeesGlobal90 = function (chartData) {
  const series = chartData.map((el) => {
    return {
      name: el.metric.rpc_method,
      type: "line",
      xAxisIndex: 0,
      yAxisIndex: 0,
      datasetIndex: 0,
      color: '#4266bb',
      areaStyle: {color: '#4266bb', opacity: 0.25},
    };
  });

  const dataset = {
    source: chartData[1].values.map((element) => {
      element[0] = formatTime(element[0]);
      element[1] = formatMetricValue(element[1]);
      return element;
    })
  }

  const p90 = calculatePercentile(dataset.source, 90);
  const p90Value = formatToK(p90);
  return createPercentileConfig(dataset, series, 90, p90Value);
}


export {
  sharedOptions, // used by admin reporting feature

  fetchRpcRequests,
  fetchRpcRequestsByOrigin,
  fetchRpcRequestsByMethod,
  fetchRpcRequestsByStatus,
  fetchDasByMethod,
  fetchTrafficInMbits,
  fetchTrafficByMethod,
  fetchResponseTimeByMethod,
  fetchResponseTimeByHost,
  fetchEgressBytes,
  fetchEgressChargeable,
  fetchLandedVsNotLanded,
  fetchTotalReceivedTransactions,
  fetchTransactionOptimisationPreflight,
  fetchPriorityFeesForLanded,
  fetchTransactionOptimisationPriorityFees,
  fetchTransactionOptimisationPriorityFeesMaxRetries,
  fetchTransactionOptimisationPriorityFeesSubmitted,
  fetchTransactionOptimisationPriorityFeesLanded,
  fetchTransactionOptimisationComputeBudgetSubmitted,
  fetchTransactionOptimisationComputeBudgetLanded,
  fetchComputeBudgetForLanded,
  fetchPoolLandedRate,
  fetchTransactionOptimisationPriorityFeesPool,
  fetchTransactionOptimisationPriorityFeesGlobal,

  rpcRequestByOriginOption,
  rpcRequestOption,
  trafficInMbitsOption,
  requestsByMethodOption,
  dasRequestsByMethodOption,
  rpcRequestByStatusOption,
  trafficByMethodOption,
  responseTimeOption,
  responseTimeByHostOption,
  egressBytesOption,
  egressChargeableOption,
  landedVsNotLandedOption,
  priorityFeesForLandedOption,
  totalReceivedTransactions,
  transactionOptimisationPreflight,
  poolLandedRateOption
}
