import moment from 'moment';
import echart from '@/components/globals/echart';

const metricsMixin = {
  components: { echart },

  data() {
    return {
      dates: {
        'Last 5 Minutes': null,
        'Last 15 Minutes': null,
        'Last 30 Minutes': null,
        'Last 1 Hour': null,
        'Last 3 Hours': null,
        'Last 6 Hours': null,
        'Last 12 Hours': null,
        'Last 24 Hours': null,
        'Last 2 Days': null,
        'Last 7 Days': null,
      },
      autoRefreshOptions: [
        {text: 'None', value: 'none'},
        {text: '1 Min', value: '1m'},
        {text: '5 Min', value: '5m'},
        {text: '15 Min', value: '15m'},
      ]
    }
  },

  methods: {
    setCalendarDates() {
      this.dates['Last 5 Minutes'] = [this.fiveMinutesAgo(), this.now()]
      this.dates['Last 15 Minutes'] = [this.fifteenMinutesAgo(), this.now()]
      this.dates['Last 30 Minutes'] = [this.thirtyMinutesAgo(), this.now()]
      this.dates['Last 1 Hour'] = [this.oneHourAgo(), this.now()]
      this.dates['Last 3 Hours'] = [this.threeHoursAgo(), this.now()]
      this.dates['Last 6 Hours'] = [this.sixHoursAgo(), this.now()]
      this.dates['Last 12 Hours'] = [this.twelveHoursAgo(), this.now()]
      this.dates['Last 24 Hours'] = [this.twentyFourHoursAgo(), this.now()]
      this.dates['Last 2 Days'] = [this.twoDaysAgo(), this.now()]
      this.dates['Last 7 Days'] = [this.sevenDaysAgo(), this.now()]
    },

    now() {
      return moment().toDate();
    },

    fiveMinutesAgo() {
      return moment().subtract(5, 'minutes').toDate();
    },

    fifteenMinutesAgo() {
      return moment().subtract(15, 'minutes').toDate();
    },

    thirtyMinutesAgo() {
      return moment().subtract(30, 'minutes').toDate();
    },

    oneHourAgo() {
      return moment().subtract(1, 'hours').toDate();
    },

    threeHoursAgo() {
      return moment().subtract(3, 'hours').toDate();
    },

    sixHoursAgo() {
      return moment().subtract(6, 'hours').toDate();
    },

    twelveHoursAgo() {
      return moment().subtract(12, 'hours').toDate();
    },

    twentyFourHoursAgo() {
      return moment().subtract(24, 'hours').toDate();
    },

    twoDaysAgo() {
      return moment().subtract(2, 'days').toDate();
    },

    sevenDaysAgo() {
      return moment().subtract(7, 'days').toDate();
    },

    beginningOfToday() {
      return moment().startOf('day').toDate();
    },

    beginningOfYesterday() {
      return moment().subtract(1, 'days').startOf('day').toDate();
    },

    endOfYesterday() {
      return moment().subtract(1, 'days').endOf('day').toDate();
    },

    clearInterval() {
      clearInterval(this.autoRefreshInterval);
    },
  }
};

export default metricsMixin;
