import http from '@/services/http';

const accounts = {
  methods: {
    async getAccount(uuid) {
      try {
        const response = await http.get(`accounts/${uuid}`);
        return response.data.account;
      }

      catch (error) {
        console.log(error);
      }
    },

    async activateAccount(uuid) {
      await http.put(`admin/accounts/${uuid}/activate`);
    },

    async deactivateAccount(uuid) {
      await http.put(`admin/accounts/${uuid}/deactivate`);
    },
  }
};

export default accounts;
