<template>
  <layout />
</template>

<script>
import layout from './layout.vue';

export default {
  name: 'Main',

  components: { layout }
}
</script>

<style lang="scss">
  @import '@/styles/theme.scss';
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200..900&display=swap');

.bg-light {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-primary {
  color: #FFFFFF !important;
  background: #3441B1 !important;
  margin: 0rem 0.5rem 0rem 0.25rem !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem !important;
  border: none !important
}

#search-nav {
  background-color: var(--falcon-card-bg);
}

li.selected {
  border-left: 3px solid var(--falcon-warning)
}

.disabled-token { display: none; }

.h-8 {
  height: 2rem;
}

select.edit-endpoint-rate-tier {
  width: auto;
}

.table >:not(:first-child) {
  border-top: 0;
}
</style>
