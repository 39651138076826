import http from '@/services/http';

const subscriptions = {
  methods: {
    async fetchSubscriptions(userUuid) {
      try {
        const response = await http.get(`subscriptions?user_uuid=${userUuid}`);
        return response.data.subscriptions;
      }
      catch (error) {
        console.log(error);
        // this.$_onRequestError({
        //   toastOptions: { message: 'Unable to fetch user data' },
        //   error
        // });
      }
    },
  }
};



export default subscriptions;
