<template>
  <div class="p-3 container-xl">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h2>Subscriptions</h2>
      </div>
      <div class="card-body">
        <subscriptions-table />
      </div>
    </div>
  </div>
</template>

<script>
import subscriptionsTable from '../../globals/subscriptions-table';

export default {
  name: 'AdminSubscriptions',

  components: { subscriptionsTable }
}
</script>
