<template>
  <div>
    <b-form-checkbox
      @change="showAccountActivationModal()"
      v-model="isSwitchActive"
      switch
      :size="switchSize"
      class="switch"
    >
      <span v-if="isSwitchActive">Active</span>
      <span v-else>Inactive</span>
    </b-form-checkbox>

    <activate-modal
      :resource-name="resourceName"
      :is-admin-path="isAdminPath"
      :uuid="uuid"
      v-on:activated="activate"
      v-on:canceled="cancelAccountActivation"
    />

    <deactivate-modal
      :resource-name="resourceName"
      :is-admin-path="isAdminPath"
      :uuid="uuid"
      v-on:deactivated="deactivate"
      v-on:canceled="cancelAccountDeactivation"
    />
  </div>
</template>

<script>
export default {
  name: 'ActivateDeactivateSwitch',

  props: {
    resourceName: {
      type: String,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
    isAdminPath: {
      type: Boolean,
      default: false,
    },
    switchSize: {
      type: String,
      default: 'lg',
    },
  },

  data() {
    return {
      isSwitchActive: null,
    };
  },

  created() {
    this.isSwitchActive = this.resource.is_active;
  },

  computed: {
    isActive() {
      return this.resource.is_active;
    },
    uuid() {
      return this.resource.uuid;
    },
  },

  methods: {
    showAccountActivationModal() {
      if (this.isActive) {
        this.$bvModal.show(`deactivate-modal-${this.uuid}`);
      } else {
        this.$bvModal.show(`activate-modal-${this.uuid}`);
      }
    },

    activate() {
      this.isSwitchActive = true;
      this.$emit('activated');
    },

    deactivate(reason) {
      this.isSwitchActive = false;
      this.$emit('deactivated', reason);
    },

    cancelAccountActivation() {
      this.isSwitchActive = false;
    },

    cancelAccountDeactivation() {
      this.isSwitchActive = true;
    },
  }
}
</script>
