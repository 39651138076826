<template>
  <div class="container">
    <div class="row flex-center py-6">
      <div class="login-card-width">
        <div class="card">
          <div class="logo-container">
            <router-link to="/" class="d-flex flex-center">
              <triton-logo :height="85"></triton-logo>
            </router-link>
            <p>
              <span class="logo-text">
                Experience the fastest, most reliable, <br />full-service RPC.
              </span>
            </p>
          </div>
          <div class="card-body p-4">
            <div class="text-container">
              <h5 class="headline mb-2">Reset your password.</h5>
              <p>Enter a new password, then confirm it and you'll be all set up again.</p>
            </div>
            <b-form v-on:submit.prevent="setNewPassword">
              <b-form-group class="mb-3">
                <label for="passowrd">Password</label>
                <b-input
                  v-model="password"
                  placeholder="Password"
                  type="password"
                  autocomplete="off"
                  :state="passwordState"
                  autofocus
                  required
                />
                <b-form-invalid-feedback :state="passwordState">
                  {{ passwordErrors }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-3">
                <label for="confirmation">Confirmation</label>
                <b-input
                  v-model="passwordConfirmation"
                  placeholder="Confirm"
                  type="password"
                  autocomplete="off"
                  required
                />
              </b-form-group>

              <div>
                <b-button
                  :disabled="status === 'loading'"
                  type="submit"
                  class="primary-button d-block w-100 mt-3"
                >
                  <span v-if="status === 'loading'">
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </span>
                  <span v-else> Reset Password </span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label[for='passowrd'],
label[for='confirmation'] {
  opacity: 0.5;
}

::v-deep .form-control {
  background-color: #f6f7ff;
  border: 1px solid #dddee5;
  border-radius: 10px;
  height: 45px;
}
</style>

<script>
import http from '@/services/http';

export default {
  name: 'SetNewPassword',

  data() {
    return {
      password: null,
      passwordConfirmation: null,
      status: null,
      errors: {},
    };
  },

  computed: {
    resetPasswordToken() {
      return this.$route.query.reset_password_token;
    },

    passwordState() {
      if (this.errors['password']) {
        return false;
      }
    },

    passwordErrors() {
      const errors = this.errors['password'];
      if (errors) {
        return errors.join(', ');
      }
    },
  },

  methods: {
    async setNewPassword() {
      if (this.password === this.passwordConfirmation) {
        this.status = 'loading';

        try {
          await http.put(`users/password/edit`, {
            user: {
              reset_password_token: this.resetPasswordToken,
              password: this.password,
            },
          });

          this.$root.$bvToast.toast('Your password has been reset, and you can now sign in', {
            title: 'Success',
            variant: 'default',
          });

          this.$router.push({ name: 'signIn' });
        } catch (error) {
          console.log(error);

          this.errors = error.response.data.errors;

          this.$bvToast.toast(`There was an error resetting your password`, {
            title: 'Error',
            variant: 'danger',
          });
        } finally {
          this.status = null;
        }
      } else {
        this.errors['password'] = ['does not match confirmation'];
      }
    },
  },
};
</script>
