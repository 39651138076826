<template>
  <b-modal
    @show="onModalShow"
    centered
    :id="isNewSubscriptionType ? 'modal-create-new-subscription-type' : `modal-${uuid}`"
    class="p-3"
    header-bg-variant="light"
    header-class="py-3"
  >
    <template v-if="!isLoading" #modal-title>
      <span class="h4 mr-2">
        <span v-if="isNewSubscriptionType">New subscription type</span>
        <span v-else>
          Edit
          <span class="text-monospace">{{ subscriptionType.name }}</span>
        </span>
      </span>

      <span v-if="subscriptionType.versions?.length" class="h6 mr-2">
        <b-link v-b-modal="'versions-modal-' + uuid">
          <span>Versions</span>
        </b-link>

        <versions-modal
          :title="'Subscription Type ' + subscriptionType.name"
          :uuid="uuid"
          :versions="subscriptionType.versions"
        />
      </span>
    </template>

    <div v-if="isLoading" class="text-center">
      <b-spinner class="align-middle mr-3"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <b-form-group label="Internal name *">
        <b-form-input
          v-model="form.name"
          :state="nameState"
          required
          :disabled="!isNewSubscriptionType"
        />
        <b-form-text>Cannot be changed once created</b-form-text>
        <b-form-invalid-feedback :state="nameState">
          {{ nameErrors }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Display name">
        <b-form-input v-model="form.displayName" />
      </b-form-group>

      <b-form-group label="Pool name">
        <b-form-input v-model="form.poolName" />
      </b-form-group>

      <b-form-checkbox v-model="form.openEndpointsAllowed" class="mb-3">
        Allow open endpoints
      </b-form-checkbox>

      <b-form-checkbox v-model="form.isDedi" class="mb-3">
        Is dedicated?
      </b-form-checkbox>

      <h4 class="mb-2">Supported Rate Tiers</h4>

      <b-form-input v-model="rateTierSearchTerm" placeholder="Search" type="search" />

      <div class="rate-tiers-for-sub-type overflow-auto">
        <b-form-checkbox-group
          id="rate-tiers-checkbox-group"
          name="supported_rate_tiers"
          v-model="form.supportedRateTiers"
        >
          <b-form-checkbox
            v-for="rateTier in filteredRateTiers"
            :key="rateTier.uuid"
            :value="rateTier.uuid"
            class="form-check fs-2"
          >
            {{ rateTier.name }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>

      <hr>

      <h4 class="pb-2">Endpoint default suffixes *</h4>
      <p class="fs--1">
        Defines what new endpoints will automatically be appended with. Can be overridden on a per-endpoint basis. Start entering some text to see an example of how they'll be generated.
      </p>

      <b-form-invalid-feedback :state="numberOfSuffixesState">
        {{ numberOfSuffixesErrors }}
      </b-form-invalid-feedback>

      <div v-for="(endpointSuffix, i) in form.endpointDefaultSuffixes" :key="i">
        <div class="d-flex flex-row mb-3">
          <div class="flex-fill pr-3">
            <b-form-input
              v-model="form.endpointDefaultSuffixes[i]"
              placeholder="Enter some text to see an example"
              :state="suffixInputState(i)"
              required
            />
            <b-form-invalid-feedback :state="suffixInputState(i)">
              {{ suffixInputErrors(i) }}
            </b-form-invalid-feedback>
          </div>
          <div class="text-right">
            <b-button @click="removeEndpointSuffix(endpointSuffix)" variant="outline-primary" size="sm">
              <b-icon-dash-square />
            </b-button>
          </div>
        </div>
      </div>

      <div class="text-right">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="addEndpointSuffix"
        >
          Add
        </b-button>
      </div>

      <div v-for="(endpointSuffix, i) in form.endpointDefaultSuffixes" :key="'a' + i">
        <code>
          {{ '<' + 'slug' + '>' + '.' + endpointSuffix }}
        </code>
      </div>

      <b-form-group v-if="!isNewSubscriptionType">
        <hr>

        <h4 class="pb-2">Logo</h4>
        <p class="fs--1">
          Displayed on Subscription edit page.
        </p>
        <p class="fs--1">
          A square image is preferred, although not required. When displayed, dimensions will be preserved but height will be limited. Must be .jpeg, .jpg or .png. Can't be more than 250 kilobytes.
        </p>

        <b-form-file
          :state="logoState"
          v-model="form.logoFile"
          placeholder="Click & drag or choose an image"
          class="w-auto flex-grow-1"
        />
        <b-form-invalid-feedback :state="logoState">
          {{ logoErrors }}
        </b-form-invalid-feedback>

        <div v-if="parsedLogoUrl" class="m-3 d-flex">
          <span>
            <img class="rounded" :src="parsedLogoUrl" height="50" />
          </span>
          <span class="ml-4 align-self-end">
            <b-button variant="outline-danger" size="sm" @click="clearLogo">Clear</b-button>
          </span>
        </div>
      </b-form-group>
    </div>

    <template #modal-footer>
      <b-button variant="primary" @click="createOrUpdate" :disabled="isSaving" type="submit">
        <span v-if="isSaving">
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </span>
        <span v-else>
          Save
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import http from '@/services/http';
import filterObjectKeys from '@/helpers/filter-object-keys';
import convertKeysToSnakeCase from '@/helpers/convert-keys-to-snake-case';
import { mapState } from 'vuex';
import versionsModal from './versions-modal';

export default {
  name: 'SubscriptionTypeModal',

  components: {
    versionsModal,
  },

  props: ['uuid'],

  data() {
    return {
      whitelistedKeys: [
        'name',
        'displayName',
        'poolName',
        'openEndpointsAllowed',
        "isDedi",
        'supportedRateTiers',
        'endpointDefaultSuffixes',
        'logoFile',
      ],
      form: {
        endpointDefaultSuffixes: [],
      },
      subscriptionType: {},
      originalLogoUrl: null,
      logoUrl: null,
      rateTierSearchTerm: null,
      isSaving: false,
      isLoading: false,
      errors: {},
    }
  },

  computed: {
    ...mapState('rateTiers', ['rateTiers']),

    filteredRateTiers() {
      if (this.rateTierSearchTerm) {
        return this.rateTiers.filter(rt => rt.name.toLowerCase().includes(this.rateTierSearchTerm.toLowerCase()));
      } else {
        return this.rateTiers;
      }
    },

    nameState() {
      if (this.errors['name']) { return false; }
    },

    nameErrors() {
      const errors = this.errors['name'];
      if (errors) { return errors.join(', ') }
    },

    logoState() {
      return this.logoErrors ? false : null;
    },

    logoErrors() {
      if (this.errors.logo) {
        return this.errors.logo.join(', ');
      }
    },

    isLogoDeleted() {
      return this.originalLogoUrl && !this.logoUrl;
    },

    parsedLogoUrl() {
      if (this.form.logoFile) {
        return URL.createObjectURL(this.form.logoFile);
      } else {
        return this.logoUrl;
      }
    },

    numberOfSuffixesState() {
      if (this.errors['number_of_suffixes']) { return false; }
    },

    numberOfSuffixesErrors() {
      const errors = this.errors['number_of_suffixes'];
      if (errors) { return errors.join(', ') }
    },

    isNewSubscriptionType() {
      return this.uuid ? false : true;
    }
  },

  methods: {
    onModalShow() {
      if (this.isNewSubscriptionType) {
        this.form.endpointDefaultSuffixes = [''];
      } else {
        this.loadSubscriptionType();
      }
    },

    async loadSubscriptionType() {
      try {
        this.isLoading = true;
        this.subscriptionType = await this.$store.dispatch('subscriptionTypes/fetchSubscriptionType', this.uuid);

        this.logoUrl = this.originalLogoUrl = this.subscriptionType.logo_url;
        this.form.name = this.subscriptionType.name;
        this.form.displayName = this.subscriptionType.display_name;
        this.form.poolName = this.subscriptionType.pool_name;
        this.form.openEndpointsAllowed = this.subscriptionType.open_endpoints_allowed;
        this.form.isDedi = this.subscriptionType.is_dedi;

        this.form.supportedRateTiers = this.subscriptionType.supported_rate_tiers.map(rt => rt.uuid);

        this.form.endpointDefaultSuffixes = this.$clone(this.subscriptionType.endpoint_default_suffixes);
      } finally {
        this.isLoading = false;
      }
    },

    clearLogo() {
      this.logoUrl = null;
      this.form.logoFile = null;
    },

    clearForm() {
      this.form = {
        endpointDefaultSuffixes: [''],
      };
      this.logoUrl = null;
      this.errors = {};
    },

    createOrUpdate() {
      if (this.isNewSubscriptionType) {
        this.create();
      } else {
        this.update();
      }
    },

    async create() {
      this.isSaving = true;

      const filteredSnakeCaseArgs = convertKeysToSnakeCase(filterObjectKeys(this.form, this.whitelistedKeys));
      const response = await this.$store.dispatch('subscriptionTypes/createSubscriptionType', filteredSnakeCaseArgs);

      this.isSaving = false;

      if (response.status === 200) {
        this.clearForm();
        this.$bvModal.hide('modal-create-new-subscription-type');
        this.$bvToast.toast('Subscription Type has been created successfully.', {
          title: 'Success',
          variant: 'default'
        });
      } else if (response.status === 422) {
        if (response.data.errors) {
          this.errors = response.data.errors;
        } else {
          // Handle `param is missing or the value is empty: subscription_type` error from BE when params are all empty
          this.errors = { name: ["can't be blank"] };
          this.$bvToast.toast('Could not create subscription type', {
            variant: 'danger'
          });
        }
      } else {
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    async update() {
      this.isSaving = true;
      this.errors = {};

      try {
        const filteredSnakeCaseArgs = convertKeysToSnakeCase(filterObjectKeys(this.form, this.whitelistedKeys));

        const response = await http.put(`subscription_types/${this.subscriptionType.uuid}`, {
          subscription_type: filteredSnakeCaseArgs
        });

        const updated = response.data.subscription_type;

        if (this.form.logoFile) {
          const formData = new FormData();
          formData.append('subscription_type_image[logo]', this.form.logoFile);

          const response = await http.post(
            `subscription_types/${this.subscriptionType.uuid}/images`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );

          this.updateLogoData(response.data.subscription_type_image.url);
        } else if (this.isLogoDeleted) {
          await http.delete(`subscription_types/${this.subscriptionType.uuid}/images`);
        }

        this.$store.commit('subscriptionTypes/UPDATE_SUBSCRIPTION_TYPE', updated);

        this.$bvModal.hide('modal-' + this.subscriptionType.uuid);
        this.$bvToast.toast('Subscription Type has been updated successfully.', {
          title: 'Success',
          variant: 'default'
        });
      } catch (error) {
        if (error.status === 422) {
          this.errors = error.data.errors;

          this.$bvToast.toast(error.data.message, {
            title: 'Error',
            variant: 'danger'
          });
        } else {
          this.$bvToast.toast(
            'There was an error submitting your request. Please try again later.',
            {
              title: 'Error',
              variant: 'danger'
            }
          );
        }
      } finally {
        this.isSaving = false;
      }
    },

    updateLogoData(url) {
      this.form.logoFile = null;
      this.logoUrl = this.originalLogoUrl = url;
    },

    addEndpointSuffix() {
      this.form.endpointDefaultSuffixes.push('');
    },

    errorsForSuffix(i) {
      if (this.errors.endpoint_default_suffixes) {
        return this.errors.endpoint_default_suffixes[i];
      }
    },

    suffixInputState(i) {
      if (this.errorsForSuffix(i) && Object.keys(this.errorsForSuffix(i)).length > 0) {
        return false;
      }
    },

    suffixInputErrors(i) {
      if (!this.suffixInputState(i)) {
        const valueErrors = this.errorsForSuffix(i)?.value;
        if (valueErrors && valueErrors.length > 0) {
          return valueErrors.join(', ');
        } else {
          return 'is invalid';
        }
      }

      return '';
    },

    removeEndpointSuffix(endpointSuffix) {
      const i = this.form.endpointDefaultSuffixes.indexOf(endpointSuffix);
      this.form.endpointDefaultSuffixes.splice(i, 1);
    },
  }
}
</script>

<style scoped>
  .modal-dialog h4 {
    margin: 0;
  }

  #rate-tiers-checkbox-group {
    padding-top: 20px;
  }

  #rate-tiers-checkbox-group .form-check {
    position: relative;
    display: block;
  }

  .rate-tiers-for-sub-type {
    max-height: 30vw;
  }
</style>
