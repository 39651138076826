<template>
  <div>
    <h3 class='border-bottom pb-1 mt-3 mb-0'>Users</h3>

    <ul class="list-unstyled">
      <li v-for="user in searchResults" class="p-3 border-bottom">
        <div class="row">
          <div class="col">
            <router-link :to="`/users/${user.uuid}`">
              {{ user.username }}
            </router-link>
          </div>
          <div class="col-6">
            {{ user.email }}
          </div>
          <div class="col">
            <div class="float-end text-right">
              <span v-if="user.is_active" class="text-success">
                Enabled
              </span>
              <span v-else class="text-danger">
                Disabled
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UserSearchResults',

  props: {
    searchResults: [],
  }
}
</script>
