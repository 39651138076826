<template>
  <b-form v-on:submit.prevent="updateEndpoint">
    <div class="card-header bg-light card-title pt-3 pb-3">
      <span v-if="formDisabled" class="mb-3 w-100">
        <span class="h4 flex-fill">
          Endpoint: {{ form.name }}
        </span>

        <span class="flex-fill float-right">
          <b-button
            v-if="canUpdateEndpoints"
            class="btn btn-md"
            @click="enableForm"
          >
            Edit
            <span class="ml-2"></span>
          </b-button>
        </span>
      </span>
      <span v-else class="mb-3 w-100">
        <b-form-input
          v-model="form.name"
          :state="nameState"
          class="full-width"
          placeholder="Display name"
          name="name"
          size="lg"
        />
        <b-form-invalid-feedback :state="nameState">
          {{ nameErrors }}
        </b-form-invalid-feedback>
      </span>

      <div class="w-100">
        <span v-if="canManageEndpointActivation" class="h6">
          <span class="activate-deactivate-switch mr-2">
            <activate-deactivate-switch
              :resource="endpoint"
              switch-size="md"
              resource-name="endpoint"
              v-on:activated="activate"
              v-on:deactivated="deactivate"
            />
          </span>

          <b-link
            v-if="hasDeactivationReasons"
            v-b-modal="'deactivation-reasons-modal-' + uuid"
            class="mr-2"
          >
            Deactivation reasons
          </b-link>

          <deactivation-reasons-modal
            title="Endpoint"
            :uuid="uuid"
            :deactivationReasons="endpoint.deactivation_reasons"
          />
        </span>

        <span v-if="currentUser.is_admin" class="h6 mr-2">
          <b-link v-b-modal="'versions-modal-' + endpoint.uuid">
            <span>Versions</span>
          </b-link>

          <versions-modal
            :title="'Endpoint ' + endpoint.name"
            :uuid="endpoint.uuid"
            :versions="endpoint.versions"
          />
        </span>

        <span v-if="isMetricsActive" class="h6 mr-2">
          <router-link :to="metricsUrl">Metrics</router-link>
        </span>
      </div>
    </div>
    <div class="card-body pt-4">
      <div class="mb-3 d-flex flex-row">
        <label class="h5 mr-3">Tier</label>
        <v-select
          class="w-100"
          :disabled="formDisabled || !isAdminOrReseller"
          v-model="form.rateTier"
          :options="rateTierDropdownOptions"
          placeholder="Select a rate tier"
          :clearable="false"
        />
      </div>
<!--
      <div v-if="currentUser.is_admin || subscription.cascade_marketplace" class="mb-3">
        <b-form-checkbox
          :disabled="formDisabled || !currentUser.is_admin"
          size="lg"
          v-model="form.cascadeMarketplace"
        >
          Cascade marketplace
        </b-form-checkbox>
      </div> -->

      <div class="mb-3">
        <span class="h5">Value(s)</span>
        <span
          v-b-tooltip.hover
          class="badge pl-0 ml-2"
          :title="canUpdateEndpointValues ? 'Value(s) are only editable by admins' : 'Value(s) can\'t be edited. Only management of display name and allowed origins is permissible'"
        >
          <b-icon font-scale="1.2" icon="info-circle" />
        </span>

        <span v-if="canUpdateEndpointValues">
          <b-button
            v-if="form.valueOverride"
            :disabled="formDisabled"
            v-b-tooltip.hover
            title="Switch to default endpoint"
            class="ml-3"
            variant="outline-primary"
            size="sm"
            @click="switchToDefaultEndpoint"
          >
          <b-icon-wrench />
          </b-button>
          <b-button
            v-else
            :disabled="formDisabled"
            v-b-tooltip.hover
            title="Switch to custom endpoint"
            class="ml-3"
            variant="outline-primary"
            size="sm"
            @click="switchToCustomEndpoint"
          >
            <b-icon-wrench />
          </b-button>
        </span>
      </div>

      <div v-for="(_, i) in form.values" :key="`${endpoint.uuid}` + i" class="row mb-1">
        <div class="col">
          <div class="d-flex flex-row mb-3">
            <div class="flex-fill">
              <b-form-input
                v-model.trim="form.values[i]"
                :disabled="valueDisabled"
                :state="errors.value_override ? false : null"
              />
            </div>
          </div>
        </div>
      </div>

      <b-form-invalid-feedback v-if="errors.value_override" :state="false">
        Value {{ errors.value_override.join(', ') }}
      </b-form-invalid-feedback>

      <div class="my-3">
        <span class="h5">Allowed Origins</span>
        <b-button
          v-if="canAddOrigin"
          :disabled="formDisabled"
          class="ml-3"
          size="sm"
          variant="outline-primary"
          @click="addAllowedOrigin"
        >
          <b-icon-plus-lg />
        </b-button>
      </div>

      <div class="table-responsive scrollbar">
        <table class="table table-striped overflow-hidden mb-3">
          <thead>
            <tr class="btn-reveal-trigger">
              <th>Value</th>
              <th>Status</th>
              <th v-if="anyActionsPermittedOnOrigins"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(allowedOrigin, i) in form.allowedOrigins" :key="i">
              <td>
                <b-form-input
                  v-model="form.allowedOrigins[i].value"
                  :value="allowedOrigin.value"
                  :disabled="formDisabled"
                />
              </td>
              <td>
                <span
                  v-if="allowedOrigin.is_active"
                  class="badge"
                  :class="isAccountAndSubscriptionAndEndpointActive ? 'badge-soft-primary' : 'badge-soft-secondary'"
                >Enabled</span>
                <span
                  v-else
                  class="badge"
                  :class="isAccountAndSubscriptionAndEndpointActive ? 'badge-soft-danger' : 'badge-soft-secondary'"
                >Disabled</span>
              </td>
              <td v-if="anyActionsPermittedOnOrigins" class="text-right">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret size="sm"
                >
                  <template #button-content>
                    <b-icon-three-dots></b-icon-three-dots>
                  </template>

                  <b-dropdown-item
                    v-if="canManageOriginActivation"
                    @click="showAllowedOriginActivationModal(allowedOrigin)"
                  >
                    <span v-if="allowedOrigin.is_active" class="text-danger">Deactivate</span>
                    <span v-else class="text-success">Activate</span>
                  </b-dropdown-item>

                  <b-dropdown-item v-if="canDeleteOrigins" @click="removeAllowedOrigin(allowedOrigin)">
                    <span class="text-danger">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>

                <activate-modal
                  v-if="allowedOrigin.uuid"
                  resource-name="allowed_origin"
                  :uuid="allowedOrigin.uuid"
                  v-on:activated="toggleAllowedOriginActivation"
                />

                <deactivate-modal
                  v-if="allowedOrigin.uuid"
                  resource-name="allowed_origin"
                  hide-reason
                  :uuid="allowedOrigin.uuid"
                  v-on:deactivated="toggleAllowedOriginActivation"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-group mt-3 mb-0">
        <span v-if="!formDisabled">
          <b-button type="submit" variant="primary">Save</b-button>
          <b-button @click="undoFormChanges" variant="secondary">Cancel</b-button>
        </span>
      </div>

      <div v-if="errorExplanation">
        <b-alert variant="danger" show class="mt-3">
          <p class="mb-0">
            <b-icon-exclamation-triangle-fill />
            {{ errorExplanation }}
          </p>
        </b-alert>
      </div>
    </div>
  </b-form>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

// components
import versionsModal from './versions-modal';
import activateDeactivateSwitch from './activate-deactivate-switch';

export default {
  name: 'EndpointUpdateForm',

  components: { versionsModal, activateDeactivateSwitch },

  props: {
    endpoint: { type: Object, required: true },
    subscription: { type: Object, required: true },
    supportedRateTiers: {
      type: Array,
      default: () => ([])
    },

    isAccountAndSubscriptionActive: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },

  data() {
    return {
      form: {
        rateTier: this.endpoint.rate_tier,
        allowedOrigins: this.endpoint.allowed_origins,
        values: this.endpoint && this.endpoint.values,
        valueOverride: this.endpoint.value_override,
        name: this.endpoint.name,
        cascadeMarketplace: this.endpoint.cascade_marketplace,
      },
      formDisabled: true,
      originalForm: {},
      uuid: this.endpoint.uuid,
      errors: {},
      valuesDisabled: true,
    }
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser',
      'canUpdateEndpoints',
      'canManageEndpointActivation',
      'canCreateTokens',
      'canUpdateEndpointValues',
      'canDeleteOrigins',
      'canDeactivateOrigins',
      'canManageOriginActivation'
    ]),

    rateTierDropdownOptions() {
      return this.supportedRateTiers.map((rt) => (
        rt.name
      ));
    },

    canAddOrigin() {
      return this.canCreateTokens || this.form.allowedOrigins.length < 1;
    },

    nameState() {
      return this.nameErrors ? false : null;
    },

    nameErrors() {
      const errors = this.errors['name'];
      if (errors) { return errors.join(', ') }
    },

    errorExplanation() {
      if (this.errors) {
        const baseErrors = this.errors.base?.join(', ');
        const allowedOriginErrors = this.errors['allowed_origins.value']?.map((error) => {
          return 'Value ' + error;
        })?.join(', ');

        return baseErrors || allowedOriginErrors;
      }
    },

    isAdminOrReseller() {
      return this.currentUser.is_admin || this.currentUser.is_reseller;
    },

    anyActionsPermittedOnOrigins() {
      return this.currentUser.is_admin || this.canManageOriginActivation || this.canDeleteOrigins;
    },

    isAccountAndSubscriptionAndEndpointActive() {
      return this.isAccountAndSubscriptionActive && this.endpoint.is_active;
    },

    metricsUrl() {
      return {
        path: `/accounts/${this.subscription.account.uuid}/metrics`,
        query: {
          selected_subscription_uuid: this.subscription.uuid,
          selected_endpoint_uuid: this.uuid
        }
      };
    },

    isMetricsActive() {
      if (this.currentUser.is_admin) {
        return true;
      } else {
        return this.isAccountAndSubscriptionAndEndpointActive && this.subscription?.account.metrics_enabled;
      }
    },

    valueDisabled() {
      return this.valuesDisabled || !this.canUpdateEndpointValues;
    },

    isCustomValue() {
      return this.form.valueOverride;
    },

    hasDeactivationReasons() {
      return this.endpoint.deactivation_reasons?.length;
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      this.setOriginalForm();
    },

    hasEndpointValuesChanged() {
      if (this.originalForm.values.length !== this.form.values.length) {
        return true;
      } else {
        return !this.originalForm.values.every((v, i) => {
          return v === this.form.values[i];
        });
      }
    },

    async updateEndpoint() {
      this.errors = {};
      this.baseErrors = [];

      let valueOverride = this.form.valueOverride;
      if (this.hasEndpointValuesChanged()) {
        // values changed, what did it change to
        if (this.originalForm.valueOverride && this.originalForm.valueOverride != this.form.valueOverride) {
          // no longer an override, and we need to unset it.
          valueOverride = '';
        } else {
          // there's now an override or an updated override
          valueOverride = this.form.values[0];
        }
      }

      try {
        const response = await http.put(`endpoints/${this.uuid}`, {
          endpoint: {
            name: this.form.name,
            cascade_marketplace: this.form.cascadeMarketplace,
            rate_tier: this.form.rateTier,
            value_override: valueOverride,
            allowed_origins: this.form.allowedOrigins.map((allowedOrigin) => {
              if (allowedOrigin.value.startsWith('chrome-extension://') || allowedOrigin.value.startsWith('moz-extension://')) {
                return {
                  value: allowedOrigin.value,
                  is_active: allowedOrigin.is_active
                };
              } else {
                return {
                  value: allowedOrigin.value.replace(/.*\:\/\//, ""),
                  is_active: allowedOrigin.is_active
                };
              }
            })
          }
        });

        this.form.valueOverride = response.data.endpoint.value_override;
        this.form.allowedOrigins = response.data.endpoint.allowed_origins;

        this.$bvToast.toast('You successfully updated an endpoint', {
          title: 'Endpoint updated',
          variant: 'default'
        });

        if (this.canUpdateEndpointValues && !this.isCustomValue) {
          this.switchToDefaultEndpoint();
        }

        this.setOriginalForm();
        this.disableForm();
      }

      catch (error) {
        this.$bvToast.toast('Could not update endpoint', {
          title: 'Update failed',
          variant: 'danger'
        });

        if (error?.data?.errors) {
          this.errors = error.data.errors;
        }
      }
    },

    switchToCustomEndpoint() {
      this.valuesDisabled = false;

      this.form.values = [''];
      this.form.valueOverride = '';
    },

    switchToDefaultEndpoint() {
      this.valuesDisabled = true;
      this.form.values = this.endpoint.default_values;
      this.form.valueOverride = '';
    },

    addAllowedOrigin() {
      this.form.allowedOrigins.push({value: '', is_active: true });
    },

    removeAllowedOrigin(origin) {
      const i = this.form.allowedOrigins.indexOf(origin);
      this.form.allowedOrigins.splice(i, 1);
    },

    showAllowedOriginActivationModal(allowedOrigin) {
      if (allowedOrigin.is_active) {
        this.$bvModal.show(`deactivate-modal-${allowedOrigin.uuid}`);
      } else {
        this.$bvModal.show(`activate-modal-${allowedOrigin.uuid}`);
      }
    },

    toggleAllowedOriginActivation(uuid) {
      [this.form, this.originalForm].forEach(f => {
        const token = f.allowedOrigins.find(entry => entry.uuid === uuid);
        const i = f.allowedOrigins.indexOf(token);
        f.allowedOrigins[i].is_active = !f.allowedOrigins[i].is_active;
      })

      this.formDisabled = true;
    },

    setOriginalForm() {
      const stringified = JSON.stringify(this.form);
      this.originalForm = JSON.parse(stringified);
    },

    undoFormChanges() {
      const stringified = JSON.stringify(this.originalForm);
      this.form = JSON.parse(stringified);
      this.disableForm();
    },

    enableForm() {
      this.formDisabled = false;

      if (this.canUpdateEndpointValues && this.isCustomValue) {
        this.valuesDisabled = false;
      }
    },

    disableForm() {
      this.formDisabled = true;
      this.valuesDisabled = true;
      this.errors = {}
    },

    activate() {
      this.endpoint.is_active = true;
    },

    deactivate(reason) {
      this.endpoint.is_active = false;
      this.endpoint.deactivation_reasons.push(reason);
    },
  }
}
</script>

<style scoped>
.card-header {
  min-height: 68px;

  .badge {
    font-size: 0.83rem
  }

  .activate-deactivate-switch {
    display: inline-block;
    position: relative;
    top: -0.24rem;
  }
}
</style>

