<template>
  <b-form v-on:submit.prevent="createEndpoint">
    <b-form-group label="Display name">
      <b-form-input
        v-model.trim="name"
        :state="nameState"
        placeholder="Internal name for reference"
      ></b-form-input>
      <b-form-invalid-feedback :state="nameState">
        {{ nameErrors }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Rate tier">
      <b-form-select
        class="form-select"
        v-model="rateTier"
        :options="rateTierDropdownOptions"
        :state="rateTierState"
      ></b-form-select>
      <b-form-invalid-feedback :state="rateTierState">
        {{ rateTierErrors }}
      </b-form-invalid-feedback>
    </b-form-group>

    <div>
      <legend class="col-form-label">Allowed origin(s)</legend>
    </div>

    <div class="row mt-1">
      <b-form-group class="col-6">
        <b-form-input
          v-model.trim="allowedOrigin1"
          :state="allowedOrigin1State"
          placeholder="Optional allowed origin"
        ></b-form-input>
        <b-form-invalid-feedback :state="allowedOrigin1State">
          {{ allowedOrigin1Errors }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="col-6">
        <b-form-input
          v-model.trim="allowedOrigin2"
          :state="allowedOrigin2State"
          placeholder="Optional allowed origin"
        ></b-form-input>
        <b-form-invalid-feedback :state="allowedOrigin2State">
          {{ allowedOrigin2Errors }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>

    <div class="form-group pt-2">
      <input type="submit" value="Add endpoint" class="btn btn-primary">
    </div>

    <div v-if="baseErrorExplanation">
      <b-alert variant="danger" show>
        <p class="mb-0">
          <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill>
          {{ baseErrorExplanation }}
        </p>
      </b-alert>
    </div>
  </b-form>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'EndpointForm',

  props: {
    subscriptionUuid: { type: String, required: true },
    supportedRateTiers: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      name: null,
      rateTier: null,
      allowedOrigin1: null,
      allowedOrigin2: null,
      errors: {},
      baseErrors: []
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    rateTierDropdownOptions() {
      if (this.supportedRateTiers.length === 1) {
        return [this.supportedRateTiers[0].name];
      } else {
        let dropdownOptions = this.supportedRateTiers.map((rt) => (
          rt.name
        ));

        dropdownOptions.unshift({ value: null, text: 'Select a rate tier' });
        return dropdownOptions;
      }
    },

    nameState() {
      if (this.errors['name']) { return false; }
    },

    nameErrors() {
      const errors = this.errors['name'];
      if (errors) { return errors.join(', ') }
    },

    rateTierState() {
      if (this.errors['rate_tier']) { return false; }
    },

    rateTierErrors() {
      const errors = this.errors['rate_tier'];
      if (errors) { return errors.join(', ') }
    },

    allowedOrigin1State() {
      // todo
      // if (this.errors['allowed_origins'])
    },

    allowedOrigin1Errors() {
      // todo
    },

    allowedOrigin2State() {
      // todo
    },

    allowedOrigin2Errors() {
      // todo
    },

    baseErrorExplanation() {
      return this.baseErrors.join(', ');
    },
  },

  methods: {
    async onCreated() {
      if (this.supportedRateTiers.length === 1) {
        this.rateTier = this.supportedRateTiers[0].name;
      }
    },

    async createEndpoint() {
      this.errors = {};
      this.baseErrors = [];

      try {
        let origins = [];
        if (this.allowedOrigin1) { origins.push({ value: this.allowedOrigin1 }) };
        if (this.allowedOrigin2) { origins.push({ value: this.allowedOrigin2 }) };

        const response = await http.post(`subscriptions/${this.subscriptionUuid}/endpoints`, {
          endpoint: {
            name: this.name,
            rate_tier: this.rateTier,
            allowed_origins: origins,
          }
        });

        this.$emit('add-endpoint', response.data.endpoint);

        this.$bvToast.toast('You successfully created a new endpoint', {
          title: 'Endpoint created',
          variant: 'default'
        });

        this.resetForm();
      }

      catch (error) {
        this.$bvToast.toast('Could not create endpoint', {
          title: 'Create failed',
          variant: 'danger'
        });

        if (error?.data?.errors) {
          this.errors = error.data.errors;

          if (this.errors.base) {
            this.baseErrors = this.errors.base;
          }
        }
      }
    },

    resetForm() {
      this.name = null;
      this.rateTier = null;
      this.allowedOrigin1 = null;
      this.allowedOrigin2 = null;
      this.errors = {};
      this.baseErrors = [];
    }
  }
}
</script>
