<template>
  <b-form v-on:submit.prevent="createLoadBalancer">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <b-form-group label="Hostname">
          <b-form-input v-model="hostname" :state="hostnameState">
          </b-form-input>
          <b-form-invalid-feedback :state="hostnameState">
            {{ hostnameErrors }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="col-lg-5 col-md-12">
        <b-form-group label="Subscription type">
          <b-form-select
            class="form-select"
            v-model="subscriptionTypeUuid"
            :options="subscriptionTypesDropdownOptions"
            required
            :state="subscriptionTypeState"
          ></b-form-select>
          <b-form-invalid-feedback :state="subscriptionTypeState">
            {{ subscriptionTypeErrors }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>

    <b-button type="submit" class="btn-primary">
      Save
    </b-button>
  </b-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LoadBalancerForm',

  data() {
    return {
      hostname: null,
      subscriptionTypeUuid: null,
      errors: {}
    }
  },

  computed: {
    ...mapState({
      subscriptionTypes: state => state.subscriptionTypes.subscriptionTypes
    }),

    hostnameState() {
      if (this.errors['hostname']) { return false; }
    },

    hostnameErrors() {
      const errors = this.errors['hostname'];
      if (errors) { return errors.join(', ') }
    },

    subscriptionTypeState() {
      if (this.errors['subscription_type']) { return false; }
    },

    subscriptionTypeErrors() {
      const errors = this.errors['subscription_type'];
      if (errors) { return errors.join(', ') }
    },

    subscriptionTypesDropdownOptions() {
      let dropdownOptions = this.subscriptionTypes.map((st) => (
        { value: st.uuid, text: st.name }
      ));

      dropdownOptions.unshift({ value: null, text: 'Select a subscription type' });

      return dropdownOptions;
    },
  },

  methods: {
    async createLoadBalancer() {
      const response = await this.$store.dispatch(
        'loadBalancers/createLoadBalancer',
        this.loadBalancerParams()
      );

      if (response.status === 200) {
        this.$bvToast.toast('You successfully created a new load balancer', {
          title: 'Load balancer created',
          variant: 'default'
        });

        this.clearLoadBalancerFrom();
      } else {
        this.errors = response.data.errors;
      }
    },

    loadBalancerParams () {
      return {
        hostname: this.hostname,
        subscription_type_uuid: this.subscriptionTypeUuid
      }
    },

    clearLoadBalancerFrom() {
      this.hostname = null;
      this.subscriptionTypeUuid = null;
      this.errors = {};
    }
  }
}
</script>
