<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="h2">Helio payments</div>
      <p class="fs--1">Viewable only by admins</p>
    </div>

    <div class="card-body h-100">
      <div class="table-responsive table-hover scrollbar">
        <table class="table table-sm">
          <thead class="border-bottom">
            <tr>
              <th>Timestamp</th>
              <th>Amount</th>
              <th>Details</th>
              <th>Solscan</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="isLoading">
              <tr role="row">
                <td colspan="6">
                  <div class="text-center my-4">
                    <b-spinner class="align-middle mr-3"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>
            </template>

            <template>
              <tr v-for="payment in payments" :key="payment.id" class="align-middle">
                <td>{{ payment.time.replace('T', ' ') }}</td>
                <td>
                  ${{ payment.convertedAmount }}
                </td>
                <td>
                  <b-link v-b-modal="'helio-payment-modal-' + payment.id">
                    View
                  </b-link>
                </td>
                <td>
                  <a :href="payment.solScanLink" target="_blank">
                    View
                  </a>
                </td>

                <b-modal
                  centered
                  size="lg"
                  :id="'helio-payment-modal-' + payment.id"
                  class="p-3" header-bg-variant="light"
                  header-class="py-3"
                  title="Payment details"
                  :ok-only="true"
                  ok-title="Close"
                >
                  <pre class="text-monospace">{{ payment }}</pre>
                </b-modal>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <p class="fs--1">{{ payments.length }} Payment(s)</p>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

export default {
  name: 'HelioPaymentsCard',

  data() {
    return {
      payments: [],
      isLoading: false,
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters(
      'sessions', ['currentUser'],
    ),
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;
        await this.fetchPayments(this.$route.params.uuid);
      }
      finally {
        this.isLoading = false;
      }
    },

    async fetchPayments(uuid) {
      const response = await http.get(`accounts/${uuid}/helio_payments`);
      this.payments = response.data.helio_payments;
    },
  }
}
</script>
