<template>
  <div>
    <b-form v-on:submit.prevent="searchAccounts" class="mb-3">
      <div class="row">
        <div class="col-sm-6">
          <b-input
            v-model="searchAccountsTerm"
            placeholder="Search by name"
            type="search"
            class="w-100"
          />
        </div>
        <div class="col-sm-6">
          <v-select
            v-model="paymentMethod"
            :options="paymentMethodsDropdownOptions"
            placeholder="Filter by payment method"
            :clearable="true"
          />
        </div>
      </div>

      <b-form-checkbox
        @change="setHideDisabledStatusAndSearch"
        v-model="hideDisabled"
        name="check-button"
        switch
        size="md"
        class="ml-1 my-3">
        Hide disabled
      </b-form-checkbox>
    </b-form>

    <div v-if="isLoading">
      <div class="text-center min-height mb-3" :style="`height: ${loadingHeight}px`">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </div>
    <div v-else class="table-responsive scrollbar" ref="accountsTable">
      <table class="table table-hover table-sm overflow-hidden">
        <thead>
        <tr class="text-center">
          <th class="text-left">
            <a href="#" @click.prevent="sortBy('name')" class="d-flex align-items-center">
              Name
              <span v-if="sortKey === 'name'" class="ml-1">
                <BIconArrowUp v-if="sortOrder === 'asc'"/>
                <BIconArrowDown v-else/>
              </span>
            </a>
          </th>
          <th>Users</th>
          <th>Subs</th>
          <th>Tokens</th>
          <th>Endpoints</th>
          <th>
            <a
              href="#"
              @click.prevent="sortBy('marketplace')"
              class="d-flex align-items-center justify-content-center"
              v-b-tooltip.hover
              title="Controls access to Cascade Marketplace bidding (unused & currently in development)"
            >
              Marketplace
              <span v-if="sortKey === 'marketplace'" class="ml-1">
                <BIconArrowUp v-if="sortOrder === 'asc'"/>
                <BIconArrowDown v-else/>
              </span>
            </a>
          </th>
          <th>Status</th>
          <th v-if="canManageAccountActivation"></th>
        </tr>
        </thead>
        <tbody role="rowgroup">
        <tr v-for="account in accounts" :key="account.uuid">
          <td>
            <router-link :to="{ name: 'editAccount', params: { uuid: account.uuid } }">
              {{ account.name }}
            </router-link>
          </td>
          <td class="text-center">
            {{ account.users_count }}
          </td>
          <td class="text-center">
            {{ account.subscriptions.length }}
          </td>
          <td class="text-center">
            {{ account.tokens_count }}
          </td>
          <td class="text-center">
            {{ account.endpoints_count }}
          </td>
          <td class="text-center">
            <span v-if="account.cascade_marketplace" class="text-success">
              Enabled
            </span>
            <span v-else class="text-danger">
              Disabled
            </span>
          </td>
          <td class="text-center">
            <span v-if="account.is_active" class="text-success">
              Enabled
            </span>
            <span v-else class="text-danger">
              Disabled
            </span>
          </td>
          <td v-if="canManageAccountActivation" class="text-right">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret size="sm">
              <template #button-content>
                <b-icon-three-dots></b-icon-three-dots>
              </template>
              <b-dropdown-item @click="showAccountActivationModal(account)">
                <span v-if="account.is_active" class="text-danger">Deactivate</span>
                <span v-else class="text-success">Activate</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="hasAccountDeactivationReasons(account)"
                v-b-modal="'deactivation-reasons-modal-' + account.uuid"
              >
                <span class="text-secondary">Deactivation reasons</span>
              </b-dropdown-item>
            </b-dropdown>

            <activate-modal
              resource-name="account"
              is-admin-path
              :uuid="account.uuid"
              v-on:activated="toggleAccountActivation"
            />

            <deactivate-modal
              resource-name="account"
              is-admin-path
              :uuid="account.uuid"
              v-on:deactivated="setAccountDeactivationReason"
            />

            <deactivation-reasons-modal
              title="Account"
              :uuid="account.uuid"
              :deactivationReasons="account.deactivation_reasons"
              :deactivationReason="account.deactivationReason"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="meta.total_pages > 1" class="overflow-auto">
      <b-pagination-nav
        :link-gen="linkGen"
        v-model="currentPage"
        @change="changePage"
        :number-of-pages="meta.total_pages"
        use-router
      />
    </div>

    <p class="fs--1">{{ meta.total_count }} Accounts</p>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import http from '@/services/http';
import debounce from 'lodash.debounce';
import {BIconArrowUp, BIconArrowDown} from 'bootstrap-vue';

export default {
  name: 'AccountsTable',
  components: {BIconArrowUp, BIconArrowDown},

  data() {
    return {
      accounts: [],
      perPage: 50,
      currentPage: 1,
      meta: {},
      searchAccountsTerm: null,
      paymentMethod: null,
      hideDisabled: false,
      isLoading: false,
      loadingHeight: 0,
      sortKey: null,
      sortOrder: null,
      isSorted: false,
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser', 'canManageAccountActivation'
    ]),

    paymentMethodsDropdownOptions() {
      return [
        {value: 'manual', label: 'Manual'},
        {value: 'helio', label: 'Hel.io'},
        {value: 'coinflow', label: 'Coinflow'},
      ];
    },

    findOptionByValue() {
      return this.paymentMethodsDropdownOptions.find(option =>
        option.value === this.$route.query.accounts_payment_method
      );
    },

    sortedAccounts() {
      if (!this.isSorted) {
        return this.accounts;
      }
      return [...this.accounts].sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1;
        if (this.sortKey === 'name') {
          return modifier * a.name.localeCompare(b.name);
        } else if (this.sortKey === 'marketplace') {
          return modifier * (a.cascade_marketplace === b.cascade_marketplace ? 0 : a.cascade_marketplace ? -1 : 1);
        }
        return 0;
      });
    },
  },

  watch: {
    searchAccountsTerm: 'debouncedSearchAccounts',
    paymentMethod: 'searchAccounts',
  },

  methods: {
    async onCreated() {
      if (this.$route.query.accounts_page) {
        this.currentPage = parseInt(this.$route.query.accounts_page);
      }

      this.getHideDisabledStatus();

      if (this.$route.query.accounts_payment_method) {
        this.paymentMethod = this.findOptionByValue;
      } else {
        await this.fetchAccounts();
      }

      if (this.meta.total_pages > 0 && this.currentPage > this.meta.total_pages) {
        await this.searchAccounts();
      }
    },

    sortBy(key) {
      if (this.sortKey === key) {
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc';
        } else if (this.sortOrder === 'desc') {
          this.sortOrder = null;
          this.sortKey = null;
          this.isSorted = false;
          this.fetchAccounts();
          return;
        } else {
          this.sortOrder = 'asc';
        }
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
      this.isSorted = true;
      this.fetchAccounts();
    },

    async fetchAccounts() {
      try {
        this.loadingHeight = this.$refs.accountsTable?.clientHeight;
        this.isLoading = true;

        let params = {
          per: this.perPage,
          page: this.currentPage,
          sort_by: this.sortKey,
          sort_order: this.sortOrder,
        }

        if (this.isSorted) {
          params.sort_by = this.sortKey;
          params.sort_order = this.sortOrder;
        }

        if (this.paymentMethod) {
          params.payment_method = this.paymentMethod.value;
        }

        if (this.searchAccountsTerm) {
          params.name = this.searchAccountsTerm;
        }

        if (this.hideDisabled) {
          params.is_active = true;
        }

        const response = await http.get('accounts', {params: params});

        this.accounts = response.data.accounts;
        this.meta = response.data.meta;
      } catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      } finally {
        this.isLoading = false;
      }
    },

    linkGen(pageNum) {
      let query = pageNum === 1 ? '?' : `?accounts_page=${pageNum}`;

      if (this.paymentMethod) {
        query += `&accounts_payment_method=${this.paymentMethod.value}`;
      }

      return query;
    },

    debouncedSearchAccounts: debounce(function () {
      this.searchAccounts();
    }, 666),

    async searchAccounts() {
      this.goToFirstPage();
      await this.fetchAccounts()
    },

    goToFirstPage() {
      let query = {accounts_page: this.currentPage = '1'};

      if (this.paymentMethod) {
        query.accounts_payment_method = this.paymentMethod.value;
      }

      if (this.$route.query != query) {
        this.$router.push({name: this.$route.name, query: query});
      }
    },

    showAccountActivationModal(account) {
      if (account.is_active) {
        this.$bvModal.show(`deactivate-modal-${account.uuid}`);
      } else {
        this.$bvModal.show(`activate-modal-${account.uuid}`);
      }
    },

    toggleAccountActivation(uuid) {
      const account = this.accounts.find(entry => entry.uuid === uuid);
      const i = this.accounts.indexOf(account);
      this.accounts[i].is_active = !this.accounts[i].is_active;
    },

    setAccountDeactivationReason(deactivationReason, uuid) {
      const account = this.accounts.find(entry => entry.uuid === uuid);
      const i = this.accounts.indexOf(account);
      this.accounts[i].deactivationReason = deactivationReason;

      this.toggleAccountActivation(uuid);
    },

    hasAccountDeactivationReasons(account) {
      return (account.deactivation_reasons
        && account.deactivation_reasons.length
      ) || account.deactivationReason;
    },

    async changePage(newPage) {
      this.currentPage = newPage;
      await this.fetchAccounts();
    },

    getHideDisabledStatus() {
      const savedStatus = localStorage.getItem("hideDisabledAccounts");
      if (savedStatus) {
        this.hideDisabled = savedStatus === 'true';
      }
    },

    setHideDisabledStatusAndSearch() {
      this.goToFirstPage();
      localStorage.setItem("hideDisabledAccounts", this.hideDisabled);
      this.fetchAccounts();
    },
  }
}
</script>
<style scoped>
th a {
  color: inherit;
  text-decoration: none;
}

th a:hover {
  text-decoration: underline;
}

.fa-sort-up, .fa-sort-down {
  margin-left: 5px;
}
</style>
