<template>
  <b-modal
    centered
    id='modal-merge-accounts'
    class="p-3"
    header-bg-variant="light"
    header-class="py-3"
    size="md"
    hide-footer
  >
    <template #modal-title>
      <h4>Merge Accounts</h4>
    </template>

    <p>
      Please select source and destination account and click on the "Merge" button. Source account will be set to inactive and all of its subscriptions, tokens, and endpoints will be assigned to destination account.
    </p>

    <b-form v-on:submit.prevent="mergeAccounts">
      <div class="row mt-3">
        <div class="col-lg-12">
          <b-form-group
            label="Source account"
            :class="sourceAccountError ? 'account-error' : ''"
          >
            <v-select
              v-model="form.sourceAccount"
              placeholder="Select an account"
              :options="accountsDropdownOptions"
              :clearable="false"
              :change="validateForm()"
            ></v-select>
            <b-form-invalid-feedback :state="sourceAccountState">
              {{ sourceAccountError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <b-form-group
            label="Destination account"
            :class="destinationAccountError ? 'account-error' : ''"
          >
            <v-select
              v-model="form.destinationAccount"
              placeholder="Select an account"
              :options="accountsDropdownOptions"
              :clearable="false"
              :change="validateForm()"
            ></v-select>
            <b-form-invalid-feedback :state="destinationAccountState">
              {{ destinationAccountError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 form-group">
          <b-button type="submit" class="btn-primary">
            Merge
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<style scoped>
.modal-dialog h4 {
  margin: 0;
}
</style>

<script>
import http from '@/services/http';

export default {
  name: 'MergeAccountsModal',

  data() {
    return {
      form: {
        sourceAccount: null,
        destinationAccount: null,
        submitted: false
      },
      sourceAccountError: null,
      destinationAccountError: null,
      allAccounts: [],
    };
  },

  computed: {
    accountsDropdownOptions() {
      if (this.allAccounts.length) {
        return this.allAccounts.map((a) => (
          { value: a.uuid, label: a.name }
        ));
      }
    },

    sourceAccountState() {
      if (this.sourceAccountError) { return false; }
    },

    destinationAccountState() {
      if (this.destinationAccountError) { return false; }
    },

    isFormValid() {
      return !this.sourceAccountError && !this.destinationAccountError
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      await this.findAllAccounts();
    },

    async findAllAccounts() {
      this.allAccounts = await this.$store.dispatch('accounts/fetchAccountOptions');
    },

    async mergeAccounts() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to merge source and destination accounts?', {
        okVariant: 'primary',
        okTitle: 'Yes',
      })
      .then(async value => {
        if (value === true) {
          this.form.submitted = true;
          this.validateForm()

          if (this.isFormValid) {
            const response = await this.$store.dispatch(
              'accounts/merge',
              {
                sourceAccountUuid: this.form.sourceAccount.value,
                destinationAccountUuid: this.form.destinationAccount.value
              }
            );

            if (response.status === 200) {
              this.$root.$bvToast.toast(`You successfully merged source and destination accounts`, {
                title: 'Accounts merged',
                variant: 'default'
              });

              this.$router.push({path: `/accounts/${response.data.account.uuid}`})
            } else {
              this.showErrorToast(response);
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    showErrorToast(response) {
      let message = 'An unexpected error occurred'

      if (response.data.errors) {
        message = response.data.errors?.join('. ');
      } else if (response.data.message) {
        message = response.data.message;
      }

      this.$bvToast.toast(message, {
        title: 'Accounts could not be merged',
        variant: 'danger'
      });
    },

    validateForm() {
      if (this.form.submitted) {
        this.validateSourceAccount();
        this.validateDestinationAccount();
      }
    },

    validateSourceAccount() {
      this.sourceAccountError = this.form.sourceAccount ? "" : "Can't be blank"
    },

    validateDestinationAccount() {
      this.destinationAccountError = this.form.destinationAccount ? "" : "Can't be blank"
    }
  }
}
</script>
