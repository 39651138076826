<template>
  <div>
    <b-form v-on:submit.prevent="createToken">
      <b-alert v-if="errors.base" show variant="danger">{{ errors.base.join(', ') }}</b-alert>

      <b-form-group label="Display name">
        <b-form-input
          v-model="name"
          :state="nameState"
          placeholder="Internal name for reference"
        />
        <b-form-invalid-feedback :state="nameState">
          {{ nameErrors }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-if="supportedRateTiers" label="Rate tier">
        <b-form-select
          v-model="rateTier"
          :options="rateTierDropdownOptions"
          :state="rateTierState"
        />
        <b-form-invalid-feedback :state="rateTierState">
          {{ rateTierErrors }}
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="form-group">
        <input type="submit" value="Add token" class="btn btn-primary">
      </div>
    </b-form>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'TokenForm',

  data() {
    return {
      name: null,
      rateTier: null,
      errors: {}
    }
  },

  created() {
    this.rateTier = this.rateTierDropdownOptions[0];
  },

  props: {
    supportedRateTiers: {
      type: Array,
      required: true
    },
  },

  computed: {
    rateTierDropdownOptions() {
      return this.supportedRateTiers.map(rt => rt.name);
    },

    nameState() {
      if (this.errors['name']) { return false; }
    },

    nameErrors() {
      const errors = this.errors['name'];
      if (errors) { return errors.join(', ') };
    },

    rateTierState() {
      if (this.errors['rate_tier']) { return false; }
    },

    rateTierErrors() {
      const errors = this.errors['rate_tier'];
      if (errors) { return errors.join(', ') };
    },
  },

  methods: {
    async createToken() {
      try {
        const response = await http.post(`subscriptions/${this.$route.params.uuid}/tokens`, {
          token: {
            name: this.name,
            rate_tier: this.rateTier
          }
        });

        this.$emit('add-token', response.data.token);

        this.$bvToast.toast('You successfully created a new token', {
          title: 'Token created',
          variant: 'default'
        });

        this.name = null;
        this.rateTier = this.rateTierDropdownOptions[0];
        this.errors = {};
      }

      catch (error) {
        this.$bvToast.toast('Could not create new token', {
          title: 'Error',
          variant: 'danger'
        });

        console.log(error);
        this.errors = error.data.errors;
      }
    }
  }
}
</script>
