<template>
  <div>
    <h2>Active users</h2>
    <p>Coming soon</p>
    <!-- <v-chart
      v-if="chartDataLoaded"
      :option="chartOptions"
      autoresize
      style="height: 400px;"
    /> -->
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';
import http from '@/services/http';
import { sharedOptions } from '@/services/metrics';

export default {
  name: 'UsersTab',
  components: { 'v-chart': VueECharts },

  props: {
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      chartDataLoaded: false,
      dailyUserSummaries: [],
      sharedOptions,
    }
  },

  created() {
    // this.onCreated();
  },

  computed: {
    chartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: this.sharedOptions.tooltipOptions
        },
        dataset: { source: this.chartData, dimensions: ['date', 'num_active_users'] },
        xAxis: { type: 'category' },
        yAxis: { type: 'value' },
        grid: {
          top: 110,
          left: 75,
          right: 15,
          height: 160
        },
        textStyle: this.sharedOptions.textStyle,
        series: [
          {
            name: 'active users: ',
            type: 'bar',
            encode: { x: 'timestamp', y: 'value' },
            areaStyle: { color: '#00f', opacity: 0.5 },
            showSymbol: false,
          }
        ]
      };
    },

    chartData() {
      return this.dailyUserSummaries.map((summary) => {
        return {
          date: summary.date,
          num_active_users: summary.active_users_count,
        }
      });
    },
  },

  methods: {
    async onCreated() {
      await this.fetchUserSummaries();
    },

    async fetchUserSummaries() {
      this.chartDataLoaded = false;

      const response = await http.get('/admin/daily_user_summaries', {
        params: {
          start_date: this.startDate,
          end_date: this.endDate,
        }
      });

      this.dailyUserSummaries = response.data.daily_user_summaries;

      this.chartDataLoaded = true;
    },
  },
}
</script>