<template>
  <div class="card">
    <div class="card-header">
      <span class="h2 float-start mr-2">Account Features</span>

      <span class="float-right">
        <b-button
          v-if="formDisabled"
          @click="enableAccountForm"
          variant="primary"
        >
          Edit
          <span class="ml-2 far fa-edit"></span>
        </b-button>
      </span>
    </div>

    <div class="card-body">
      <b-form v-on:submit.prevent="updateFeatures">
        <div v-if="isLoading">
          <div class="row">
            <div class="col-12">
              <div class="text-center my-4">
                <b-spinner class="align-middle mr-3"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
          </div>
        </div>

        <b-form-group label="Payment method" label-class="h4">
          <b-form-radio-group
            v-model="form.payment_method"
            :options="paymentMethodOptions"
            :disabled="formDisabled"
            button-variant="outline-secondary"
            buttons
          ></b-form-radio-group>
        </b-form-group>

        <div v-if="showCoinflowLink" class="mb-3">
          <b-link v-b-modal="'coinflow-payment-link'">
            Coinflow Payment link
          </b-link>

          <b-modal id="coinflow-payment-link" title="Coinflow payment link" ok-title="Close" centered ok-only>
            <p>
              A Coinflow payment link requires an email address to associate the payment to. Select a user from the list below to generate the link.
            </p>

            <template v-if="usersForAccount.length === 0">
              <b-badge pill class="mt-3" variant="danger">This account has no users assigned to it.</b-badge>
            </template>
            <template v-else>
              <b-form-select v-model="selectedEmail" :options="optionsForCoinflowLinkGenerator" />

              <div v-if="selectedEmail" class="mt-3">
                <code>{{ coinflowLink }}</code>
                <div>
                  <b-button
                    variant="outline-secondary"
                    class="btn-sm mt-3"
                    @click="$_copyToClipboard(coinflowLink)"
                  >
                    Copy
                  </b-button>
                </div>
              </div>
            </template>
          </b-modal>
        </div>

        <b-form-group label="Maximum RPC tokens" label-for="account_maximum_tokens">
          <b-form-input
            :disabled="formDisabled"
            v-model="form.maximum_tokens"
            id="account_maximum_tokens"
          />
          <p class="fs--1">Use -1 to indicate infinite</p>
        </b-form-group>

        <b-form-group label-for="num_permitted_allowed_origins_per_endpoint">
          <label>Number of permitted allowed origins per endpoint</label>
            <span
              v-b-tooltip.hover
              class="badge"
              title="Maximum number of active allowed origins per endpoint. Defaults to 5."
            >
            <b-icon font-scale="1.2" icon="info-circle" />
          </span>

          <b-form-input
            id="num_permitted_allowed_origins_per_endpoint"
            v-model="form.num_permitted_allowed_origins_per_endpoint"
            :disabled="formDisabled"
          />
        </b-form-group>

        <b-form-checkbox :disabled="formDisabled" size="lg" v-model="form.address_watch_lists">
          Address watch lists feature
        </b-form-checkbox>

        <b-form-checkbox :disabled="formDisabled" size="lg" v-model="form.metrics_enabled">
          Metrics feature
        </b-form-checkbox>

        <b-form-checkbox :disabled="formDisabled" size="lg" v-model="form.allow_ip_ranges">
          Allow IP ranges
        </b-form-checkbox>

        <b-form-checkbox :disabled="formDisabled" size="lg" v-model="form.cascade_marketplace">
          Cascade marketplace
        </b-form-checkbox>

        <div class="my-3" v-if="!formDisabled">
          <b-button type="submit" variant="primary">Save</b-button>

          <b-button variant="secondary" @click="undoFormChanges">
            Cancel
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<style>
.btn-outline-secondary.disabled.active {
  color: #ffffff;
  background-color: #748194;
  border-color: #748194;
}
</style>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';
import copyToClipboard from '@/mixins/copy-to-clipboard';

export default {
  mixins: [copyToClipboard],

  data() {
    return {
      form: {
        payment_method: null,
        maximum_tokens: null,
        address_watch_lists: null,
        metrics_enabled: null,
        allow_ip_ranges: null,
        num_permitted_allowed_origins_per_endpoint: null,
        cascade_marketplace: null,
      },
      paymentMethodOptions: [
        { value: 'manual', text: 'Manual' },
        { value: 'helio', text: 'Hel.io' },
        { value: 'coinflow', text: 'Coinflow' },
      ],
      formDisabled: true,
      originalForm: {},
      isLoading: false,
      selectedEmail: null,
    }
  },

  props: {
    account: {
      type: Object,
      required: true
    },

    usersForAccount: {
      type: Array,
      required: false,
      default: () => null
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),

    showCoinflowLink() {
      return this.form.payment_method === 'coinflow' && this.usersForAccount;
    },

    optionsForCoinflowLinkGenerator() {
      return this.usersForAccount.map(user => {
        return {
          value: user.email,
          text: `${user.email} (${user.username})`
        };
      });
    },

    coinflowLink() {
      const accountUuid = this.account.uuid;

      let baseUrl = '';
      let planCode = '';
      if (window.location.hostname === 'customers.triton.one') {
        baseUrl = 'https://triton.coinflow.cash';
        planCode = 'production_starter_plan';
      } else {
        baseUrl = 'https://triton-staging.coinflow.cash';
        planCode = 'basic_plan';
      }

      return `${baseUrl}/subscribe?accountUuid=${accountUuid}&planCode=${planCode}&email=${this.selectedEmail}`;
    }
  },

  methods: {
    async onCreated() {
      await this.getFeatures();
      this.setOriginalForm();
    },

    async getFeatures() {
      this.form.payment_method = this.account.payment_method;
      this.form.maximum_tokens = this.account.maximum_tokens;
      this.form.address_watch_lists = this.account.address_watch_lists;
      this.form.metrics_enabled = this.account.metrics_enabled;
      this.form.allow_ip_ranges = this.account.allow_ip_ranges;
      this.form.cascade_marketplace = this.account.cascade_marketplace;
      this.form.num_permitted_allowed_origins_per_endpoint = this.account.num_permitted_allowed_origins_per_endpoint;
    },

    async updateFeatures() {
      try {
        const response = await http.put(`/accounts/${this.account.uuid}/features`, { account: this.form });
        this.$emit('metrics-enable-status', response.data.account.metrics_enabled);

        this.$bvToast.toast('Updated account', {
          title: 'Account updated',
          variant: 'default'
        });

        this.setOriginalForm();
        this.disableAccountForm();
      } catch (error) {
        this.$bvToast.toast('Could not update account', {
          title: 'Update failed',
          variant: 'danger'
        });
      }
    },

    undoFormChanges() {
      this.form = Object.assign({}, this.originalForm);

      this.disableAccountForm();
    },

    enableAccountForm() {
      this.formDisabled = false;
    },

    disableAccountForm() {
      this.formDisabled = true;
    },

    setOriginalForm() {
      this.originalForm = Object.assign({}, this.form);
    }
  }
}
</script>
