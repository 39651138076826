<template>
  <div class="container">
    <div class="row flex-center py-6">
      <div class="login-card-width">
        <div class="text-center">
          <triton-logo :height="100" />
        </div>
        <div class="card">
          <div class="card-body p-4">

            <div class="row flex-between-center mb-2">
              <div class="col-auto">
                <h5>Confirm your account</h5>
              </div>
            </div>

            <b-form v-on:submit.prevent="setUsernameAndPassword">
              <b-input :value="email" :disabled="true" class="mb-3" />

              <b-form-group>
                <b-input
                  v-model="username"
                  placeholder="Set your username"
                  autofocus
                  :state="usernameState"
                />
                <b-form-invalid-feedback :state="usernameState">
                  {{ usernameErrors }}
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="row">
                <b-form-group class="mb-3 col-12">
                  <!-- TODO: FIll width -->
                  <b-input
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="off"
                    :state="passwordState"
                  />
                  <b-form-invalid-feedback :state="passwordState">
                    {{ passwordErrors }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group class="mb-3 col-12">
                  <!-- TODO: FIll width -->
                  <b-input
                    v-model="passwordConfirmation"
                    :state="passwordConfirmationState"
                    placeholder="Confirm"
                    type="password"
                    autocomplete="off"
                  />
                  <b-form-invalid-feedback :state="passwordConfirmationState">
                    {{ passwordConfirmationErrors }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="my-3">
                <b-button variant="primary" :disabled="status === 'loading'" type="submit" class="d-block w-100 mt-3">
                  <span v-if="status === 'loading'">
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </span>
                  <span v-else>
                    Register
                  </span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'SetUsernameAndPassword',

  data() {
    return {
      username: null,
      password: null,
      passwordConfirmation: null,
      status: null,
      errors: {},
    }
  },

  computed: {
    confirmationToken() {
      return this.$route.query.confirmation_token;
    },

    email() {
      return this.$route.query.email;
    },

    usernameState() {
      if (this.errors['username']) { return false; }
    },

    usernameErrors() {
      const errors = this.errors['username'];
      if (errors) { return errors.join(', ') };
    },

    passwordState() {
      if (this.errors['password']) { return false; }
    },

    passwordErrors() {
      const errors = this.errors['password'];
      if (errors) { return errors.join(', ') };
    },

    passwordConfirmationState() {
      if (this.errors['password_confirmation']) { return false; }
    },

    passwordConfirmationErrors() {
      const errors = this.errors['password_confirmation'];
      if (errors) { return errors.join(', ') };
    },
  },

  methods: {
    async setUsernameAndPassword() {
      this.status = 'loading';

      try {
        await http.put(`users/confirmations/set_username_and_password`, {
          user: {
            confirmation_token: this.confirmationToken,
            username: this.username,
            password: this.password,
            password_confirmation: this.passwordConfirmation
          }
        });

        this.$root.$bvToast.toast('Your account has successfully been confirmed, and you can now sign in', {
          title: 'Success',
          variant: 'default'
        });

        this.$router.push({ name: 'signIn' });
      }

      catch (error) {
        console.log(error);

        this.errors = error.data.errors;

        this.$bvToast.toast(`There was an error confirming your account`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.status = null;
      }
    }
  }
}
</script>
