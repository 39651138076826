<template>
  <div class="v-chart mb-4">
    <v-chart
      v-if="!isLoading && hasData"
      :option="optionsForChart"
      autoresize
      :style="'height: ' + (height ? height : '425px') + ';'"
      ref="chart"
      @update:legendState="updateLegendState"
      @legendselectchanged="handleLegendSelectChanged"
    />
    <div v-else-if="isLoading">
      <div class="text-center mt-8 mb-8">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading chart {{ humanizeChartName(chartName) }}...</strong>
      </div>
    </div>
    <div v-else-if="error" class="mb-8">
      <div class="v-chart-heading">{{ humanizeChartName(chartName) }}</div>
      <div class="text-center py-2 text-danger">Error: {{ error.message }}</div>
    </div>
    <div v-else class="mb-8">
      <div class="v-chart-heading">{{ humanizeChartName(chartName) }}</div>
      <div class="text-center py-2">No data available</div>
    </div>
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';
import {metricsConfigs} from '@/constants/metrics';

// todo: import less than full library
// https://github.com/ecomfe/vue-echarts#example
// https://vue-echarts.dev/#codegen
import 'echarts';

export default {
  components: {
    'v-chart': VueECharts
  },

  props: ['chartName', 'options', 'quantile', 'height'],

  data() {
    return {
      chartData: null,
      optionsForChart: null,
      isLoading: false,
      legendState: {},
      error: null
    };
  },

  created() {
    this.onCreated();
  },

  watch: {
    metricsShouldBeRefreshed() {
      this.fetchMetricsData();
    }
  },

  computed: {
    metricsParams() {
      return {
        accountUuid: this.accountUuid,
        startDate: this.startDate,
        endDate: this.endDate,
        subscriptionTypeUuid: this.subscriptionTypeUuid,
        subscriptionUuid: this.subscriptionUuid,
        endpointUuid: this.endpointUuid,
        tokenUuid: this.tokenUuid,
        quantile: this.quantile
      };
    },

    metricsShouldBeRefreshed() {
      return [
        this.startDate,
        this.endDate,
        this.endpointUuid,
        this.tokenUuid,
        this.subscriptionUuid,
        this.subscriptionTypeUuid,
      ]
    },

    // this isn't always going to be passed
    subscriptionTypeUuid() {
      return this.options.subscriptionTypeUuid;
    },

    accountUuid() {
      return this.options.accountUuid;
    },

    // needs a look
    subscriptionUuid() {
      return this.options.subscriptionUuid;
    },

    endpointUuid() {
      return this.options.endpointUuid;
    },

    tokenUuid() {
      return this.options.tokenUuid;
    },

    startDate() {
      return this.options.startDate;
    },

    endDate() {
      return this.options.endDate;
    },

    chartFetchMethodName() {
      return metricsConfigs[this.chartName][0];
    },

    chartOptionsName() {
      return metricsConfigs[this.chartName][1];
    },

    optionsOverrides() {
      return metricsConfigs[this.chartName][2];
    },

    hasData() {
      if (!this.chartData) return false;
      // some results come back with the chartData as an array, while others come back as k/v object
      if (this.chartData.length === 0) return false;

      // check for a simple array of values (like [1,2,3] or [[1,2],[1,2,3]])
      if (Array.isArray(this.chartData[0]) && this.chartData[0].length > 0) {
        return true;
      }

      // Check for the matrix heatmap charts
      // the first values being empty will invalidate, but we have to do a not elegant check
      // [{"metric":{"pf_submitted":"pf_submitted"},"values":[{"metric":{},"values":[]}]}]
      const values = this.chartData[0].values;
      if (values && values[0] && values[0].values && Array.isArray(values[0].values)) {
        if (values[0].values.length === 0) {
          return false;
        }
      }

      // Now check for nested objects that have 'values' and length
      return this.chartData.some(item =>
        item.values && Array.isArray(item.values) && item.values.length > 0
      );
    }
  },

  methods: {
    async onCreated() {
      await this.fetchMetricsData();
    },

    updateLegendState() {
      if (this.optionsForChart && this.optionsForChart.series) {
        let newLegendState = {};
        newLegendState = this.optionsForChart.series.reduce((obj, item) => ({
          ...obj,
          [item.name]: this.legendState[item.name] !== undefined ? this.legendState[item.name] : true
        }), {});
        this.legendState = newLegendState;
        this.$emit('update:legendState', newLegendState);
      }
    },

    async fetchMetricsData() {
      try {
        this.isLoading = true;

        const module = await import('@/services/metrics');
        const fetchMethod = module[this.chartFetchMethodName];
        const chartOptions = module[this.chartOptionsName];

        this.chartData = await fetchMethod(this.metricsParams);
        this.optionsForChart = chartOptions(this.chartData, this.optionsOverrides, this.quantile);

        // Apply legend state
        this.applyLegendState();

        // init the legend state
        if (this.optionsForChart.hasLegendClick) {
          this.initializeLegendState();
        }
        // check if the chart needs a font resize
        if (this.optionsForChart.hasTextResize) {
          setTimeout(() => {
            this.updateChartFontSize();
          }, 500);
        }
      } catch (error) {
        if (error.message) {
          this.error = error;
        } else {
          this.error = {message: "There was an error fetching this chart's data."};
        }
      } finally {
        this.isLoading = false;
      }
    },

    initializeLegendState() {
      if (this.optionsForChart && this.optionsForChart.series) {
        this.legendState = this.optionsForChart.series.reduce((obj, item) => ({
          ...obj,
          [item.name]: this.legendState[item.name] !== undefined ? this.legendState[item.name] : true
        }), {});
        this.updateChartLegend();
      }
    },

    handleLegendSelectChanged(params) {
      if (this.optionsForChart.hasLegendClick) {
        const {name} = params;
        const legendData = this.optionsForChart.legend.data;

        // Check if the clicked item is currently the only one selected
        const isOnlyOneSelected = Object.values(this.legendState).filter(Boolean).length === 1;
        const isClickedItemSelected = this.legendState[name];

        if (isClickedItemSelected && isOnlyOneSelected) {
          // If clicking the only selected item, turn all on
          legendData.forEach(item => {
            this.legendState[item] = true;
          });
        } else {
          // Otherwise, set only the clicked item to true
          legendData.forEach(item => {
            this.legendState[item] = (item === name);
          });
        }

        this.updateChartLegend();
      }
    },

    updateChartLegend() {
      if (this.$refs.chart) {
        this.$refs.chart.setOption({
          legend: {selected: this.legendState}
        });
      }
    },

    updateChartFontSize() {
      const widthFactor = 4;
      if (this.$refs.chart) {
        const chart = this.$refs.chart;
        const width = chart.getWidth();
        const newFontSize = Math.round(width / widthFactor);
        chart.setOption({
          graphic: this.optionsForChart.graphic.map(group => ({
            ...group,
            children: group.children.map(child => {
              if (child.type === 'text' && child.resizable) {
                return {
                  ...child,
                  style: {
                    ...child.style,
                    fontSize: newFontSize
                  }
                };
              }
              return child;
            })
          }))
        });
      }
    },

    humanizeChartName(kebabCase) {
      return kebabCase
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    applyLegendState() {
      if (this.optionsForChart && this.optionsForChart.legend) {
        this.optionsForChart.legend.selected = this.legendState;
      }
    },

  }
};
</script>

<style scoped>
.v-chart .tooltip-circle {
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.v-chart-heading {
  font-weight: bold !important;
  font: 17px sans-serif;
  color: #464646;
}
</style>
