<template>
  <b-modal centered id="create-new-user" class="p-3" title="Create new user" :hide-footer="true">
    <template #modal-title>
      <h4>Create new user</h4>
    </template>
    <div class="row flex-between-center pl-3 pr-4">
      <div class="alert alert-primary" role="alert">
        <i class="fas fa-info-circle pe-2"></i>
        An invite email will <b>not</b> automatically be sent. You'll be able to send that in a separate step, which they'll be able to set their username and password through.
      </div>
    </div>

    <b-form v-on:submit.prevent="createUser" class="mt-3">
      <b-form-group>
        <b-form-input
          placeholder="Email address"
          v-model="userForm.email"
          :state="userFormEmailState"
          autofocus
          required
        >
        </b-form-input>
        <b-form-invalid-feedback :state="userFormEmailState">
          {{ userFormEmailErrors }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Role" label-class="h4">
        <b-form-radio-group
          v-model="userForm.role"
          :options="roleOptions"
          button-variant="outline-secondary"
          buttons
        ></b-form-radio-group>
      </b-form-group>

      <role-definitions-list :roles="roleOptions" />

      <div class="modal-footer">
        <b-button type="submit" variant="primary">Save</b-button>
        <b-button variant="secondary" @click="closeModal">
          Cancel
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import http from '@/services/http';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'CreateNewUserModal',

  data() {
    return {
      DEFAULT_ROLE: 'standard',
      userForm: {
        email: null,
        role: null,
        errors: {},
      },
    }
  },

  computed: {
    ...mapState({
      roles: state => state.roles.roles
    }),

    userFormEmailState() {
      if (this.userForm.errors['email']) { return false; }
    },

    userFormEmailErrors() {
      const errors = this.userForm.errors['email'];
      if (errors) { return errors.join(', ') };
    },

    roleOptions() {
      return this.roles.map(role => ({ value: role.name, text: _.startCase(role.name) }));
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
     this.loadRoles();
    },

    async loadRoles() {
      await this.$store.dispatch('roles/fetchRoles');

      this.role = this.DEFAULT_ROLE;
    },

    closeModal() {
      this.$bvModal.hide('create-new-user');

      this.clearForm();
    },

    async createUser() {
      try {
        const response = await http.post('users', {
          user: { email: this.userForm.email, role: this.userForm.role }
        });

        this.$emit('user-created', response.data.user);

        this.closeModal();

        this.$root.$bvToast.toast('Successfully created new user', {
          title: 'Created',
          variant: 'default'
        });
      }

      catch (error) {
        console.log(error);
        this.userForm.errors = error.data.errors;
      }
    },

    clearForm() {
      this.userForm.errors = {};
      this.userForm.email = null;
      this.userForm.role = this.DEFAULT_ROLE;
    }
  }
}
</script>
