<template>
  <div class="p-3">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h1>Reporting</h1>
        <div class="d-flex align-items-center justify-content-center">
          <b-form inline v-on:submit.prevent="fetchSummaries">
            <div class="form-group">
              <label for="start_date">Start Date</label>
              <b-form-input v-model="startDate" type="date" />
            </div>
            <div class="form-group">
              <label for="end_date">End Date</label>
              <b-form-input v-model="endDate" type="date" />
            </div>
            <div class="form-group">
              <input type="submit" value="Submit" class="btn btn-primary">
            </div>
          </b-form>
          <div class="table-button" @click="promptCsvDownload" v-b-tooltip.hover.bottom title="Download Report">
            <b-icon icon="file-earmark-text-fill" class="main-icon"/>
          </div>
        </div>
      </div>

      <div class="card-body">
        <b-tabs content-class="mt-3" @activate-tab="onTabChanged">
          <b-tab title="Accounts" :active="activeTab === 'accounts'">
            <accounts-tab :start-date="startDate" :end-date="endDate" :last-submitted="lastSubmitted" />
          </b-tab>
          <b-tab title="Subscriptions" :active="activeTab === 'subscriptions'">
            <subscriptions-tab :start-date="startDate" :end-date="endDate" :last-submitted="lastSubmitted" />
          </b-tab>
          <b-tab title="Users" :active="activeTab === 'users'">
            <users-tab :start-date="startDate" :end-date="endDate" :last-submitted="lastSubmitted" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import accountsTab from './accounts';
import subscriptionsTab from './subscriptions';
import usersTab from './users';
import http from '@/services/http';
import dayjs from 'dayjs';

export default {
  name: 'AdminReporting',

  components: {
    accountsTab,
    subscriptionsTab,
    usersTab
  },

  data() {
    return {
      startDate: moment().subtract(90, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      lastSubmitted: Date.now(),
      tabs: ['accounts', 'subscriptions', 'users']
    }
  },

  computed: {
    activeTab() {
      if (this.$route.query.subscriptions) {
        return 'subscriptions';
      } else if (this.$route.query.users) {
        return 'users';
      } else {
        return 'accounts';
      }
    },
  },

  methods: {
    async promptCsvDownload() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to download a CSV report of all active accounts?', {
          okVariant: 'primary',
          okTitle: 'Yes',
        })
        .then(async (value) => {
          if (value === true) {
            await this.downloadCsv();
          }
        });
    },

    async downloadCsv() {
      try {
        const response = await http.get('/accounts.csv?is_active=true');

        this.createAndDownloadCSV(response.data);
      } catch (error) {
        console.log(error);
        this.$bvToast.toast('There was an error downloading the CSV. Please try again.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 500,
        });
      }
    },

    createAndDownloadCSV(csv) {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      const currentDate = dayjs().format('YYYY-MM-DD');
      const downloadName = `active-accounts-${currentDate}.csv`;

      downloadLink.href = url;
      downloadLink.download = downloadName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      return downloadLink;
    },

    onTabChanged(newTabIndex) {
      if (this.tabs[newTabIndex] === 'accounts') {
        this.$router.push({ query: { accounts: true } });
      } if (this.tabs[newTabIndex] === 'subscriptions') {
        this.$router.push({ query: { subscriptions: true } });
      } else if (this.tabs[newTabIndex] === 'users') {
        this.$router.push({ query: { users: true } });
      }
    },

    fetchSummaries() {
      // Update the lastSubmitted timestamp to trigger watchers in child components
      this.lastSubmitted = Date.now();
    }
  }
}
</script>

<style scoped>
.table-button {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 40px;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  padding: 12px;
  text-decoration: none;
  background: #eef0f3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, transform 0.2s ease-in-out, border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.table-button:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.07);
}

.table-button:active {
  text-decoration: none;
  transform: scale(0.98);
  background: #e0e7f0;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.06), 0 0px 0px rgba(0, 0, 0, 0.08);
}
</style>

