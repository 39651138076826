<template>
  <div>
    <b-form v-on:submit.prevent="searchTokens">
      <div class="form-group">
        <b-input v-model="searchTerm" placeholder="Name starts with..." type="search" />
      </div>
    </b-form>

    <div v-if="isLoading">
      <div class="text-center mb-3">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </div>
    <div v-else>
      <div class="table-responsive scrollbar">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Auth username</th>
              <th scope="col">Subscription</th>
              <th scope="col" class="text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="token in tokens" :key="token.uuid">
              <!-- Name -->
              <td>
                <router-link :to="{ name: 'editSubscription', params: { uuid: token.subscription.uuid } }">
                  <span>{{ token.name }}</span>
                </router-link>
              </td>
              <!-- Auth username -->
              <td>
                <span>{{ token.auth_username }}</span>
              </td>
              <!-- Subscription -->
              <td>
                <router-link :to="{ name: 'editSubscription', params: { uuid: token.subscription.uuid } }">
                  {{ token.subscription.name }}
                </router-link>
              </td>
              <!-- Status -->
              <td class="text-right">
                <span v-if="token.is_active" class="text-success">Enabled</span>
                <span v-else class="text-danger">Disabled</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="meta?.total_pages > 1">
      <b-pagination
        v-model="currentPage"
        :total-rows="meta?.total_count"
        :per-page="perPage"
        @change="changePage"
      />
    </div>

    <p class="fs--1">{{ meta?.total_count }} Tokens</p>
  </div>
</template>

<script>
import http from '@/services/http';
import debounce from 'lodash.debounce';

export default {
  name: 'RateTierTokensTable',

  data() {
    return {
      isLoading: true,
      perPage: 50,
      currentPage: 1,
      tokens: [],
      searchTerm: null,
      meta: null
    }
  },

  props: ['rateTierUuid'],

  watch: {
    searchTerm: 'debouncedSearchEndpoints',
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      await this.fetchTokens();
    },

    debouncedSearchTokens: debounce(function () {
      this.searchTokens();
    }, 500),

    async changePage(newPage) {
      this.currentPage = newPage;
      await this.fetchTokens();
    },

    async searchTokens() {
      this.currentPage = 1;
      await this.fetchTokens();
    },

    async fetchTokens() {
      try {
        this.isLoading = true;

        const tokensParams = {
          page: this.currentPage,
          per: this.perPage,
          rate_tier_uuid: this.rateTierUuid
        }

        if (this.searchTerm) { tokensParams.name = this.searchTokensTerm; }

        const response = await http.get(`tokens`, { params: tokensParams });
        this.tokens = response.data.tokens;
        this.meta = response.data.meta;
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast('An error occurred while fetching tokens', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
      }

      finally {
        this.isLoading = false;
      }
    },
  }
}
</script>
