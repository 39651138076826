<template>
  <div class="table-responsive scrollbar">
    <table class="table table-sm table-hover overflow-hidden">
      <thead>
        <tr class="btn-reveal-trigger">
          <th scope="col">Hostname</th>
          <th>Environment</th>
          <th>Copy token</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="loadBalancer in loadBalancers" :key="loadBalancer.key" class="btn-reveal-trigger">
          <td>
            <p>{{ loadBalancer.hostname }}</p>
          </td>
          <td>
            <p>{{ loadBalancer.subscription_type?.name }}</p>
          </td>
          <td>
            <b-button
              v-if="loadBalancer.token"
              variant="outline-info"
              class="btn-link btn-sm"
              @click="$_copyToClipboard(loadBalancer.token.value)"
            >
              <b-icon-clipboard-check></b-icon-clipboard-check>
            </b-button>
          </td>
          <td class="text-right">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <b-icon-three-dots></b-icon-three-dots>
              </template>
              <b-dropdown-item @click="deleteLoadBalancer(loadBalancer)">
                <span class="text-danger">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="loadBalancer.token" @click="viewToken(loadBalancer)">
                <span class="text-default">View Token</span>
              </b-dropdown-item>
            </b-dropdown>

            <b-modal
              v-if="loadBalancer.token"
              :id="'modal-' + loadBalancer.uuid"
              centered
              class="p-3"
              header-bg-variant="light"
              header-class="py-3"
            >
              <template #modal-title>
                <h4>Token for "{{ loadBalancer.hostname }}" load balancer:</h4>
              </template>

              <p>{{ loadBalancer.token.value }}</p>

              <template #modal-footer>
                <b-button variant="secondary" @click="closeTokenModal(loadBalancer)">
                  Close
                </b-button>
              </template>
            </b-modal>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.modal-dialog h4, .modal-dialog p {
  margin: 0;
}

table p {
  margin: 5px 0 0 0;
}
</style>

<script>
import { mapState } from 'vuex';
import copyToClipboard from '@/mixins/copy-to-clipboard';

export default {
  name: 'LoadBalancersTable',

  mixins: [copyToClipboard],

  computed: {
    ...mapState({
      loadBalancers: state => state.loadBalancers.loadBalancers
    }),
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      if (this.loadBalancers.length === 0) {
        await this.$store.dispatch('loadBalancers/getLoadBalancers');
      }
    },

    async deleteLoadBalancer(loadBalancer) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to delete this load balancer?", {
        okVariant: 'danger',
        okTitle: 'Yes',
      })
      .then(async value => {
        if (value === true) {
          const response = await this.$store.dispatch(
            'loadBalancers/deleteLoadBalancer',
            loadBalancer
          );

          if (response.status === 204) {
            this.$root.$bvToast.toast('You successfully deleted the load balancer.', {
              title: 'Load balancer deleted',
              variant: 'default'
            });
          } else {
            this.$bvToast.toast(response.statusText, {
              title: 'Unable to delete load balancer',
              variant: 'danger'
            });
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    viewToken(loadBalancer) {
      this.$bvModal.show('modal-' + loadBalancer.uuid);
    },

    closeTokenModal(loadBalancer) {
      this.$bvModal.hide('modal-' + loadBalancer.uuid);
    },
  }
}
</script>
