const autoFocus = {
  inserted(element) {
    const nestedInput = element.querySelector('input, select, button, a');

    if (nestedInput) {
      nestedInput.focus();
    }

    else {
      element.focus();
    }
  }
};



export default autoFocus;
