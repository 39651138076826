import http from '@/services/http';

export default {
  state: {
    accounts: []
  },

  mutations: {
    // SET_ACCOUNTS
  },

  actions: {
    async getMe({ commit }) {
      try {
        const response = await http.get('users/me');


      }

      catch (error) {
        console.log(error);
      }
    },
  }
}
