function filterObjectKeys(object, keys, isBlacklisted) {
  const _object = JSON.parse(JSON.stringify(object));
  Object.keys(_object).forEach((key) => {
    const invalidKey = isBlacklisted ? keys.includes(key) : !keys.includes(key);

    if (invalidKey) {
      delete _object[key];
    }
  });
  return _object;
}

export default filterObjectKeys;
