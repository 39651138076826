<template>
  <div>
    <b-form v-on:submit.prevent="onboardAccount" class="mt-3">
      <div v-if="!hackathon" :class="selectedBlockchainsState ? '' : 'checkbox-group-error'">
        <div class="text-container">
          <h5 class="headline mb-2">Select your network!</h5>
          <p>What blockchain networks do you want your RPC to support.</p>
        </div>

        <div class="row my-4">
          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="pythnet"
                v-model="selectedBlockchains.pythnet"
              />
              <label class="form-check-label" for="pythnet">
                <img src="@/assets/pythnet.svg" alt="Pythnet" class="pythnet" />
              </label>
              <div class="checkbox-label">Pythnet</div>
            </div>
          </div>

          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="solana"
                v-model="selectedBlockchains.solana"
              />
              <label class="form-check-label" for="solana">
                <img src="@/assets/solana.svg" alt="Solana" />
              </label>
              <div class="checkbox-label">Solana</div>
            </div>
          </div>

          <div class="col">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="sui"
                v-model="selectedBlockchains.sui"
              />
              <label class="form-check-label" for="sui">
                <img src="@/assets/sui.png" alt="Sui" />
              </label>
              <div class="checkbox-label">Sui</div>
            </div>
          </div>
        </div>

        <b-form-invalid-feedback :state="selectedBlockchainsState">
          {{ selectedBlockchainsErrors }}
        </b-form-invalid-feedback>
      </div>

      <div class="mb-3">
        <!-- <hr v-if="!hackathon" /> -->
        <div v-if="!hackathon" class="d-flex justify-content-center mb-3">
          <div class="w-100 d-flex justify-content-between">
            <hr class="divider-right align-self-center" />
            <!-- <span class="section-text">Already have an account?</span> -->
            <hr class="divider-left align-self-center" />
          </div>
        </div>

        <b-form-group>
          <div d-flex>
            <label for="production">Production Origin</label>
            <span class="pill">Optional</span>
          </div>
          <b-form-input
            aria-autocomplete="false"
            placeholder="example.com"
            v-model="allowedOrigins[0]"
            :state="prodOriginState"
          />
          <b-form-invalid-feedback :state="prodOriginState">
            {{ prodOriginErrors }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <div d-flex>
            <label for="staging">Staging Origin</label>
            <span class="pill">Optional</span>
          </div>
          <b-form-input
            aria-autocomplete="false"
            placeholder="example.com"
            v-model="allowedOrigins[1]"
            :state="stagingOriginState"
          />
          <b-form-invalid-feedback :state="stagingOriginState">
            {{ stagingOriginErrors }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-button class="primary-button d-block w-100" :disabled="isLoading" type="submit">
          <span v-if="isLoading">
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </span>
          <span v-else> Continue </span>
        </b-button>
      </div>

      <transition name="shake" mode="out-in">
        <b-alert show class="alert-two">
          <p>
            🔍 Where are you calling the RPC from? If calling the RPC from a
            backend server then don't worry about endpoints.
          </p>
        </b-alert>
      </transition>
    </b-form>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapActions } from 'vuex';

export default {
  name: 'OnboardStepThree',

  props: ['hackathon', 'account'],

  created() {
    if (this.hackathon) {
      this.selectedBlockchains = { solana: true };
    } else {
      this.selectedBlockchains = { solana: false, sui: false, pythnet: false };
    }
  },

  data() {
    return {
      selectedBlockchains: {},
      allowedOrigins: ['', ''],
      isLoading: false,
      errors: {},
    };
  },

  computed: {
    selectedBlockchainsState() {
      if (this.errors.selected_blockchains) {
        return false;
      } else {
        return true;
      }
    },

    selectedBlockchainsErrors() {
      if (this.errors.selected_blockchains) {
        return this.errors.selected_blockchains.join(',');
      }
    },

    originErrors() {
      return this.errors.allowed_origins || [];
    },

    prodOriginState() {
      if (this.originErrors[0] && this.originErrors[0].value) {
        return false;
      }
    },

    prodOriginErrors() {
      return this.originErrors[0]?.value?.join(',');
    },

    stagingOriginState() {
      if (this.originErrors[1] && this.originErrors[1].value) {
        return false;
      }
    },

    stagingOriginErrors() {
      return this.originErrors[1]?.value?.join(',');
    },
  },

  methods: {
    ...mapActions('sessions', ['togglePaymentModal']),

    async onboardAccount() {
      this.errors = {};
      this.isLoading = true;

      const originsParams = this.allowedOrigins.map((origin) => {
        return { value: origin };
      });

      try {
        await http.put('accounts/onboard', {
          onboard: {
            hackathon: this.hackathon,
            account: { uuid: this.account.uuid },
            selected_blockchains: this.selectedBlockchains,
            allowed_origins: originsParams,
          },
        });

        this.togglePaymentModal();
        this.$router.push('/users/me');
      } catch (error) {
        console.log(error);

        if (error.data.message) {
          this.$bvToast.toast(error.data.message, {
            title: 'Error',
            variant: 'danger',
          });
        } else {
          this.$bvToast.toast(`There was an error submitting your request`, {
            title: 'Error',
            variant: 'danger',
          });
        }

        if (error.status === 422 && error.data.errors) {
          this.errors = error.data.errors;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
label[for='staging'],
label[for='production'] {
  opacity: 0.5;
}

.alert-two {
  padding: 15px;
  background-color: #dbdffc;
  color: rgb(86, 88, 112);
  border-radius: 18px;
  border: 2px dashed #b3b7db;
  margin-bottom: 15px;
}

.form-check {
  padding-left: 0;
}

::v-deep .form-control {
  background-color: #f6f7ff;
  border: 1px solid #dddee5;
  border-radius: 10px;
  height: 45px;
}

.form-check-input {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  border: 0;
}

.form-check-label {
  display: block;
  cursor: pointer;
  width: 75px;
  height: 75px;
  padding: 15px;
  overflow: hidden;
  background-color: #00000015;
  border-radius: 16px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  border: 3px solid white;
  box-shadow: inset 0 0 0 3px white;
}

.form-check-label img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.form-check-input:checked + .form-check-label {
  border-color: #353fb05c;
}

.checkbox-label {
  text-align: center;
  margin-top: 3px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  color: #00000076;
}

img.pythnet {
  text-align: center;
  padding-left: 4px;
}

.checkbox-group-error {
  border: 1px solid #dc3545;
  border-radius: 5px;
  padding: 10px;
}
</style>
