<template>
  <b-modal
    centered
    id='modal-create-new-account'
    class="p-3"
    header-bg-variant="light"
    header-class="py-3"
    size="lg"
    hide-footer
  >
    <template #modal-title>
      <h4>Create New Account</h4>
    </template>

    <account-form mode="create" :userUuid="userUuid"></account-form>
  </b-modal>
</template>

<style scoped>
.modal-dialog h4 {
  margin: 0;
}
</style>

<script>
export default {
  name: 'CreateNewAccountModal',

  props: {
    userUuid: {
      type: String,
      required: false
    }
  }
}
</script>
