<template>
  <div class="row">
    <div class="col-12">
      <h3 class='border-bottom pb-1 mt-3 mb-0'>Rate Tiers</h3>

      <table class="w-100 mb-3">
        <tbody>
          <tr v-for="rateTier in searchResults" class='border-bottom'>
            <td>
              <router-link :to="`/admins/rate_tiers/${rateTier.uuid}`">
                {{ rateTier.name }}
              </router-link>
            </td>
            <td>
              <span v-if="rateTier.is_restricted">
                Restricted
              </span>
              <span v-else>
                Unrestricted
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateTierSearchResults',

  props: {
    searchResults: [],
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/custom/searches.scss';
</style>
