<template>
  <div>
    <b-table
      v-if="addressWatchLists.length"
      striped
      hover
      small
      :fields="tableFields"
      :items="addressWatchLists"
    >
      <template #cell(encoded_value)="row">
        <b-link @click="showModal(row.item.uuid)">{{ abbreviatedValue(row.item.encoded_value) }}</b-link>
      </template>

      <template #cell(actions)="row">
        <b-button variant="outline-danger" size="sm" @click="deleteWatchList(row.item)">Delete</b-button>
      </template>
    </b-table>

    <span v-for="list in addressWatchLists" :key="list.uuid">
      <address-watch-list-modal :variantsDropdownOptions="variantsDropdownOptions" :addressWatchList="list" />
    </span>

    <b-form v-on:submit.prevent="create">
      <div class="row">
        <div class="col-lg-5 col-md-12">
          <b-form-group label="Variant">
            <b-form-select
              class="form-select"
              v-model="form.variant"
              :options="variantsDropdownOptions"
              required
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-lg-7 col-md-12">
          <b-form-group label="Encoded value">
            <b-form-input v-model="form.encoded_value" required>
            </b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-button type="submit" variant="primary" style="top: 10px;">
        Add address watch list
      </b-button>
    </b-form>
  </div>
</template>

<script>
import http from '@/services/http';
import dayjs from 'dayjs'

// components
import addressWatchListModal from './address-watch-list-modal';

export default {
  components: { addressWatchListModal },

  data() {
    return {
      addressWatchLists: [],
      form: {
        variant: null,
        encoded_value: null,
      },
      tableFields: [
        { key: 'variant' },
        { key: 'encoded_value' },
        { key: 'created_at', formatter: 'abbreviatedDate', sortable: true },
        { key: 'actions', label: '' }
      ]
    }
  },

  props: {
    subscription: {
      type: Object,
      required: true
    },
  },

  created() {
    this.onCreated();
  },

  computed: {
    variantsDropdownOptions() {
      return [
        { value: null, text: 'Select a variant' },
        { value: 'collection', text: 'Collection' },
        { value: 'tree', text: 'Tree' }
      ]
    },
  },

  methods: {
    async onCreated() {
      try {
        const response = await http.get(`/address_watch_lists?subscription_uuid=${this.subscription.uuid}`);
        this.addressWatchLists = response.data.address_watch_lists;
      } catch (err) {
        console.log(err);
      }
    },

    async create() {
      try {
        const response = await http.post(`/subscriptions/${this.subscription.uuid}/address_watch_lists`, {
          variant: this.form.variant,
          encoded_value: this.form.encoded_value
        });

        this.$bvToast.toast('Address watch list successfully created', {
          title: 'Created',
          variant: 'default'
        });

        const created = response.data.address_watch_list;

        this.addressWatchLists.unshift(created);
        this.form.variant = null;
        this.form.encoded_value = null;
      } catch (err) {
        this.$bvToast.toast('Could not create address watch list', {
          title: 'Create failed',
          variant: 'danger'
        });
        console.log(err);
      }
    },

    abbreviatedValue(value) {
      if (value.length <= 23) { return value; }

      const leftPart = value.slice(0, 10);
      const rightPart = value.slice(-10);

      return `${leftPart}...${rightPart}`;
    },

    abbreviatedDate(createdAt) {
      return dayjs(createdAt).format('MM/DD/YY');
    },

    async deleteWatchList(item) {
      try {
        await http.delete(`/address_watch_lists/${item.uuid}`);

        this.$bvToast.toast('Address watch list successfully deleted', {
          title: 'Deleted',
          variant: 'default'
        });

        this.addressWatchLists = this.addressWatchLists.filter(watchList => watchList.uuid !== item.uuid);
      } catch (err) {
        this.$bvToast.toast('Could not delete address watch list', {
          title: 'Delete failed',
          variant: 'danger'
        });
        console.log(err);
      }
    },

    showModal(uuid) {
      this.$bvModal.show(`address-watch-list-modal-${uuid}`)
    },
  }
}
</script>
