<template>
  <b-modal
    centered
    id="cancel-coinflow-confirmation-modal"
    header-bg-variant="light"
    header-class="py-3"
    size="md"
    title="Cancel Account"
    hide-footer
  >
    <p>Are you sure you want to cancel this account? It will be canceled immediately.</p>

    <div class="modal-footer">
      <b-button :disabled="isLoading" type="submit" variant="primary" @click="cancelWithCoinflow">
        <span v-if="isLoading">
          <b-spinner small></b-spinner>
        </span>
        <span class="emboss" v-else>Yes</span>
      </b-button>

      <b-button variant="secondary" @click="closeModal">
        No
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import http from '@/services/http';

export default {
  props: ['uuid'],

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async cancelWithCoinflow() {
      try {
        this.isLoading = true;

        await http.put(`accounts/${this.uuid}/coinflow/cancel_subscription`);

        this.$emit('coinflow-subscription-cancelled');

        this.$root.$bvToast.toast('Account has been cancelled', {
          title: 'Success',
          variant: 'default',
        });

        this.closeModal();
      } catch (error) {
        this.$bvToast.toast('An error occurred. Please try again or contact us', {
          title: 'Error',
          variant: 'danger',
        });
      } finally {
        this.isLoading = false;
      }
    },

    closeModal() {
      this.$bvModal.hide('cancel-coinflow-confirmation-modal');
    },
  }
}
</script>
