<template>
  <div class="row">
    <div class="col-12">
      <h3 class='border-bottom pb-1 mt-3 mb-0'>Accounts</h3>

      <ul class="list-unstyled">
        <div v-for="account in searchResults">
          <li class="p-3 border-bottom">
            <div class="row">
              <div class="col">
                <router-link :to="`/accounts/${account.uuid}`">
                  {{ account.name }}
                </router-link>
              </div>
              <div class="col">
                <div class="float-end text-right">
                  <span v-if="account.is_active" class="text-success">
                    Enabled
                  </span>
                  <span v-else-if="!account.is_active" class="text-danger">
                    Disabled
                  </span>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSearchResults',

  props: {
    searchResults: [],
  }
}
</script>
