<template>
  <div>
    <h2>Active accounts</h2>
    <p class="small">Click on a column for details</p>
    <div v-if="isLoading" class="chart-loading text-center my-4">
      <b-spinner class="align-middle mr-3"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <v-chart
        v-if="chartDataLoaded"
        @click="onBarClick"
        :option="chartOptions"
        autoresize
        style="height: 400px;"
      />
    </div>

    <div v-if="selectedDate">
      <h3>{{ selectedDate }}</h3>

      <table class="table table-sm">
        <tbody>
          <tr>
            <td>Name</td>
          </tr>
          <tr v-for="account in detailsForDate" :key="account.uuid">
            <td>
              <router-link :to="{ name: 'editAccount', params: { uuid: account.uuid } }">
                {{ account.name }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';
import http from '@/services/http';
import { sharedOptions } from '@/services/metrics';

export default {
  name: 'AccountsTab',
  components: { 'v-chart': VueECharts },

  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    lastSubmitted: { type: Number, required: true },
  },

  data() {
    return {
      chartDataLoaded: false,
      dailyAccountSummaries: [],
      selectedDate: null,
      isLoading: false,
      sharedOptions,
    }
  },

  watch: {
    lastSubmitted(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Re-fetch when the parent form is submitted
        this.fetchAccountSummaries();
      }
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    chartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: this.sharedOptions.tooltipOptions
        },
        dataset: { source: this.chartData, dimensions: ['date', 'num_active_accounts'] },
        xAxis: { type: 'category' },
        yAxis: { type: 'value' },
        grid: {
          top: 110,
          left: 75,
          right: 15,
          height: 160
        },
        textStyle: this.sharedOptions.textStyle,
        series: [
          {
            name: 'active accounts: ',
            type: 'bar',
            encode: { x: 'timestamp', y: 'value' },
            areaStyle: { color: '#00f', opacity: 0.5 },
            showSymbol: false,
          }
        ]
      };
    },

    chartData() {
      return this.dailyAccountSummaries.map((summary) => {
        return {
          date: summary.date,
          num_active_accounts: summary.active_accounts_count,
        }
      });
    },

    detailsForDate() {
      let summaryForDate = this.dailyAccountSummaries.find((summary) => {
        return summary.date === this.selectedDate;
      });

      // sort the accounts alphabetically
      summaryForDate.accounts.sort((a, b) => {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return summaryForDate.accounts;
    }
  },

  methods: {
    async onCreated() {
      await this.fetchAccountSummaries();
    },

    async fetchAccountSummaries() {
      try {
        this.isLoading = true;
        this.chartDataLoaded = false;

        const response = await http.get('/admin/daily_account_summaries', {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
          }
        });

        this.dailyAccountSummaries = response.data.daily_account_summaries;

        this.chartDataLoaded = true;
      }
      finally {
        this.isLoading = false;
      }
    },

    onBarClick(event) {
      this.selectedDate = event.data.date;
    }
  },
}
</script>

<style>
.chart-loading {
  height: 400px;
}
</style>
