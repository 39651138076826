<template>
  <b-modal
    v-if="uuid"
    centered
    v-on:close="cancel"
    class="p-3"
    :id="'deactivate-modal-' + uuid"
    header-bg-variant="light"
    header-class="py-3"
    size="md"
    hide-footer
  >
    <template #modal-title>
      <h4>Deactivate {{ name }}</h4>
    </template>

    <p>Are you sure you want to deactivate this {{ name }}?</p>

    <div v-if="!hideReason">
      <b-form v-on:submit.prevent="deactivate">
        <div class="mt-3">
          <b-form-group label="Deactivation reason (optional)">
            <b-form-textarea
              v-model="form.description"
              placeholder="Deactivation reason"
              :state="deactivationReasonState"
              autofocus
            />
            <b-form-invalid-feedback :state="deactivationReasonState">
              {{ deactivationReasonErrors }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="modal-footer">
          <b-button type="submit" variant="primary">Yes</b-button>
          <b-button variant="secondary" @click="cancel">
            No
          </b-button>
        </div>
      </b-form>
    </div>

    <div v-else>
      <div class="modal-footer">
        <b-button type="submit" variant="primary" @click="deactivate">Yes</b-button>
        <b-button variant="secondary" @click="cancel">
          No
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.modal-footer {
  margin: 0 -15px -15px -15px;
}
</style>

<script>
import http from '@/services/http';

export default {
  name: 'DeactivateModal',

  props: {
    uuid: {
      type: String,
      required: true
    },
    resourceName: {
      type: String,
      required: true
    },
    hideReason: {
      type: Boolean,
      default: false
    },
    isAdminPath: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        description: null,
        errors: {}
      },
      deactivated: false,
    };
  },

  computed: {
    deactivationReasonState() {
      if (this.form.errors['description']) { return false; }
    },

    deactivationReasonErrors() {
      const errors = this.form.errors['description'];
      if (errors) { return errors.join(', ') }
    },

    name() {
      return this.resourceName.replace("_", " ");
    },

    adminPath() {
      return this.isAdminPath ? 'admin/' : '';
    }
  },

  methods: {
    async deactivate() {
      try {
        const response = await this.sendDeactivationReason();

        // update the account via store
        // do we update sessions or accounts?

        const reason = response.data.deactivation_reason;

        if (reason) {
          this.$emit('deactivated', reason, this.uuid);
        } else {
          this.$emit('deactivated', this.uuid);
        }

        this.closeModal();

        this.$root.$bvToast.toast(`You successfully deactivated the ${this.name}`, {
          title: 'Success',
          variant: 'default'
        });
      }

      catch (error) {
        console.log('Error:', error.data.errors);
        this.form.errors = error.data.errors;
      }
    },

    async sendDeactivationReason() {
      return await http.put(`/${this.adminPath}${this.resourceName}s/${this.uuid}/deactivate`, {
        deactivation_reason: this.form.description
      });
    },

    closeModal() {
      this.$bvModal.hide(`deactivate-modal-${this.uuid}`);
      this.clearForm();
    },

    cancel() {
      this.$emit('canceled');
      this.closeModal();
    },

    clearForm() {
      this.form.description = null;
    }
  }
}
</script>
