<template>
    <b-form v-on:submit.prevent="updateUser">
      <div v-if="isLoading">
        <div class="text-center my-4">
          <b-spinner class="align-middle mr-3"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </div>
      <div v-else>
        <div v-if="isMe" class="mb-3">
          <span>You have the <b>{{ this.user.role.name }}</b> role</span>
          <div
            v-b-tooltip.hover
            class="badge rounded-pill"
            :title="getRoleDefinition(this.user.role.name)"
          >
            <b-icon font-scale="1.5" icon="info-circle"></b-icon>
          </div>
        </div>

        <b-form-group label="Username">
          <b-form-input
            v-model="form.username"
            :state="inputState('username')"
            :disabled="userFormDisabled"
          ></b-form-input>
          <b-form-invalid-feedback :state="inputState('username')">
            {{ inputError('username') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Email">
          <b-form-input
            v-model="form.email"
            :state="inputState('email')"
            :disabled="userFormDisabled"
          ></b-form-input>
          <b-form-invalid-feedback :state="inputState('email')">
            {{ inputError('email') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div v-if="isMe">
          <b-form-group label="New password">
            <span class="fs--2">Leave blank if you don't want to change it</span>
            <b-form-input
              v-model="form.password"
              type="password"
              :state="inputState('password')"
              :disabled="userFormDisabled"
            ></b-form-input>
            <b-form-invalid-feedback :state="inputState('password')">
              {{ inputError('password') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Password confirmation">
            <b-form-input
              v-model="form.passwordConfirmation"
              type="password"
              :state="inputState('password_confirmation')"
              :disabled="userFormDisabled"
            ></b-form-input>
            <b-form-invalid-feedback :state="inputState('password_confirmation')">
              {{ inputError('password_confirmation') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Current password">
          <span class="fs--2">
            To change your password, confirm your current password
          </span>
            <b-form-input
              v-model="form.currentPassword"
              type="password"
              :state="inputState('current_password')"
              :disabled="userFormDisabled"
            />
            <b-form-invalid-feedback :state="inputState('current_password')">
              {{ inputError('current_password') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group label="Role">
            <b-form-radio-group
              v-model="form.role"
              :options="roleOptions"
              :disabled="userFormDisabled"
              button-variant="outline-secondary"
              buttons
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <div class="my-3" v-if="isModal || !userFormDisabled">
          <b-button type="submit" variant="primary" :disabled="isSaveDisabled">Save</b-button>
          <b-button v-if="isModal" variant="secondary" @click="undoChangesAndCloseModal">
            Cancel
          </b-button>
          <b-button v-else variant="secondary" @click="undoChangesAndDisableForm">
            Cancel
          </b-button>
        </div>
      </div>
    </b-form>
</template>

<style>
.btn-outline-secondary.disabled.active {
  color: #ffffff;
  background-color: #748194;
  border-color: #748194;
}
</style>

<script>
import http from '@/services/http';
import roleDefinitions from '@/mixins/role-definitions.js';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'UserForm',

  mixins: [roleDefinitions],

  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false
    },

    userFormDisabled: {
      type: Boolean,
      required: false,
      default: true
    },

    user: {
      type: Object,
      required: false
    }
  },

  watch: {
    user: {
      handler: 'loadUser'
    }
  },

  data() {
    return {
      form: {
        username: null,
        email: null,
        role: null,
        currentPassword: null,
        password: null,
        passwordConfirmation: null
      },
      userUuid: null,
      originalForm: {},
      errors: {},
      isLoading: true
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', ['currentUser']),
    ...mapState({
      roles: state => state.roles.roles
    }),

    roleOptions() {
      return this.roles.map(role => ({ value: role.name, text: _.startCase(role.name) }));
    },

    userParams() {
      let baseParams = {
        username: this.form.username,
        email: this.form.email
      }

      if (this.isMe) {
        baseParams.current_password = this.form.currentPassword;

        if (this.form.password) {
          baseParams.password = this.form.password;
          baseParams.password_confirmation = this.form.passwordConfirmation;
        }
      } else {
        baseParams.role = this.form.role;
      }

      return { user: baseParams };
    },

    isSaveDisabled() {
      return JSON.stringify(this.originalForm) === JSON.stringify(this.form);
    },

    isMe() {
      return this.currentUser.uuid === this.user.uuid;
    },
  },

  methods: {
    async onCreated() {
      await this.loadRoles();
      await this.loadUser();
    },

    async loadRoles() {
      if (!this.isMe) {
        await this.$store.dispatch('roles/fetchRoles');
      }
    },

    async loadUser() {
      if (Object.keys(this.user).length) {
        this.form.username = this.user.username;
        this.form.email = this.user.email;

        if (!this.isMe) {
          this.form.role = this.user.role.name;
        }

        this.updateOriginalForm();

        this.isLoading = false;
      }
    },

    async updateUser() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to update user?`, {
        okTitle: 'Yes',
      })
      .then(async value => {
        if (value === true) {
          try {
            const resp = await http.put(`users/${this.user.uuid}`, this.userParams);

            this.updateSessionUser();
            this.updateOriginalForm();
            this.$emit('user-updated', resp.data.user);
            this.closeAndNotify();
          } catch (error) {
            console.log('Error:', error)
            this.errors = error.data.errors;
          }
        } else {
          this.undoChangesAndDisableForm();
        }
      })
    },

    updateSessionUser() {
      if (this.isMe) {
        this.$store.dispatch('' +
          'sessions/updateSessionUser',
          {
            username: this.form.username,
            email: this.form.email
          }
        );
      }
    },

    async signOut() {
      this.$router.push('/users/sign-in');
      await this.$store.dispatch('sessions/signOut');
    },

    closeAndNotify() {
      const isPasswordUpdated = !!this.form.password

      const message = isPasswordUpdated
        ? 'User has been updated successfully, and you will need to sign back in with the updated password'
        : 'User has been updated successfully'

      this.isModal
        ? this.clearPasswordAndCloseModal()
        : this.clearPasswordAndDisableForm();

      this.$root.$bvToast.toast(message, {
        title: 'User updated',
        variant: 'default'
      });

      if (isPasswordUpdated) { this.signOut(); }
    },

    undoChangesAndCloseModal() {
      this.undoFormChanges();
      this.closeModal();
    },

    clearPasswordAndCloseModal() {
      this.clearPassword();
      this.closeModal();
    },

    undoChangesAndDisableForm() {
      this.undoFormChanges();
      this.disableForm();
    },

    clearPasswordAndDisableForm() {
      this.clearPassword();
      this.disableForm();
    },

    closeModal() {
      this.errors = {}
      this.$bvModal.hide('manage-user-modal');
    },

    disableForm() {
      this.errors = {}
      this.$emit('disable-user-form');
    },

    clearPassword() {
      this.form.currentPassword = null;
      this.form.password =  null;
      this.form.passwordConfirmation = null;
    },

    undoFormChanges() {
      this.form = Object.assign({}, this.originalForm);
    },

    inputState(inputName) {
      if (this.errors[inputName]) { return false; }
    },

    inputError(inputName) {
      const errors = this.errors[inputName];
      if (errors) { return errors.join(', ') }
    },

    updateOriginalForm() {
      this.originalForm = Object.assign({}, this.form);
    }
  }
}
</script>
