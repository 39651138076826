<template>
  <div v-if="showHelioInfo" class="card mb-3">
    <div class="card-header">
      <div class="h2">Helio events</div>
      <p class="fs--1">Viewable only by admins</p>
    </div>

    <div class="card-body">
      <div class="table-responsive table-hover scrollbar">
        <table class="table table-sm">
          <thead class="border-bottom">
            <tr>
              <th>Event</th>
              <th>Timestamp</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="event in helioWebhookEvents" :key="event.uuid" class="align-middle">
              <td>
                <span v-if="event.event === 'STARTED'" class="text-success">
                  STARTED
                </span>
                <span v-else class="text-danger">
                  ENDED
                </span>
              </td>
              <td>{{ event.created_at.replace('T', ' ') }}</td>
              <td>
                <b-link v-b-modal="'event-modal-' + event.uuid">
                  View
                </b-link>
              </td>
              <b-modal
                centered
                size="lg"
                :id="'event-modal-' + event.uuid"
                class="p-3"
                header-bg-variant="light"
                header-class="py-3"
                title="Helio event details"
                :ok-only="true"
                ok-title="Close"
              >
                <pre class="text-monospace">{{ JSON.parse(event.helio_webhook_request.body) }}</pre>
              </b-modal>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="fs--1">{{ helioWebhookEvents.length }} Event(s)</p>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';
import { mapGetters } from 'vuex';

export default {
  name: 'HelioWebhookEvents',

  data() {
    return {
      helioWebhookEvents: [],
    };
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters(
      'sessions', ['currentUser'],
    ),

    uuid() {
      return this.$route.params.uuid;
    },

    showHelioInfo() {
      return this.helioWebhookEvents.length > 0;
    },
  },

  methods: {
    async onCreated() {
      await this.fetchHelioWebhookEvents();
    },

    async fetchHelioWebhookEvents() {
      try {
        const response = await http.get(`accounts/${this.uuid}/helio_webhook_events`);
        this.helioWebhookEvents = response.data.helio_webhook_events;
      }

      catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
