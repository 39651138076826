<template>
  <img src="@/assets/triton-logo.png" alt="triton logo" :height="height" />
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      required: true
    }
  }
}
</script>
