<template>
  <div id="new-onboard" class="container-lg">
    <div class="row">
      <div class="col-md-11 col-lg-9 col-xl-8 col-xxl-6">
        <h1>Onboard to shared pool</h1>

        <b-form v-on:submit.prevent="createAccount">
          <h3>Account</h3>
          <div class="row mt-3">
            <div class="col-12 form-group">
              <b-form-group label="Name">
                <b-form-input v-model="account.name" :state="nameState" autofocus />
                <b-form-invalid-feedback :state="nameState">
                  {{ nameErrors }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <h3>User</h3>
          <div class="row mt-3">
            <div class="col-12 form-group">
              <b-form-group label="Email address (optional)">
                <b-form-input v-model="user.email" :state="emailState" />
                <b-form-invalid-feedback :state="emailState">
                  {{ emailErrors }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="h3">Payment Method</div>
          <div class="pt-1 pb-4">
            <b-form-group>
              <b-form-radio-group
                v-model="account.paymentMethod"
                :options="paymentMethodOptions"
                button-variant="outline-primary"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </div>

          <div class="h3">Select blockchains</div>
          <b-form-group class="mb-4">
            <b-form-checkbox id="pythnet" v-model="selectedBlockchains.pythnet" size="lg" :state="selectedBlockchainsState">
              Pythnet
            </b-form-checkbox>
            <b-form-checkbox id="solana" v-model="selectedBlockchains.solana" size="lg" :state="selectedBlockchainsState">
              Solana
            </b-form-checkbox>
            <b-form-checkbox id="sui" v-model="selectedBlockchains.sui" size="lg" :state="selectedBlockchainsState">
              Sui
            </b-form-checkbox>
            <b-form-invalid-feedback :state="selectedBlockchainsState">
              {{ selectedBlockchainsErrors }}
            </b-form-invalid-feedback>
          </b-form-group>

          <h3 class="pt-3">Allowed Origins</h3>
          <p class="fs--1">
            If no origins are provided then endpoints will be created with
            <code>__blocked.rpcpool.com</code>
          </p>

          <div class="row">
            <div class="col-6">
              <b-form-group label="Production">
                <b-form-input v-model="allowedOrigins[0]" :state="prodOriginState" />
                <b-form-invalid-feedback :state="prodOriginState">
                  {{ prodOriginErrors }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-6">
              <b-form-group label="Staging">
                <b-form-input v-model="allowedOrigins[1]" :state="stagingOriginState" />
                <b-form-invalid-feedback :state="stagingOriginState">
                  {{ stagingOriginErrors }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-12 form-group">
              <b-button type="submit" class="btn-primary">
                Create
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'NewOnboardView',

  data() {
    return {
      account: { name: null, paymentMethod: 'manual' },
      user: { email: null },
      selectedBlockchains: { solana: false, sui: false, pythnet: false },
      allowedOrigins: ['', ''],
      paymentMethodOptions: [
        { value: 'manual', text: 'Manual' },
        { value: 'coinflow', text: 'Coinflow' },
        { value: 'helio', text: 'Hel.io' }
      ],
      errors: {}
    }
  },

  destroyed() {
    this.reset();
  },

  computed: {
    nameState() {
      if (this.errors.account?.name) { return false; }
    },

    nameErrors() {
      if (this.errors.account?.name) { return this.errors.account.name.join(','); }
    },

    emailState() {
      if (this.errors.user?.email) { return false; }
    },

    emailErrors() {
      if (this.errors.user?.email) { return this.errors.user.email.join(','); }
    },

    selectedBlockchainsState() {
      if (this.errors.selected_blockchains) { return false; }
    },

    selectedBlockchainsErrors() {
      if (this.errors.selected_blockchains) {
        return this.errors.selected_blockchains.join(',');
      }
    },

    originErrors() {
      return this.errors.allowed_origins || [];
    },

    prodOriginState() {
      if (this.originErrors[0] && this.originErrors[0].value) { return false; }
    },

    prodOriginErrors() {
      return this.originErrors[0]?.value?.join(',');
    },

    stagingOriginState() {
      if (this.originErrors[1] && this.originErrors[1].value) { return false; }
    },

    stagingOriginErrors() {
      return this.originErrors[1]?.value?.join(',');
    }
  },

  methods: {
    reset() {
      this.account = { name: null, paymentMethod: 'manual' };
      this.user = { email: null };
      this.selectedBlockchains = { solana: false, sui: false, pythnet: false };
      this.allowedOrigins = [];
      this.errors = {};
    },

    async createAccount() {
      this.errors = {};

      const originParams = this.allowedOrigins.map((origin) => { return { value: origin }; });

      const onboardParams = {
        account: {
          name: this.account.name,
          payment_method: this.account.paymentMethod
        },
        allowed_origins: originParams,
        selected_blockchains: this.selectedBlockchains,
      };

      if (this.user.email) {
        onboardParams.user = { email: this.user.email }
      }

      try {
        const response = await http.put('accounts/onboard', { onboard: onboardParams });

        if (response.data.user) {
          this.$router.push({
            name: 'manageUserRpc',
            params: { uuid: response.data.user.uuid }
          });
        } else {
          this.$router.push({
            name: 'editAccount',
            params: { uuid: response.data.account.uuid }
          });
        }
      } catch (error) {
        console.log(error);
        if (error.status === 422) {
          this.$bvToast.toast(error.data.message, {
            title: 'Error',
            variant: 'danger'
          });

          this.errors = error.data.errors;
        } else {
          this.$bvToast.toast('There was an error submitting your request. Please try again', {
            title: 'Error',
            variant: 'danger'
          });
        }
      }
    }
  },
}
</script>
