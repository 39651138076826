<template>
  <b-modal
    v-if="uuid"
    centered
    v-on:close="cancel"
    class="p-3"
    :id="'activate-modal-' + uuid"
    header-bg-variant="light"
    header-class="py-3"
    size="md"
    hide-footer
  >
    <template #modal-title>
      <h4>Activate {{ name }}</h4>
    </template>

    <p>Are you sure you want to activate this {{ name }}?</p>

    <div class="modal-footer">
      <b-button type="submit" variant="primary" @click="activate">Yes</b-button>
      <b-button variant="secondary" @click="cancel">
        No
      </b-button>
    </div>
  </b-modal>
</template>

<style scoped>
.modal-footer {
  margin: 0 -15px -15px -15px;
}
</style>

<script>
import http from '@/services/http';

export default {
  name: 'ActivateModal',

  props: {
    uuid: {
      type: String,
      required: false
    },
    resourceName: {
      type: String,
      required: true
    },
    isAdminPath: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    name() {
      return this.resourceName.replace("_", " ");
    },

    adminPath() {
      return this.isAdminPath ? 'admin/' : '';
    }
  },

  methods: {
    async activate() {
      try {
        await http.put(`/${this.adminPath}${this.resourceName}s/${this.uuid}/activate`);

        this.$emit('activated', this.uuid);

        this.closeModal();

        this.$bvToast.toast(`You successfully activated the ${this.name}`, {
          title: 'Success',
          variant: 'default'
        });
      }

      catch (error) {
        console.log('Error:', error);

        const errorSentence = Object.values(error.data.errors)
          .map(errors => errors.join(' '))
          .join(' ');

        this.$bvToast.toast(errorSentence, {
          title: error.data.message,
          variant: 'danger'
        });
      }
    },

    cancel() {
      this.$emit('canceled');
      this.closeModal();
    },

    closeModal() {
      this.$bvModal.hide(`activate-modal-${this.uuid}`);
    },
  }
}
</script>
