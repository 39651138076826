import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    accounts: [],
    accountOptions: [],
    currentAccount: null,
  },

  getters: {
    findCachedAccount(uuid) {
      if (this.accounts.length > 0) {
        return this.accounts.find(a => a.uuid === uuid);
      }
    },

    findCachedAccountOptions(state) {
      if (state.accountOptions.length > 0) {
        return state.accountOptions;
      }
    },
  },

  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },

    SET_ACCOUNT_OPTIONS(state, options) {
      state.accountOptions = options;
    },

    ADD_ACCOUNT(state, account) {
      state.accounts.push(account);
    },

    SET_CURRENT_ACCOUNT(state, account) {
      state.currentAccount = account;
    },

    CLEAR_CURRENT_ACCOUNT(state) {
      state.currentAccount = null;
    },
  },

  actions: {
    async fetchAccounts({ commit }, params) {
      try {
        const response = params
          ? await http.get('accounts', params)
          : await http.get('accounts');

        commit('SET_ACCOUNTS', response.data.accounts);
      }

      catch (error) {
        console.log(error);
      }
    },

    async fetchAccount({ commit }, uuid) {
      try {
        let cachedAccount = findCachedAccount(uuid);

        if (cachedAccount) {
          return cachedAccount;
        } else {
          const response = await http.get(`accounts/${uuid}`);
          commit('ADD_ACCOUNT', response.data.account);
          return response.data.account;
        }
      }

      catch (error) {
        console.log(error);
      }
    },

    async fetchAccountOptions({ commit, getters }) {
      try {
        let cachedAccountOptions = getters.findCachedAccountOptions;

        if (cachedAccountOptions) {
          return cachedAccountOptions;
        } else {
          const response = await http.get(`admin/accounts/options`);
          commit('SET_ACCOUNT_OPTIONS', response.data);
          return response.data;
        }
      }

      catch (error) {
        console.log(error);
      }
    },

    async merge({ commit, getters }, mergeParams) {
      try {
        const response = await http.put('admin/accounts/merge', {
          source_account_uuid: mergeParams.sourceAccountUuid,
          destination_account_uuid: mergeParams.destinationAccountUuid
        });

        let cachedAccountOptions = getters.findCachedAccountOptions;

        if (cachedAccountOptions) {
          const indexToDelete = cachedAccountOptions.findIndex(
            option => option.uuid === mergeParams.sourceAccountUuid
          );

          if (indexToDelete !== -1) {
            cachedAccountOptions.splice(indexToDelete, 1);
            commit('SET_ACCOUNT_OPTIONS', cachedAccountOptions);
          }
        } else {
          const options = await http.get(`admin/accounts/options`);
          commit('SET_ACCOUNT_OPTIONS', options.data);
        }

        return response;
      }

      catch (error) {
        console.log(error);
        return error;
      }
    },
  }
}
