<template>
  <div class="container">
    <div class="row flex-center py-6">
      <div class="login-card-width">
        <transition name="expand">
          <div class="card">
            <div class="logo-container">
              <router-link to="/users/sign-in" class="d-flex flex-center">
                <triton-logo :height="85"></triton-logo>
              </router-link>
              <p>
                <span class="logo-text"> Experience the fastest, most reliable, <br />full-service RPC. </span>
              </p>
            </div>
            <div class="card-body p-4">
              <sign-in-form
                :confirmUser="confirmUser"
                :resetPasswordMsg="resetPasswordMsg"
                :recoveryMsg="recoveryMsg"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import signInForm from '../globals/sign-in-form';

export default {
  name: 'SignIn',

  components: { signInForm },

  props: {
    confirmUser: { type: Boolean },
    resetPasswordMsg: { type: String },
    recoveryMsg: { type: String },
  },
};
</script>
