<template>
  <b-modal
    v-if="uuid"
    centered
    class="p-3"
    :id="'deactivation-reasons-modal-' + uuid"
    header-bg-variant="light"
    header-class="py-3"
    size="lg"
  >
    <template #modal-title>
      <h4>{{ title }} deactivation reasons</h4>
    </template>

    <b-list-group>
      <b-list-group-item
        v-for="(reason, index) in reasons"
        :key="index"
        class="flex-column align-items-start"
        :variant="isEven(index) ? 'secondary' : ''"
      >
        <div class="d-flex w-100 justify-content-between">
          <span v-if="reason.description">{{ reason.description }}</span>
          <span v-else>No description</span>
          <small class="text-right">Deactivated on {{ dayjs(reason.created_at).format('MM/DD/YY h:mm A') }}</small>
        </div>
      </b-list-group-item>
    </b-list-group>

    <template #modal-footer>
      <b-button variant="secondary" @click="closeModal">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<style scoped>
.list-group-item.list-group-item-secondary {
  background-color: #f9fafd !important;
}
</style>

<script>
import http from '@/services/http';
import dayjs from 'dayjs'

export default {
  name: 'DeactivateModal',

  props: {
    deactivationReasons: {
      type: Array,
      required: false
    },
    uuid: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      reasons: [],
      dayjs: dayjs
    };
  },

  watch: {
    deactivationReasons: {
      handler: 'setDeactivationReasons'
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
      if (this.deactivationReasons) {
        this.reasons = this.deactivationReasons;
      }
    },

    closeModal() {
      this.$bvModal.hide(`deactivation-reasons-modal-${this.uuid}`);
    },

    isEven(num) {
      return num % 2 === 0;
    },

    setDeactivationReasons(deactivationReasons) {
      this.reasons = deactivationReasons;
    }
  }
}
</script>
