import { render, staticRenderFns } from "./deactivation-reasons-modal.vue?vue&type=template&id=78e36879&scoped=true"
import script from "./deactivation-reasons-modal.vue?vue&type=script&lang=js"
export * from "./deactivation-reasons-modal.vue?vue&type=script&lang=js"
import style0 from "./deactivation-reasons-modal.vue?vue&type=style&index=0&id=78e36879&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78e36879",
  null
  
)

export default component.exports