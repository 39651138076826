import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    metrics: {
      numberOfRequests: null,
      numberOfRequestsByRpc: null,
      numberOfRequestsByStatus: null,
      trafficInBytesByRpc: null,
      trafficInBytesOut: null,
    }
  },

  getters: {
    numberOfRequests(state) {
      return state.metrics.numberOfRequests;
    },

    numberOfRequestsByRpc(state) {
      return state.metrics.numberOfRequestsByRpc;
    },

    numberOfRequestsByStatus(state) {
      return state.metrics.numberOfRequestsByStatus;
    },

    trafficInBytesByRpc(state) {
      return state.metrics.trafficInBytesByRpc;
    },

    trafficInBytesOut(state) {
      return state.metrics.trafficInBytesOut;
    },
  },

  mutations: {
    SET_NUMBER_OF_REQUESTS(state, numberOfRequests) {
      state.metrics.numberOfRequests = numberOfRequests;
    },

    SET_NUMBER_OF_REQUESTS_BY_RPC(state, numberOfRequestsByRpc) {
      state.metrics.numberOfRequestsByRpc = numberOfRequestsByRpc;
    },

    SET_NUMBER_OF_REQUESTS_BY_STATUS(state, numberOfRequestsByStatus) {
      state.metrics.numberOfRequestsByStatus = numberOfRequestsByStatus;
    },

    SET_TRAFFIC_IN_BYTES_BY_RPC(state, trafficInBytesByRpc) {
      state.metrics.trafficInBytesByRpc = trafficInBytesByRpc;
    },

    SET_TRAFFIC_IN_BYTES_OUT(state, trafficInBytesOut) {
      state.metrics.trafficInBytesOut = trafficInBytesOut;
    },
  },

  actions: {
    async fetchMetrics({ commit }, params) {
      try {
        let metricsParams = params.metricsParams

        if (params.selectedResourceUuid) {
          metricsParams[`${params.selectedResourceName}_uuid`] = params.selectedResourceUuid;
        }

        const response = await http.get(`accounts/${params.accountUuid}/metrics/${params.requestName}`, {
          params: metricsParams
        });

        commit(`SET_${params.requestName.toUpperCase()}`, response.data.data);
        return response.data.data;
      }

      catch (error) {
        console.log(error);
      }
    }
  }
}
