<template>
  <div class="p-3 container-xl">
    <div class="card">
      <div class="card-header">
        <h2>Helio webhooks</h2>
      </div>

      <div class="card-body">
        <b-form @submit.prevent="submitForm" class="mb-4">
          <b-input-group prepend="Filter by">
            <b-form-select v-model="selected" :options="options" class="mr-2" />
            <b-form-input v-model="searchTerm" placeholder="Search" type="search" />
          </b-input-group>
        </b-form>

        <div class="table-responsive table-hover scrollbar">
          <table class="table table-sm">
            <thead class="border-bottom">
              <tr>
                <th>Event</th>
                <th>Account</th>
                <th>Account UUID</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading" role="row">
                <td colspan="3">
                  <div class="text-center my-4">
                    <b-spinner class="align-middle mr-3"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </td>
              </tr>

              <tr
                v-for="r in helioWebhookEvents"
                :key="r.uuid"
                class="align-middle"
                @click="openModal(r)"
                style="cursor: pointer;"
              >
                <td>{{ r.event }}</td>
                <td>{{ r.account?.name }}</td>
                <td>{{ r.account?.uuid }}</td>
                <td>{{ formatDate(r.created_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <b-modal
          v-if="selectedRow"
          @hide="selectedRow = null"
          id="details-modal"
          centered
          size="lg"
          class="p-3"
          header-bg-variant="light"
          header-class="py-3"
          title="Helio Webhook Event Details"
          :ok-only="true"
          ok-title="Close"
        >
          <b-card-text>
            <div class="mb-3">
              <b>Account UUID: </b>{{ selectedRow.account?.uuid }}
            </div>
            <div class="mb-3">
              <b>Account Name: </b>{{ selectedRow.account?.name }}
            </div>
            <div class="mb-3"><b>Event:</b> {{ selectedRow.event }}</div>
            <div class="mb-3"><b>Created At:</b> {{ formatDate(selectedRow.created_at) }}</div>
            <div class="mb-3"><b>Webhook Body:</b></div>
            <pre class="json-body mb-3">{{ formatBody(selectedRow.helio_webhook_request.body) }}</pre>
          </b-card-text>
        </b-modal>

        <div v-if="meta.total_pages > 1" class="overflow-auto">
          <b-pagination
            v-model="currentPage"
            @change="changePage"
            :total-rows="meta.total_count"
            :per-page="per"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'AccountsHelioWebhookEventsTable',

  data() {
    return {
      isLoading: false,
      searchTerm: null,
      selected: 'account_uuid',
      selectedRow: null,
      currentPage: 1,
      per: 25,
      meta: {},
      options: [
        { value: 'account_uuid', text: 'Account UUID' },
        { value: 'event', text: 'Event' },
      ],
      helioWebhookEvents: [],
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    queryParams() {
      let base = `page=${this.currentPage}&per=${this.per}`;
      if (this.searchTerm) { base += `&${this.selected}=${this.searchTerm}`; }
      return base;
    },
  },

  methods: {
    async onCreated() {
      await this.fetchHelioWebhookEvents();
    },

    async submitForm() {
      this.currentPage = 1;
      await this.fetchHelioWebhookEvents();
    },

    async fetchHelioWebhookEvents() {
      try {
        this.isLoading = true;
        const response = await http.get(`/admin/accounts_helio_webhook_events?${this.queryParams}`);
        this.helioWebhookEvents = response.data.accounts_helio_webhook_events;
        this.meta = response.data.meta;
      } catch (error) {
        this.$bvToast.toast('Could not fetch any webhook events', {
          variant: 'danger'
        });
      } finally {
        this.isLoading = false;
      }
    },

    async changePage(newPage) {
      this.currentPage = newPage;
      await this.fetchHelioWebhookEvents();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    formatBody(body) {
      try {
        return JSON.stringify(JSON.parse(body), null, 2);
      } catch (e) {
        return body;
      }
    },

    formatDate(date) {
      if (!date) return '';
      return date.replace('T', ' ');
    },

    openModal(row) {
      this.selectedRow = row;

      this.$nextTick(() => {
        this.$bvModal.show('details-modal');
      });
    },

    closeModal() {
      this.$bvModal.hide('details-modal');
      this.selectedRow = null;
    },
  },
}
</script>
