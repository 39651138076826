<template>
  <div>
    <div class="row flex-between-center pl-3 pr-4">
      <div class="alert alert-primary" role="alert">
        <b-icon-info-circle-fill class="mr-2"></b-icon-info-circle-fill>
        <span v-html="coinflowMessage"></span>
      </div>
    </div>

    <div class="modal-footer">
      <a :href="coinflowLink" target="_blank" class="btn btn-primary btn-sm" variant="primary">
        Submit payment
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CoinflowPaymentModal',

  computed: {
    ...mapGetters('sessions', ['coinflowLink', 'coinflowAccounts']),

    hasInactiveCoinflowAccount() {
      return this.coinflowAccounts.some(account => !account.is_active);
    },

    coinflowMessage() {
      if (this.hasInactiveCoinflowAccount) {
        return 'Your RPC is currently inactive. You can submit payment via Coinflow, and can activate by clicking ' + this.coinflowAnchorTag + ' or by clicking the link in the banner.';
      } else {
        return 'Please submit payment via Coinflow to keep your RPC active. You can submit payment by clicking '  + this.coinflowAnchorTag + ' or by clicking the link in the banner.'
      }
    },

    coinflowAnchorTag() {
      return `<a class="font-weight-bolder" href="${this.coinflowLink}" target="_blank">here</a>`;
    }
  }
}
</script>
