<template>
  <b-modal centered id="manage-user-modal" class="p-3" :hide-footer="true">
    <template #modal-title>
      <h4>Manage my profile</h4>
    </template>

    <user-form :user="currentUser" :isModal="true" :userFormDisabled="false"></user-form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import userForm from './user-form';

export default {
  name: 'EditUserModal',

  components: { userForm },

  computed: {
    ...mapGetters('sessions', ['currentUser'])
  }
}
</script>
