import axios from 'axios';
import events from '@/services/events';

const http = axios.create({
  timeout: 30000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  baseURL: '/api/v1/'
});

export function handleResponseError(error) {
  const { config, response } = error;

  const isSessionsEndpoint = /^sessions/.test(config.url);

  /**
  * collect all the unauthorized requests NOT related to sessions
  * and re-request all of them once a user has been authorized
  */
  if (response?.status === 401 && !isSessionsEndpoint) {
    return new Promise((resolve) => {
      events.$emit('unauthorized');
      events.$once(
        'authorized',
        () => resolve(http({ ...config }))
      );
    });
  }
  return Promise.reject(response);
}

http.interceptors.response.use(
  undefined,
  handleResponseError
);

export default http;
