<template>
  <div class="container">
    <div class="row flex-center min-vh-50 text-center py-6">
      <div class="login-card-width">
        <div class="card">
          <div class="logo-container">
            <router-link to="/users/sign-in" class="d-flex flex-center">
              <triton-logo :height="85"></triton-logo>
            </router-link>
            <p>
              <span class="logo-text">
                Experience the fastest, most reliable, <br />full-service RPC.
              </span>
            </p>
          </div>

          <div class="card-body p-4">
            <transition name="shake" mode="out-in">
              <div v-if="emailError" class="alert alert-danger" role="alert">
                {{ emailError }}
              </div>
            </transition>

            <div class="text-container">
              <h5 class="headline mb-2">Forgot your username?</h5>
              <p>Enter your email and we'll send you username.</p>
            </div>

            <b-form v-on:submit.prevent="submitForm">
              <label for="email">Email</label>
              <b-input
                v-model="email"
                class="mb-3"
                placeholder="Email address"
                required
                autofocus
              />

              <b-button
                :disabled="isLoading"
                type="submit"
                class="primary-button d-block w-100 mt-3"
              >
                <span v-if="isLoading">
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </span>
                <span v-else> Send my username </span>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
label[for='email'] {
  opacity: 0.5;
  text-align: left;
  justify-content: left;
  align-items: left;
  width: 100%;
}
.alert {
  border-radius: 18px;
  background: rgba(255, 6, 155, 0.1);
  color: rgba(255, 6, 155, 1);
  border: 2px dashed rgba(255, 6, 155, 0.4);
}

.input {
  text-align: left;
}

::v-deep .form-control {
  background-color: #f6f7ff;
  border: 1px solid #dddee5;
  border-radius: 10px;
  height: 45px;
}
</style>

<script>
import http from '@/services/http';

export default {
  data() {
    return {
      email: null,
      emailError: null,
      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      try {
        this.isLoading = true;

        const response = await http.post('users/retrieve_username', {
          user: {
            email: this.email,
          },
        });

        this.$router.push({
          name: 'signIn',
          params: {
            recoveryMsg: 'Please check your email to see your username',
          },
        });
      } catch (error) {
        if (error?.status === 422) {
          this.emailError = error.data.message;
        } else {
          this.emailError = '⚠️ There was an unknown error. Please try again later';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
