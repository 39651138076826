const CountryCodes = [
  { country: "", abbreviation: "", telephoneCode: "", flagEmoji: "" },
  { country: "Afghanistan", abbreviation: "AF", telephoneCode: "+93", flagEmoji: "🇦🇫" },
  { country: "Åland Islands", abbreviation: "AX", telephoneCode: "+358", flagEmoji: "🇦🇽" },
  { country: "Albania", abbreviation: "AL", telephoneCode: "+355", flagEmoji: "🇦🇱" },
  { country: "Algeria", abbreviation: "DZ", telephoneCode: "+213", flagEmoji: "🇩🇿" },
  { country: "American Samoa", abbreviation: "AS", telephoneCode: "+684", flagEmoji: "🇦🇸" },
  { country: "Andorra", abbreviation: "AD", telephoneCode: "+376", flagEmoji: "🇦🇩" },
  { country: "Angola", abbreviation: "AO", telephoneCode: "+244", flagEmoji: "🇦🇴" },
  { country: "Anguilla", abbreviation: "AI", telephoneCode: "+264", flagEmoji: "🇦🇮" },
  { country: "Antarctica", abbreviation: "AQ", telephoneCode: "+672", flagEmoji: "🇦🇶" },
  { country: "Antigua and Barbuda", abbreviation: "AG", telephoneCode: "+268", flagEmoji: "🇦🇬" },
  { country: "Argentina", abbreviation: "AR", telephoneCode: "+54", flagEmoji: "🇦🇷" },
  { country: "Armenia", abbreviation: "AM", telephoneCode: "+374", flagEmoji: "🇦🇲" },
  { country: "Aruba", abbreviation: "AW", telephoneCode: "+297", flagEmoji: "🇦🇼" },
  { country: "Australia", abbreviation: "AU", telephoneCode: "+61", flagEmoji: "🇦🇺" },
  { country: "Austria", abbreviation: "AT", telephoneCode: "+43", flagEmoji: "🇦🇹" },
  { country: "Azerbaijan", abbreviation: "AZ", telephoneCode: "+994", flagEmoji: "🇦🇿" },
  { country: "Bahamas", abbreviation: "BS", telephoneCode: "+242", flagEmoji: "🇧🇸" },
  { country: "Bahrain", abbreviation: "BH", telephoneCode: "+973", flagEmoji: "🇧🇭" },
  { country: "Bangladesh", abbreviation: "BD", telephoneCode: "+880", flagEmoji: "🇧🇩" },
  { country: "Barbados", abbreviation: "BB", telephoneCode: "+246", flagEmoji: "🇧🇧" },
  { country: "Belarus", abbreviation: "BY", telephoneCode: "+375", flagEmoji: "🇧🇾" },
  { country: "Belgium", abbreviation: "BE", telephoneCode: "+32", flagEmoji: "🇧🇪" },
  { country: "Belize", abbreviation: "BZ", telephoneCode: "+501", flagEmoji: "🇧🇿" },
  { country: "Benin", abbreviation: "BJ", telephoneCode: "+229", flagEmoji: "🇧🇯" },
  { country: "Bermuda", abbreviation: "BM", telephoneCode: "+441", flagEmoji: "🇧🇲" },
  { country: "Bhutan", abbreviation: "BT", telephoneCode: "+975", flagEmoji: "🇧🇹" },
  { country: "Bolivia (Plurinational State of)", abbreviation: "BO", telephoneCode: "+591", flagEmoji: "🇧🇴" },
  { country: "Bonaire, Sint Eustatius and Saba", abbreviation: "BQ", telephoneCode: "+599", flagEmoji: "🇧🇶" },
  { country: "Bosnia and Herzegovina", abbreviation: "BA", telephoneCode: "+387", flagEmoji: "🇧🇦" },
  { country: "Botswana", abbreviation: "BW", telephoneCode: "+267", flagEmoji: "🇧🇼" },
  { country: "Bouvet Island", abbreviation: "BV", telephoneCode: "+47", flagEmoji: "🇧🇻" },
  { country: "Brazil", abbreviation: "BR", telephoneCode: "+55", flagEmoji: "🇧🇷" },
  { country: "British Indian Ocean Territory", abbreviation: "IO", telephoneCode: "+246", flagEmoji: "🇮🇴" },
  { country: "Brunei Darussalam", abbreviation: "BN", telephoneCode: "+673", flagEmoji: "🇧🇳" },
  { country: "Bulgaria", abbreviation: "BG", telephoneCode: "+359", flagEmoji: "🇧🇬" },
  { country: "Burkina Faso", abbreviation: "BF", telephoneCode: "+226", flagEmoji: "🇧🇫" },
  { country: "Burundi", abbreviation: "BI", telephoneCode: "+257", flagEmoji: "🇧🇮" },
  { country: "Cabo Verde", abbreviation: "CV", telephoneCode: "+238", flagEmoji: "🇨🇻" },
  { country: "Cambodia", abbreviation: "KH", telephoneCode: "+855", flagEmoji: "🇰🇭" },
  { country: "Cameroon", abbreviation: "CM", telephoneCode: "+237", flagEmoji: "🇨🇲" },
  { country: "Canada", abbreviation: "CA", telephoneCode: "+1", flagEmoji: "🇨🇦" },
  { country: "Cayman Islands", abbreviation: "KY", telephoneCode: "+345", flagEmoji: "🇰🇾" },
  { country: "Central African Republic", abbreviation: "CF", telephoneCode: "+236", flagEmoji: "🇨🇫" },
  { country: "Chad", abbreviation: "TD", telephoneCode: "+235", flagEmoji: "🇹🇩" },
  { country: "Chile", abbreviation: "CL", telephoneCode: "+56", flagEmoji: "🇨🇱" },
  { country: "China", abbreviation: "CN", telephoneCode: "+86", flagEmoji: "🇨🇳" },
  { country: "Christmas Island", abbreviation: "CX", telephoneCode: "+61", flagEmoji: "🇨🇽" },
  { country: "Cocos (Keeling) Islands", abbreviation: "CC", telephoneCode: "+891", flagEmoji: "🇨🇨" },
  { country: "Colombia", abbreviation: "CO", telephoneCode: "+57", flagEmoji: "🇨🇴" },
  { country: "Comoros", abbreviation: "KM", telephoneCode: "+269", flagEmoji: "🇰🇲" },
  { country: "Congo", abbreviation: "CG", telephoneCode: "+242", flagEmoji: "🇨🇬" },
  { country: "Congo (Democratic Republic of the)", abbreviation: "CD", telephoneCode: "+243", flagEmoji: "🇨🇩" },
  { country: "Cook Islands", abbreviation: "CK", telephoneCode: "+682", flagEmoji: "🇨🇰" },
  { country: "Costa Rica", abbreviation: "CR", telephoneCode: "+506", flagEmoji: "🇨🇷" },
  { country: "Côte d'Ivoire", abbreviation: "CI", telephoneCode: "+225", flagEmoji: "🇨🇮" },
  { country: "Croatia", abbreviation: "HR", telephoneCode: "+385", flagEmoji: "🇭🇷" },
  { country: "Cuba", abbreviation: "CU", telephoneCode: "+53", flagEmoji: "🇨🇺" },
  { country: "Curaçao", abbreviation: "CW", telephoneCode: "+599", flagEmoji: "🇨🇼" },
  { country: "Cyprus", abbreviation: "CY", telephoneCode: "+357", flagEmoji: "🇨🇾" },
  { country: "Czechia", abbreviation: "CZ", telephoneCode: "+420", flagEmoji: "🇨🇿" },
  { country: "Denmark", abbreviation: "DK", telephoneCode: "+45", flagEmoji: "🇩🇰" },
  { country: "Djibouti", abbreviation: "DJ", telephoneCode: "+253", flagEmoji: "🇩🇯" },
  { country: "Dominica", abbreviation: "DM", telephoneCode: "+767", flagEmoji: "🇩🇲" },
  { country: "Dominican Republic", abbreviation: "DO", telephoneCode: "+809", flagEmoji: "🇩🇴" },
  { country: "Ecuador", abbreviation: "EC", telephoneCode: "+593", flagEmoji: "🇪🇨" },
  { country: "Egypt", abbreviation: "EG", telephoneCode: "+20", flagEmoji: "🇪🇬" },
  { country: "El Salvador", abbreviation: "SV", telephoneCode: "+503", flagEmoji: "🇸🇻" },
  { country: "Equatorial Guinea", abbreviation: "GQ", telephoneCode: "+240", flagEmoji: "🇬🇶" },
  { country: "Eritrea", abbreviation: "ER", telephoneCode: "+291", flagEmoji: "🇪🇷" },
  { country: "Estonia", abbreviation: "EE", telephoneCode: "+372", flagEmoji: "🇪🇪" },
  { country: "Ethiopia", abbreviation: "ET", telephoneCode: "+251", flagEmoji: "🇪🇹" },
  { country: "Falkland Islands (Malvinas)", abbreviation: "FK", telephoneCode: "+500", flagEmoji: "🇫🇰" },
  { country: "Faroe Islands", abbreviation: "FO", telephoneCode: "+298", flagEmoji: "🇫🇴" },
  { country: "Fiji", abbreviation: "FJ", telephoneCode: "+679", flagEmoji: "🇫🇯" },
  { country: "Finland", abbreviation: "FI", telephoneCode: "+358", flagEmoji: "🇫🇮" },
  { country: "France", abbreviation: "FR", telephoneCode: "+33", flagEmoji: "🇫🇷" },
  { country: "French Guiana", abbreviation: "GF", telephoneCode: "+594", flagEmoji: "🇬🇫" },
  { country: "French Polynesia", abbreviation: "PF", telephoneCode: "+689", flagEmoji: "🇵🇫" },
  { country: "French Southern Territories", abbreviation: "TF", telephoneCode: "+689", flagEmoji: "🇹🇫" },
  { country: "Gabon", abbreviation: "GA", telephoneCode: "+241", flagEmoji: "🇬🇦" },
  { country: "Gambia", abbreviation: "GM", telephoneCode: "+220", flagEmoji: "🇬🇲" },
  { country: "Georgia", abbreviation: "GE", telephoneCode: "+995", flagEmoji: "🇬🇪" },
  { country: "Germany", abbreviation: "DE", telephoneCode: "+49", flagEmoji: "🇩🇪" },
  { country: "Ghana", abbreviation: "GH", telephoneCode: "+233", flagEmoji: "🇬🇭" },
  { country: "Gibraltar", abbreviation: "GI", telephoneCode: "+350", flagEmoji: "🇬🇮" },
  { country: "Greece", abbreviation: "GR", telephoneCode: "+30", flagEmoji: "🇬🇷" },
  { country: "Greenland", abbreviation: "GL", telephoneCode: "+299", flagEmoji: "🇬🇱" },
  { country: "Grenada", abbreviation: "GD", telephoneCode: "+473", flagEmoji: "🇬🇩" },
  { country: "Guadeloupe", abbreviation: "GP", telephoneCode: "+590", flagEmoji: "🇬🇵" },
  { country: "Guam", abbreviation: "GU", telephoneCode: "+671", flagEmoji: "🇬🇺" },
  { country: "Guatemala", abbreviation: "GT", telephoneCode: "+502", flagEmoji: "🇬🇹" },
  { country: "Guernsey", abbreviation: "GG", telephoneCode: "+1481", flagEmoji: "🇬🇬" },
  { country: "Guinea", abbreviation: "GN", telephoneCode: "+225", flagEmoji: "🇬🇳" },
  { country: "Guinea-Bissau", abbreviation: "GW", telephoneCode: "+245", flagEmoji: "🇬🇼" },
  { country: "Guyana", abbreviation: "GY", telephoneCode: "+592", flagEmoji: "🇬🇾" },
  { country: "Haiti", abbreviation: "HT", telephoneCode: "+509", flagEmoji: "🇭🇹" },
  { country: "Heard Island and McDonald Islands", abbreviation: "HM", telephoneCode: "+61", flagEmoji: "🇭🇲" },
  { country: "Holy See", abbreviation: "VA", telephoneCode: "+379", flagEmoji: "🇻🇦" },
  { country: "Honduras", abbreviation: "HN", telephoneCode: "+504", flagEmoji: "🇭🇳" },
  { country: "Hong Kong", abbreviation: "HK", telephoneCode: "+852", flagEmoji: "🇭🇰" },
  { country: "Hungary", abbreviation: "HU", telephoneCode: "+36", flagEmoji: "🇭🇺" },
  { country: "Iceland", abbreviation: "IS", telephoneCode: "+354", flagEmoji: "🇮🇸" },
  { country: "India", abbreviation: "IN", telephoneCode: "+91", flagEmoji: "🇮🇳" },
  { country: "Indonesia", abbreviation: "ID", telephoneCode: "+62", flagEmoji: "🇮🇩" },
  { country: "Iran (Islamic Republic of)", abbreviation: "IR", telephoneCode: "+98", flagEmoji: "🇮🇷" },
  { country: "Iraq", abbreviation: "IQ", telephoneCode: "+964", flagEmoji: "🇮🇶" },
  { country: "Ireland", abbreviation: "IE", telephoneCode: "+353", flagEmoji: "🇮🇪" },
  { country: "Isle of Man", abbreviation: "IM", telephoneCode: "+44", flagEmoji: "🇮🇲" },
  { country: "Israel", abbreviation: "IL", telephoneCode: "+972", flagEmoji: "🇮🇱" },
  { country: "Italy", abbreviation: "IT", telephoneCode: "+39", flagEmoji: "🇮🇹" },
  { country: "Jamaica", abbreviation: "JM", telephoneCode: "+876", flagEmoji: "🇯🇲" },
  { country: "Japan", abbreviation: "JP", telephoneCode: "+81", flagEmoji: "🇯🇵" },
  { country: "Jersey", abbreviation: "JE", telephoneCode: "+44", flagEmoji: "🇯🇪" },
  { country: "Jordan", abbreviation: "JO", telephoneCode: "+962", flagEmoji: "🇯🇴" },
  { country: "Kazakhstan", abbreviation: "KZ", telephoneCode: "+7", flagEmoji: "🇰🇿" },
  { country: "Kenya", abbreviation: "KE", telephoneCode: "+254", flagEmoji: "🇰🇪" },
  { country: "Kiribati", abbreviation: "KI", telephoneCode: "+686", flagEmoji: "🇰🇮" },
  { country: "Korea (Democratic People's Republic of)", abbreviation: "KP", telephoneCode: "+850", flagEmoji: "🇰🇵" },
  { country: "Korea (Republic of)", abbreviation: "KR", telephoneCode: "+82", flagEmoji: "🇰🇷" },
  { country: "Kuwait", abbreviation: "KW", telephoneCode: "+965", flagEmoji: "🇰🇼" },
  { country: "Kyrgyzstan", abbreviation: "KG", telephoneCode: "+996", flagEmoji: "🇰🇬" },
  { country: "Lao People's Democratic Republic", abbreviation: "LA", telephoneCode: "+856", flagEmoji: "🇱🇦" },
  { country: "Latvia", abbreviation: "LV", telephoneCode: "+371", flagEmoji: "🇱🇻" },
  { country: "Lebanon", abbreviation: "LB", telephoneCode: "+961", flagEmoji: "🇱🇧" },
  { country: "Lesotho", abbreviation: "LS", telephoneCode: "+266", flagEmoji: "🇱🇸" },
  { country: "Liberia", abbreviation: "LR", telephoneCode: "+231", flagEmoji: "🇱🇷" },
  { country: "Libya", abbreviation: "LY", telephoneCode: "+218", flagEmoji: "🇱🇾" },
  { country: "Liechtenstein", abbreviation: "LI", telephoneCode: "+423", flagEmoji: "🇱🇮" },
  { country: "Lithuania", abbreviation: "LT", telephoneCode: "+370", flagEmoji: "🇱🇹" },
  { country: "Luxembourg", abbreviation: "LU", telephoneCode: "+352", flagEmoji: "🇱🇺" },
  { country: "Macao", abbreviation: "MO", telephoneCode: "+853", flagEmoji: "🇲🇴" },
  { country: "Macedonia (the former Yugoslav Republic of)", abbreviation: "MK", telephoneCode: "+389", flagEmoji: "🇲🇰" },
  { country: "Madagascar", abbreviation: "MG", telephoneCode: "+261", flagEmoji: "🇲🇬" },
  { country: "Malawi", abbreviation: "MW", telephoneCode: "+265", flagEmoji: "🇲🇼" },
  { country: "Malaysia", abbreviation: "MY", telephoneCode: "+60", flagEmoji: "🇲🇾" },
  { country: "Maldives", abbreviation: "MV", telephoneCode: "+960", flagEmoji: "🇲🇻" },
  { country: "Mali", abbreviation: "ML", telephoneCode: "+223", flagEmoji: "🇲🇱" },
  { country: "Malta", abbreviation: "MT", telephoneCode: "+356", flagEmoji: "🇲🇹" },
  { country: "Marshall Islands", abbreviation: "MH", telephoneCode: "+692", flagEmoji: "🇲🇭" },
  { country: "Martinique", abbreviation: "MQ", telephoneCode: "+596", flagEmoji: "🇲🇶" },
  { country: "Mauritania", abbreviation: "MR", telephoneCode: "+222", flagEmoji: "🇲🇷" },
  { country: "Mauritius", abbreviation: "MU", telephoneCode: "+230", flagEmoji: "🇲🇺" },
  { country: "Mayotte", abbreviation: "YT", telephoneCode: "+262", flagEmoji: "🇾🇹" },
  { country: "Mexico", abbreviation: "MX", telephoneCode: "+52", flagEmoji: "🇲🇽" },
  { country: "Micronesia (Federated States of)", abbreviation: "FM", telephoneCode: "+691", flagEmoji: "🇫🇲" },
  { country: "Moldova (Republic of)", abbreviation: "MD", telephoneCode: "+373", flagEmoji: "🇲🇩" },
  { country: "Monaco", abbreviation: "MC", telephoneCode: "+377", flagEmoji: "🇲🇨" },
  { country: "Mongolia", abbreviation: "MN", telephoneCode: "+976", flagEmoji: "🇲🇳" },
  { country: "Montenegro", abbreviation: "ME", telephoneCode: "+382", flagEmoji: "🇲🇪" },
  { country: "Montserrat", abbreviation: "MS", telephoneCode: "+664", flagEmoji: "🇲🇸" },
  { country: "Morocco", abbreviation: "MA", telephoneCode: "+212", flagEmoji: "🇲🇦" },
  { country: "Mozambique", abbreviation: "MZ", telephoneCode: "+258", flagEmoji: "🇲🇿" },
  { country: "Myanmar", abbreviation: "MM", telephoneCode: "+95", flagEmoji: "🇲🇲" },
  { country: "Namibia", abbreviation: "NA", telephoneCode: "+264", flagEmoji: "🇳🇦" },
  { country: "Nauru", abbreviation: "NR", telephoneCode: "+674", flagEmoji: "🇳🇷" },
  { country: "Nepal", abbreviation: "NP", telephoneCode: "+977", flagEmoji: "🇳🇵" },
  { country: "Netherlands", abbreviation: "NL", telephoneCode: "+31", flagEmoji: "🇳🇱" },
  { country: "New Caledonia", abbreviation: "NC", telephoneCode: "+687", flagEmoji: "🇳🇨" },
  { country: "New Zealand", abbreviation: "NZ", telephoneCode: "+64", flagEmoji: "🇳🇿" },
  { country: "Nicaragua", abbreviation: "NI", telephoneCode: "+505", flagEmoji: "🇳🇮" },
  { country: "Niger", abbreviation: "NE", telephoneCode: "+277", flagEmoji: "🇳🇪" },
  { country: "Nigeria", abbreviation: "NG", telephoneCode: "+234", flagEmoji: "🇳🇬" },
  { country: "Niue", abbreviation: "NU", telephoneCode: "+683", flagEmoji: "🇳🇺" },
  { country: "Norfolk Island", abbreviation: "NF", telephoneCode: "+672", flagEmoji: "🇳🇫" },
  { country: "Northern Mariana Islands", abbreviation: "MP", telephoneCode: "+670", flagEmoji: "🇲🇵" },
  { country: "Norway", abbreviation: "NO", telephoneCode: "+47", flagEmoji: "🇳🇴" },
  { country: "Oman", abbreviation: "OM", telephoneCode: "+968", flagEmoji: "🇴🇲" },
  { country: "Pakistan", abbreviation: "PK", telephoneCode: "+92", flagEmoji: "🇵🇰" },
  { country: "Palau", abbreviation: "PW", telephoneCode: "+680", flagEmoji: "🇵🇼" },
  { country: "Palestine, State of", abbreviation: "PS", telephoneCode: "+970", flagEmoji: "🇵🇸" },
  { country: "Panama", abbreviation: "PA", telephoneCode: "+507", flagEmoji: "🇵🇦" },
  { country: "Papua New Guinea", abbreviation: "PG", telephoneCode: "+675", flagEmoji: "🇵🇬" },
  { country: "Paraguay", abbreviation: "PY", telephoneCode: "+595", flagEmoji: "🇵🇾" },
  { country: "Peru", abbreviation: "PE", telephoneCode: "+51", flagEmoji: "🇵🇪" },
  { country: "Philippines", abbreviation: "PH", telephoneCode: "+63", flagEmoji: "🇵🇭" },
  { country: "Pitcairn", abbreviation: "PN", telephoneCode: "+872", flagEmoji: "🇵🇳" },
  { country: "Poland", abbreviation: "PL", telephoneCode: "+48", flagEmoji: "🇵🇱" },
  { country: "Portugal", abbreviation: "PT", telephoneCode: "+351", flagEmoji: "🇵🇹" },
  { country: "Puerto Rico", abbreviation: "PR", telephoneCode: "+787", flagEmoji: "🇵🇷" },
  { country: "Qatar", abbreviation: "QA", telephoneCode: "+974", flagEmoji: "🇶🇦" },
  { country: "Réunion", abbreviation: "RE", telephoneCode: "+262", flagEmoji: "🇷🇪" },
  { country: "Romania", abbreviation: "RO", telephoneCode: "+40", flagEmoji: "🇷🇴" },
  { country: "Russian Federation", abbreviation: "RU", telephoneCode: "+7", flagEmoji: "🇷🇺" },
  { country: "Rwanda", abbreviation: "RW", telephoneCode: "+250", flagEmoji: "🇷🇼" },
  { country: "Saint Barthélemy", abbreviation: "BL", telephoneCode: "+590", flagEmoji: "🇧🇱" },
  { country: "Saint Helena, Ascension and Tristan da Cunha", abbreviation: "SH", telephoneCode: "+290", flagEmoji: "🇸🇭" },
  { country: "Saint Kitts and Nevis", abbreviation: "KN", telephoneCode: "+869", flagEmoji: "🇰🇳" },
  { country: "Saint Lucia", abbreviation: "LC", telephoneCode: "+758", flagEmoji: "🇱🇨" },
  { country: "Saint Martin (French part)", abbreviation: "MF", telephoneCode: "+590", flagEmoji: "🇲🇫" },
  { country: "Saint Pierre and Miquelon", abbreviation: "PM", telephoneCode: "+508", flagEmoji: "🇵🇲" },
  { country: "Saint Vincent and the Grenadines", abbreviation: "VC", telephoneCode: "+784", flagEmoji: "🇻🇨" },
  { country: "Samoa", abbreviation: "WS", telephoneCode: "+685", flagEmoji: "🇼🇸" },
  { country: "San Marino", abbreviation: "SM", telephoneCode: "+378", flagEmoji: "🇸🇲" },
  { country: "Sao Tome and Principe", abbreviation: "ST", telephoneCode: "+239", flagEmoji: "🇸🇹" },
  { country: "Saudi Arabia", abbreviation: "SA", telephoneCode: "+966", flagEmoji: "🇸🇦" },
  { country: "Senegal", abbreviation: "SN", telephoneCode: "+221", flagEmoji: "🇸🇳" },
  { country: "Serbia", abbreviation: "RS", telephoneCode: "+381", flagEmoji: "🇷🇸" },
  { country: "Seychelles", abbreviation: "SC", telephoneCode: "+248", flagEmoji: "🇸🇨" },
  { country: "Sierra Leone", abbreviation: "SL", telephoneCode: "+232", flagEmoji: "🇸🇱" },
  { country: "Singapore", abbreviation: "SG", telephoneCode: "+65", flagEmoji: "🇸🇬" },
  { country: "Sint Maarten (Dutch part)", abbreviation: "SX", telephoneCode: "+599", flagEmoji: "🇸🇽" },
  { country: "Slovakia", abbreviation: "SK", telephoneCode: "+421", flagEmoji: "🇸🇰" },
  { country: "Slovenia", abbreviation: "SI", telephoneCode: "+386", flagEmoji: "🇸🇮" },
  { country: "Solomon Islands", abbreviation: "SB", telephoneCode: "+677", flagEmoji: "🇸🇧" },
  { country: "Somalia", abbreviation: "SO", telephoneCode: "+252", flagEmoji: "🇸🇴" },
  { country: "South Africa", abbreviation: "ZA", telephoneCode: "+27", flagEmoji: "🇿🇦" },
  { country: "South Georgia and the South Sandwich Islands", abbreviation: "GS", telephoneCode: "+500", flagEmoji: "🇬🇸" },
  { country: "South Sudan", abbreviation: "SS", telephoneCode: "+211", flagEmoji: "🇸🇸" },
  { country: "Spain", abbreviation: "ES", telephoneCode: "+34", flagEmoji: "🇪🇸" },
  { country: "Sri Lanka", abbreviation: "LK", telephoneCode: "+94", flagEmoji: "🇱🇰" },
  { country: "Sudan", abbreviation: "SD", telephoneCode: "+249", flagEmoji: "🇸🇩" },
  { country: "Suriname", abbreviation: "SR", telephoneCode: "+597", flagEmoji: "🇸🇷" },
  { country: "Svalbard and Jan Mayen", abbreviation: "SJ", telephoneCode: "+47", flagEmoji: "🇸🇯" },
  { country: "Swaziland", abbreviation: "SZ", telephoneCode: "+268", flagEmoji: "🇸🇿" },
  { country: "Sweden", abbreviation: "SE", telephoneCode: "+46", flagEmoji: "🇸🇪" },
  { country: "Switzerland", abbreviation: "CH", telephoneCode: "+41", flagEmoji: "🇨🇭" },
  { country: "Syrian Arab Republic", abbreviation: "SY", telephoneCode: "+963", flagEmoji: "🇸🇾" },
  { country: "Taiwan", abbreviation: "TW", telephoneCode: "+886", flagEmoji: "🇹🇼" },
  { country: "Tajikistan", abbreviation: "TJ", telephoneCode: "+992", flagEmoji: "🇹🇯" },
  { country: "Tanzania, United Republic of", abbreviation: "TZ", telephoneCode: "+255", flagEmoji: "🇹🇿" },
  { country: "Thailand", abbreviation: "TH", telephoneCode: "+66", flagEmoji: "🇹🇭" },
  { country: "Timor-Leste", abbreviation: "TL", telephoneCode: "+670", flagEmoji: "🇹🇱" },
  { country: "Togo", abbreviation: "TG", telephoneCode: "+228", flagEmoji: "🇹🇬" },
  { country: "Tokelau", abbreviation: "TK", telephoneCode: "+690", flagEmoji: "🇹🇰" },
  { country: "Tonga", abbreviation: "TO", telephoneCode: "+676", flagEmoji: "🇹🇴" },
  { country: "Trinidad and Tobago", abbreviation: "TT", telephoneCode: "+868", flagEmoji: "🇹🇹" },
  { country: "Tunisia", abbreviation: "TN", telephoneCode: "+216", flagEmoji: "🇹🇳" },
  { country: "Turkey", abbreviation: "TR", telephoneCode: "+90", flagEmoji: "🇹🇷" },
  { country: "Turkmenistan", abbreviation: "TM", telephoneCode: "+993", flagEmoji: "🇹🇲" },
  { country: "Turks and Caicos Islands", abbreviation: "TC", telephoneCode: "+649", flagEmoji: "🇹🇨" },
  { country: "Tuvalu", abbreviation: "TV", telephoneCode: "+688", flagEmoji: "🇹🇻" },
  { country: "Uganda", abbreviation: "UG", telephoneCode: "+256", flagEmoji: "🇺🇬" },
  { country: "Ukraine", abbreviation: "UA", telephoneCode: "+380", flagEmoji: "🇺🇦" },
  { country: "United Arab Emirates", abbreviation: "AE", telephoneCode: "+971", flagEmoji: "🇦🇪" },
  { country: "United Kingdom of Great Britain and Northern Ireland", abbreviation: "GB", telephoneCode: "+44", flagEmoji: "🇬🇧" },
  { country: "United States of America", abbreviation: "US", telephoneCode: "+1", flagEmoji: "🇺🇸" },
  { country: "United States Minor Outlying Islands", abbreviation: "UM", telephoneCode: "+1", flagEmoji: "🇺🇲" },
  { country: "Uruguay", abbreviation: "UY", telephoneCode: "+598", flagEmoji: "🇺🇾" },
  { country: "Uzbekistan", abbreviation: "UZ", telephoneCode: "+998", flagEmoji: "🇺🇿" },
  { country: "Vanuatu", abbreviation: "VU", telephoneCode: "+678", flagEmoji: "🇻🇺" },
  { country: "Venezuela (Bolivarian Republic of)", abbreviation: "VE", telephoneCode: "+58", flagEmoji: "🇻🇪" },
  { country: "Viet Nam", abbreviation: "VN", telephoneCode: "+84", flagEmoji: "🇻🇳" },
  { country: "Virgin Islands (British)", abbreviation: "VG", telephoneCode: "+284", flagEmoji: "🇻🇬" },
  { country: "Virgin Islands (U.S.)", abbreviation: "VI", telephoneCode: "+340", flagEmoji: "🇻🇮" },
  { country: "Wallis and Futuna", abbreviation: "WF", telephoneCode: "+681", flagEmoji: "🇼🇫" },
  { country: "Western Sahara", abbreviation: "EH", telephoneCode: "+212", flagEmoji: "🇪🇭" },
  { country: "Yemen", abbreviation: "YE", telephoneCode: "+967", flagEmoji: "🇾🇪" },
  { country: "Zambia", abbreviation: "ZM", telephoneCode: "+260", flagEmoji: "🇿🇲" },
  { country: "Zimbabwe", abbreviation: "ZW", telephoneCode: "+263", flagEmoji: "🇿🇼" },
]


export default CountryCodes;
