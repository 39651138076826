<template>
  <div>
    <div v-if="isLoading" class="loading-overlay py-5">
      <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" />
    </div>

    <div v-else class="p-3">
      <b-row class="d-flex align-items-end flex-nowrap pb-3">
        <b-col :lg="8" class="d-flex align-items-end">
          <div class="d-flex align-items-end">

            <span class="username h1 mr-3">{{ user.username }}</span>
            <span class="h5 text-secondary mr-3">{{ user.email }}</span>
            <span v-if="roleName === 'admin'" class="h5 badge badge-soft-primary">
              Admin
            </span>
            <span v-else-if="roleName === 'operator'" class="h5 badge badge-soft-info">
              Operator
            </span>
            <span v-else-if="roleName === 'reseller'" class="h5 badge badge-soft-success">
              Reseller
            </span>

            <span class="unconfirmed-overlay p-2 pl-3">
              <span
                v-if="!userIsConfirmed"
                v-b-tooltip.hover
                class="badge rounded-pill bg-primary text-white"
                title='User has not confirmed their profile and set a username or password yet.
                You can resend their confirmation email by clicking the "Resend Invite Email"
                button to the right.'
              >
                <span class="unconfirmed-tip">Unconfirmed</span>
                <b-icon font-scale="1.5" icon="info-circle"></b-icon>
              </span>
            </span>
          </div>
        </b-col>

        <b-col class="d-flex align-items-end justify-content-end">
          <span v-if="currentUser.is_admin" class="h6 mr-3">
            <b-link v-b-modal="'versions-modal-' + user.uuid">
              <span>Versions</span>
            </b-link>

            <versions-modal :title="'User ' + user.username" :uuid="user.uuid" :versions="user.versions" />
          </span>

          <span v-if="hasDeactivationReasons" class="h6 mr-3">
            <b-link v-b-modal="'deactivation-reasons-modal-' + user.uuid">
              <span>Deactivation reasons</span>
            </b-link>

            <deactivation-reasons-modal
              title="User"
              :uuid="user.uuid"
              :deactivation-reasons="user.deactivation_reasons"
            />
          </span>

          <template v-if="canManageUserActivation">
            <activate-deactivate-switch
              :resource="user"
              resource-name="user"
              :is-admin-path="true"
              v-on:activated="activate"
              v-on:deactivated="deactivate"
            />
          </template>
        </b-col>
      </b-row>

      <users-rpc-summary :subscriptions="activeSubscriptions" :isLoading="isLoading" />

      <div class="row mb-4">
        <!-- Subscriptions -->
        <div class="col-xxl-6 mb-3">
          <div class="card h-lg-100 pr-4">
            <div class="card-header">
              <h2>
                Subscriptions
                <span
                  v-b-tooltip.hover
                  class="badge pl-1"
                  title="Where RPC access is managed, and isolates access to devnet and mainnet."
                >
                  <b-icon font-scale="0.8" icon="info-circle"></b-icon>
                </span>
              </h2>
            </div>
            <div class="card-body h-100 pt-0">
              <b-link
                v-if="accounts.length === 0 && currentUser.is_admin"
                v-b-modal="'modal-create-new-account'"
              >
                Create an account before creating subscriptions
              </b-link>
              <b-link
                v-else-if="accounts.length > 0 && canCreateSubscriptions"
                v-b-modal="'modal-create-new-subscription'"
              >
                Create new subscription
                <create-new-subscription-modal
                  v-if="accounts.length"
                  :accounts="accounts"
                />
              </b-link>

              <div class="pt-2">
                <subscriptions-table :userUuid="user.uuid" :updatedAccount="updatedAccount" />
              </div>

              <create-new-account-modal :userUuid="uuid" />
            </div>
          </div>
        </div>
        <!-- End Subscriptions -->

        <!-- Accounts -->
        <div class="col-xxl-6">
          <div class="mb-3">
            <div class="card h-lg-100">
              <div class="card-header d-flex justify-content-between">
                <h2>
                  Accounts
                  <span
                    v-b-tooltip.hover
                    class="badge pl-1"
                    title="Used for billing. You can have multiple accounts setup if you need separate billing for your subscriptions."
                  >
                    <b-icon font-scale="0.8" icon="info-circle"></b-icon>
                  </span>
                </h2>
                <div>
                  <b-button
                    v-if="currentUser.is_admin"
                    v-b-modal="'modal-create-new-account'"
                    class="btn btn-primary"
                  >
                    New
                  </b-button>
                </div>
              </div>

              <div class="card-body h-100 pr-4">
                <div class="table-responsive scrollbar">
                  <table class="table table-hover table-sm overflow-hidden">
                    <thead>
                      <tr class="btn-reveal-trigger">
                        <th scope="col">Name</th>
                        <th>Activation date</th>
                        <th class="text-right" scope="col">Status</th>
                        <th
                          v-if="isCurrentUserAdmin"
                          class="text-right"
                          scope="col"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- TV TODO: Move to shared table component -->
                      <!-- TODO: Show accounts for user -->
                      <tr
                        v-for="account in accounts"
                        :key="account.uuid"
                        class="btn-reveal-trigger"
                      >
                        <td>
                          <router-link
                            :to="{
                              name: 'editAccount',
                              params: { uuid: account.uuid },
                            }"
                          >
                            {{ account.name }}
                          </router-link>
                        </td>
                        <td>
                          {{ formattedDate(account.last_time_activated) }}
                        </td>
                        <td class="text-right">
                          <span v-if="account.is_active" class="text-success">
                            Enabled
                          </span>
                          <span v-else class="text-danger"> Disabled </span>
                        </td>
                        <td v-if="isCurrentUserAdmin" class="text-right">
                          <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template #button-content>
                              <b-icon-three-dots></b-icon-three-dots>
                            </template>
                            <b-dropdown-item
                              v-if="account.is_active"
                              @click="toggleAccountActivation(account)"
                            >
                              <span class="text-danger">Deactivate</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                              v-else
                              class="text-success"
                              @click="toggleAccountActivation(account)"
                            >
                              <span class="text-success">Activate</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Accounts -->

        <!-- User -->
        <div v-if="showUserForm" class="col-xl-6">
          <div class="mb-3">
            <div class="card h-lg-100">
              <div class="card-header">
                <span class="h2 float-start">User </span>
                <span class="float-right">
                  <span v-if="!userIsConfirmed">
                    <b-button v-b-modal.invite-user-modal variant="primary">
                      Send invite email
                    </b-button>
                  </span>

                  <b-modal centered id="invite-user-modal" size="xl" :hide-footer="true">
                    <template #modal-title>
                      <h4>Invitation email</h4>
                    </template>

                    <b-form v-on:submit.prevent="sendInvitationEmail">
                      <div>
                        <vue-editor v-model="emailBody" :editor-toolbar="editorToolbarOptions"/>
                      </div>

                      <b-button
                        :disabled="isSending"
                        type="submit"
                        class="invite btn-primary mt-4 float-right"
                      >
                        <span v-if="isSending">
                          <b-spinner small></b-spinner>
                          <span class="sr-only">Loading...</span>
                        </span>
                        <span v-else> Send </span>
                      </b-button>
                    </b-form>
                  </b-modal>

                  <b-button v-if="userFormDisabled" @click="enableUserForm">
                    Edit
                  </b-button>
                </span>
              </div>
              <div class="card-body">
                <user-form
                  :user="user"
                  v-bind:userFormDisabled="userFormDisabled"
                  v-on:disable-user-form="disableUserForm"
                  v-on:user-updated="updateUser"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- End User -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapMutations } from 'vuex';
import http from '@/services/http';
import events from '@/services/events';

import accountsMixin from '@/mixins/accounts';
import subscriptionsMixin from '@/mixins/subscriptions';

import subscriptionsTable from '../../globals/subscriptions-table';
import createNewSubscriptionModal from '../../globals/create-new-subscription-modal';
import createNewAccountModal from '../../globals/create-new-account-modal';
import versionsModal from '../../globals/versions-modal';
import userForm from '../../globals/user-form';
import usersRpcSummary from './users-rpc-summary';
import activateDeactivateSwitch from '../../globals/activate-deactivate-switch';

export default {
  name: 'MyRpc',

  components: {
    userForm,
    subscriptionsTable,
    createNewSubscriptionModal,
    createNewAccountModal,
    versionsModal,
    usersRpcSummary,
    activateDeactivateSwitch,
  },

  mixins: [accountsMixin, subscriptionsMixin],

  data() {
    return {
      user: {},
      username: null,
      email: null,
      accounts: [],
      currentPassword: null,
      password: null,
      passwordConfirmation: null,
      editorToolbarOptions: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'], // remove formatting button
      ],
      confirmationLink: null,
      emailBody: null,
      userFormDisabled: true,
      updatedAccount: null,
      isSending: false,
      isLoading: true,
    };
  },

  created() {
    this.onCreated();
  },

  watch: {
    uuid: 'onCreated',
    confirmationLink: 'interpolateDefaultEmailBody',
  },

  computed: {
    ...mapState({
      subscriptions: (state) => state.subscriptions.subscriptions,
    }),

    ...mapGetters('sessions', [
      'currentUser',
      'isCurrentUserAdmin',
      'canCreateAccounts',
      'canCreateSubscriptions',
      'canManageUserActivation',
    ]),

    roleName() {
      return this.user.role_name;
    },

    userIsNotCurrentUser() {
      if (this.uuid === this.currentUser.uuid) {
        return false;
      } else {
        return true;
      }
    },

    userIsConfirmed() {
      return !!this.user.confirmed_at;
    },

    uuid() {
      return this.$route.params.uuid;
    },

    accountUuid() {
      return this.accounts && this.accounts[0] && this.accounts[0].uuid;
    },

    showUserForm() {
      return this.currentUser.is_admin;
    },

    activeSubscriptions() {
      return this.subscriptions.filter((subscription) => {
        return subscription.is_active && subscription.account?.is_active;
      });
    },

    hasDeactivationReasons() {
      return this.user.deactivation_reasons?.length;
    },
  },

  methods: {
    ...mapMutations('subscriptions', ['UPDATE_SUBSCRIPTION_ACTIVATION']),

    async onCreated() {
      try {
        const response = await http.get(`users/${this.uuid}`);
        this.user = response.data.user;
        this.accounts = this.user.accounts;
        this.username = this.user.username;
        this.email = this.user.email;

        this.accounts.forEach((account) => {
          events.$on(`accountEventReceived-${account.uuid}`, this.handleAccountEvent);
        });

        await this.fetchSubscriptions(this.user.uuid);

        if (!this.userIsConfirmed) {
          this.confirmationLink = await this.getConfirmationLink();
        }
      }

      catch (error) {
        console.log(error);

        if ([403, 404].includes(error.status)) {
          this.$router.push({
            name: 'notFound',
            params: { message: 'User with given UUID cannot be found.' }
          });
        }
      }

      finally {
        this.isLoading = false;
      }
    },

    async fetchSubscriptions() {
      await this.$store.dispatch('subscriptions/fetchSubscriptions', {
        user_uuid: this.user.uuid,
      });
    },

    updateUser(user) {
      this.user = user;
      this.username = user.username;
      this.email = user.email;
    },

    formattedDate(date) {
      const formattedDate = moment(date).format('YYYY/MM/DD');
      return moment(formattedDate, 'YYYY/MM/DD', true).isValid()
        ? formattedDate
        : '';
    },

    handleAccountEvent(data) {
      let account = this.accounts.find(
        (account) => account.uuid === data.account_uuid
      );

      if (account) {
        account.is_active = data.is_active;

        let subsForAccount = this.subscriptions.filter(
          (sub) => sub.account_uuid === data.account_uuid
        );

        subsForAccount.forEach((subscription) => {
          this.UPDATE_SUBSCRIPTION_ACTIVATION(subscription, data.is_active);
        });
      }
    },

    async getConfirmationLink() {
      const response = await http.get(
        `admin/users/${this.uuid}/confirmation_link`
      );
      return response.data.user.confirmation_link;
    },

    interpolateDefaultEmailBody() {
      if (this.confirmationLink) {
        this.emailBody = `<h1>Welcome to Triton RPC!</h1></br><p>You've been invited to create an account on triton.one. After you set up your account, you can use the customers.triton.one website to manage your RPC access. You can enter your username and set your password through the link below:</p><p><a href='${this.confirmationLink}'>Confirm my account</a></p></br><p>We use hel.io for payments & subscriptions. After you connect your wallet, follow the prompts to pay and activate your account. See our docs site for more details: <a href='https://docs.triton.one/customers/payments'>https://docs.triton.one/customers/payments</a></p></br><p>If the link above doesn't work, you can copy and paste the following URL into your browser:</p></br><p>${this.confirmationLink}</p>`;
      }
    },

    async sendInvitationEmail() {
      try {
        this.isSending = true;

        await http.put(
          `admin/users/${this.uuid}/send_confirmation_instructions`,
          { email_body: this.emailBody }
        );

        this.$bvToast.toast('Successfully sent invitation email', {
          title: 'Success',
          variant: 'default',
        });

        this.$bvModal.hide('invite-user-modal');
      } catch (error) {
        console.log('Error:', error);
      } finally {
        this.isSending = false;
      }
    },

    // TODO: duplicate method. Also in accounts-table.vue
    toggleAccountActivation(account) {
      let promptMessage = '';
      let confirmOkVariant = '';
      let confirmationMessage = '';
      if (account.is_active) {
        promptMessage =
          "Are you sure you want to deactivate this account? This will also deactivate all of the account's subscriptions";
        confirmOkVariant = 'danger';
        confirmationMessage = 'You successfully activated the account';
      } else {
        promptMessage = 'Are you sure you want to activate this account?';
        confirmOkVariant = 'primary';
        confirmationMessage = 'You successfully deactivated the account';
      }

      this.$bvModal
        .msgBoxConfirm(promptMessage, {
          okVariant: confirmOkVariant,
          okTitle: 'Yes',
        })
        .then((value) => {
          if (value === true) {
            if (account.is_active) {
              this.deactivateAccount(account.uuid);
            } else {
              this.activateAccount(account.uuid);
            }

            this.toggleAccount(account.uuid);
            this.toggleSubscriptionsActivation(account.uuid);

            this.updatedAccount = {
              uuid: account.uuid,
              isActive: account.is_active,
            };
            // TODO: Need to mark the subscription, which is shown on this page, as deactivated since if
            // you reload the page it will be deactivated.

            this.$bvToast.toast(confirmationMessage, {
              title: 'Success',
              variant: 'default',
              autoHideDelay: 500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toggleSubscriptionsActivation(uuid) {
      this.$store.dispatch('subscriptions/toggleSubscriptionsActivation', {
        accountUuid: uuid,
      });
    },

    toggleAccount(uuid) {
      const uuids = this.accounts.map((a) => a.uuid);
      const i = uuids.indexOf(uuid);
      this.accounts[i].is_active = !this.accounts[i].is_active;
    },

    enableUserForm() {
      this.userFormDisabled = false;
    },

    disableUserForm() {
      this.userFormDisabled = true;
    },

    activate() {
      this.user.is_active = true;
    },

    deactivate(reason) {
      this.user.is_active = false;
      this.user.deactivation_reasons.push(reason);
    },
  },
};
</script>


<style lang="scss" scoped>
.unconfirmed-overlay {
  margin-top: 5px;

  .unconfirmed-tip {
    display: inline-block;
    padding: 0 8px 0 0;
    position: relative;
    top: -2px;
  }
}
.card-header {
  border-radius: 20px;
}
.invite {
  margin: 15px 0 0 0 !important;
}

.username {
  margin-bottom: 0;
}
</style>