<template>
  <div class="container">
    <div class="row flex-center min-vh-50 py-6">
      <div class="login-card-width">
        <div class="card">
          <div class="logo-container">
            <router-link to="/users/sign-in" class="d-flex flex-center">
              <triton-logo :height="85"></triton-logo>
            </router-link>
            <p>
              <span class="logo-text">
                Experience the fastest, most reliable, <br />full-service RPC.
              </span>
            </p>
          </div>

          <div class="card-body p-4">
            <transition name="shake" mode="out-in">
              <div v-if="confirmUserError" class="alert alert-danger" role="alert">
                {{ confirmUserError }}
              </div>
            </transition>

            <div class="text-container">
              <h5 class="headline mb-2">Didn't receive your confirmation?</h5>
              <p>Enter your email and we'll send you another one right away.</p>
            </div>

            <b-form @submit.prevent="confirmUser">
              <div class="mb-3">
                <label for="email">Email</label>
                <b-input v-model="email" placeholder="Email" required autofocus />
              </div>
              <div class="mb-3">
                <b-button type="submit" class="primary-button d-block w-100 mt-3">
                  Resend Confirmation
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'ConfirmUser',

  data() {
    return {
      email: null,
      confirmUserError: null,
    };
  },

  methods: {
    async confirmUser() {
      try {
        await http.post('/users/confirmations', {
          user: {
            email: this.email,
          },
        });

        this.$router.push({
          name: 'signIn',
          params: {
            recoveryMsg: 'Please check your email for a link to confirm your account',
          },
        });
      } catch (error) {
        if (error?.status === 422) {
          this.confirmUserError = error.data.message;
        } else {
          this.confirmUserError = '⚠️ There was an unknown error. Please try again later';
        }
      }
    },
  },
};
</script>

<style scoped>
label[for='email'] {
  opacity: 0.5;
}
.alert {
  border-radius: 18px;
  background: rgba(255, 6, 155, 0.1);
  color: rgba(255, 6, 155, 1);
  border: 2px dashed rgba(255, 6, 155, 0.4);
}

::v-deep .form-control {
  background-color: #f6f7ff;
  border: 1px solid #dddee5;
  border-radius: 10px;
  height: 45px;
}
</style>