<template>
  <div class="row">
    <div class="col-12">
      <h3 class='border-bottom pb-1 mt-3 mb-0'>Tokens</h3>

      <table class="w-100 mb-3">
        <tbody>
          <tr v-for="token in searchResults" class='border-bottom'>
            <td>
              <router-link :to="`/subscriptions/${token.subscription_uuid}`">
                {{ token.name }}
              </router-link>
            </td>
            <td class="text-break">
              Username: <span class="text-monospace">{{ token.auth_username }}</span>
            </td>
            <td>
              Tier: <span class="text-monospace">{{ token.rate_tier.name }}</span>
            </td>
            <td>
              <span v-if="token.is_active" class="text-success">
                Enabled
              </span>
              <span v-else-if="!token.is_active" class="text-danger">
                Disabled
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TokenSearchResults',

  props: {
    searchResults: [],
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/custom/searches.scss';
</style>
