<template>
  <div>
    <b-form v-on:submit.prevent="searchEndpoints">
      <div class="form-group">
        <b-input v-model="searchTerm" placeholder="Name starts with..." type="search" />
      </div>
    </b-form>

    <div v-if="isLoading">
      <div class="text-center mb-3">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </div>
    <div v-else>
      <div class="table-responsive scrollbar">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Value(s)</th>
              <th scope="col">Subscription</th>
              <th scope="col" class="text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="endpoint in endpoints" :key="endpoint.uuid">
              <!-- Name -->
              <td>
                <router-link :to="{ name: 'editSubscription', params: { uuid: endpoint.subscription.uuid } }">
                  <span>{{ endpoint.name }}</span>
                </router-link>
              </td>
              <!-- Value(s) -->
              <td>
                <span>{{ endpoint.values.join(', ') }}</span>
              </td>
              <!-- Subscription -->
              <td>
                <router-link :to="{ name: 'editSubscription', params: { uuid: endpoint.subscription.uuid } }">
                  {{ endpoint.subscription.name }}
                </router-link>
              </td>
              <!-- Status -->
              <td class="text-right">
                <span v-if="endpoint.is_active" class="text-success">Enabled</span>
                <span v-else class="text-danger">Disabled</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="meta?.total_pages > 1">
      <b-pagination
        v-model="currentPage"
        :total-rows="meta?.total_count"
        :per-page="perPage"
        @change="changePage"
      />
    </div>

    <p class="fs--1">{{ meta?.total_count }} Endpoints</p>
  </div>
</template>

<script>
import http from '@/services/http';
import debounce from 'lodash.debounce';

export default {
  name: 'RateTierEndpointsTable',

  data() {
    return {
      isLoading: true,
      perPage: 50,
      currentPage: 1,
      endpoints: [],
      searchTerm: null,
      meta: null
    }
  },

  props: ['rateTierUuid'],

  watch: {
    searchTerm: 'debouncedSearchEndpoints',
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      await this.fetchEndpoints();
    },

    debouncedSearchEndpoints: debounce(function () {
      this.searchEndpoints();
    }, 500),

    async changePage(newPage) {
      this.currentPage = newPage;

      await this.fetchEndpoints();
    },

    async searchEndpoints() {
      this.currentPage = 1;
      await this.fetchEndpoints();
    },

    async fetchEndpoints() {
      try {
        this.isLoading = true;

        const endpointsParams = {
          page: this.currentPage,
          per: this.perPage,
          rate_tier_uuid: this.rateTierUuid
        }

        if (this.searchTerm) { endpointsParams.name = this.searchTerm; }

        const response = await http.get(`endpoints`, { params: endpointsParams });

        this.endpoints = response.data.endpoints;
        this.meta = response.data.meta;
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(
          'An error occurred while fetching endpoints',
          {
            title: 'Error',
            variant: 'danger',
            solid: true
          }
        );
      }

      finally {
        this.isLoading = false;
      }
    },
  }
}
</script>
