import globals from '@/globals';

import main from '@/components/globals/main.vue';
import router from '@/router/router.js';
import store from '@/store/store.js';
import Vue from 'vue';

Vue.config.productionTip = false;


globals.initialize();


const app = new Vue({
  router,
  render(createElement) {
    return createElement(main)
  },
  store
});


app.$mount('#app');


export default app;
