<template>
  <div class="card">
    <div class="card-header">
      <div>
        <span class="h2 flat-start">REST API Tokens</span>
        <b-button v-b-modal="'modal-create-new-access-token'" class="btn btn-primary float-right">
          New Access Token
        </b-button>
      </div>

      <p class="fs--1">
        Create access tokens for back-end access to the REST API
      </p>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-sm table-hover overflow-hidden">
          <thead>
            <tr class="btn-reveal-trigger">
              <th scope="col">Description</th>
              <th>Role</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="token in accessTokens" :key="token.uuid" class="btn-reveal-trigger" v-b-tooltip.hover.top="'Public ID: ' + token.uuid">
              <td>
                <p v-if="token.description">
                  {{ token.description.length <= 50 ? token.description : token.description.slice(0, 47) + '...' }}
                </p>
              </td>
              <td>
                <p :class="'badge ' + roleBadges[token.role.name].badge">{{ token.role.name }}</p>
              </td>
              <td style="white-space: nowrap;">
                <p>{{ token.created_at.split('T')[0] }}</p>
              </td>
              <td>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="deleteAccessToken(token)"
                >
                  Delete
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal
      id="modal-create-new-access-token"
      centered
      class="p-3"
      header-bg-variant="light"
      header-class="py-3"
      title="Create access token"
      hide-footer
    >
      <div v-if="createdTokenValue">
        <b-alert show variant="success">
          Successfully created access token. Copy the value below and store it somewhere safe. <b>You will not be able to see it again.</b> You can use the public ID to identify the token after it has been created - it is not utilized to authenticate.
        </b-alert>

        <div class="d-flex justify-content-between">
          <div class="flex-fill mr-2">
            <b-form-group label="Public ID">
              <b-form-input required v-model="createdTokenId" :disabled="true" />
            </b-form-group>
          </div>

          <b-button class="input-height" @click="$_copyToClipboard(createdTokenId)">
            Copy
          </b-button>
        </div>

        <div class="d-flex justify-content-between">
          <div class="flex-fill mr-2">
            <b-form-group label="Access Token">
              <b-form-input required v-model="createdTokenValue" :disabled="true" />
            </b-form-group>
          </div>

          <b-button class="input-height" @click="$_copyToClipboard(createdTokenValue)">
            Copy
          </b-button>
        </div>
      </div>

      <b-form @submit.prevent="createNewAccessToken">
        <b-form-group label="Description">
          <b-form-input v-model="form.description" placeholder="Optional description" autofocus :disabled="formDisabled" />
        </b-form-group>

        <b-form-group label="Role">
          <b-form-radio-group
            v-model="form.role_name"
            :options="roles"
            :disabled="formDisabled"
            button-variant="outline-secondary"
            buttons
            required
          ></b-form-radio-group>
        </b-form-group>

        <role-definitions-list :roles="roles" />

        <b-button v-if="!formDisabled" type="submit" variant="primary">Create</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<style scoped>
.modal-dialog h4,
.modal-dialog p {
  margin: 0;
}

table p {
  margin: 5px 0 0 0;
}
</style>

<script>
import copyToClipboard from '@/mixins/copy-to-clipboard';
import http from '@/services/http';
import { mapGetters } from 'vuex';
import roleBadges from '@/constants/roles.js';
import roleDefinitionsTable from '../../globals/role-definitions-list.vue';

export default {
  components: { roleDefinitionsTable },
  name: 'AccessTokensTable',

  mixins: [copyToClipboard],

  props: ['accountUuid'],

  data() {
    return {
      accessTokens: [],
      form: {
        description: '',
        role_name: 'standard',
      },
      formDisabled: false,
      createdTokenValue: null,
      createdTokenId: null,
      roleBadges
    }
  },

  computed: {
    ...mapGetters(
      'sessions', ['currentUser'],
    ),

    roles() {
      if (this.currentUser.role.name === 'operator') {
        return [
          { value: 'standard', text: 'Standard' },
          { value: 'operator', text: 'Operator' },
        ];
      } else if (['reseller', 'admin'].includes(this.currentUser.role.name)) {
        return [
          { value: 'standard', text: 'Standard' },
          { value: 'operator', text: 'Operator' },
          { value: 'reseller', text: 'Reseller' },
        ];
      }
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'modal-create-new-access-token') {
          this.resetForm();
        }
      })

      try {
        const response = await http.get(`accounts/${this.accountUuid}/access_tokens`);

        this.accessTokens = response.data.access_tokens;
      }

      catch (error) {
        console.log('Error:', error)
      }
    },

    async createNewAccessToken() {
      try {
        const response = await http.post(`accounts/${this.accountUuid}/access_tokens`, { access_token: this.form });
        const accessToken = response.data.access_token;

        this.$bvToast.toast('Created access token', {
          title: 'Created'
        });

        this.formDisabled = true;

        this.accessTokens.push(accessToken);

        this.createdTokenValue = accessToken.token;
        this.createdTokenId = accessToken.uuid;
      }

      catch (error) {
        let sentence = `There was an error submitting your request. Please try again later`

        if (error.data.errors) {
          sentence = Object.keys(error.data.errors).map((key) => {
            return `${key} ${error.data.errors[key]}`;
          }).join(', ');
        }

        this.$bvToast.toast(
          sentence,
          {
            title: error.data.message || 'Unable to create new access token',
            variant: 'danger'
          }
        );
      }
    },

    resetForm() {
      this.form = {
        description: '',
        role: '',
      };

      this.createdTokenValue = null;
      this.createdTokenId = null;

      this.formDisabled = false;
    },

    async deleteAccessToken(acccessToken) {
      this.$bvModal.msgBoxConfirm("Are you sure you want to delete this access token? It will immediately become unusable.", {
        okVariant: 'danger',
        okTitle: 'Yes',
      })
        .then(async value => {
          if (value === true) {
            const response = await http.delete(`accounts/${this.accountUuid}/access_tokens/${acccessToken.uuid}`);

            if (response.status === 204) {
              this.$bvToast.toast('Successfully deleted acccess token', {
                title: 'Success'
              });

              const i = this.accessTokens.indexOf(acccessToken);
              this.accessTokens.splice(i, 1);
            } else {
              this.$bvToast.toast(response.statusText, {
                title: 'Unable to delete access token',
                variant: 'error'
              });
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    closeTokenModal() {
      this.$bvModal.hide('modal-create-new-access-token');
    },
  }
}
</script>
