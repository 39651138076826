<template>
  <div class="mt-4">
    <dl class="row" v-for="role in roles" :key="role.value">
      <dt class="col-sm-3">{{ role.text }}</dt>
      <dd class="col-sm-9">
        {{ getRoleDefinition(role.value) }}
      </dd>
    </dl>
  </div>
</template>

<script>
import roleDefinitions from '@/mixins/role-definitions.js';

export default {
  mixins: [roleDefinitions],

  props: {
    roles: {
      type: Array,
      required: true
    }
  }
}
</script>
