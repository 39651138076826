<template>
  <div>
    <b-alert show variant="primary">
      <h5 class="alert-heading">
        Account was deactivated on {{ dayjs(newestDeactivationReason.created_at).format('MM/DD/YY h:mm A') }} (local time)
      </h5>
      <div v-if="newestDeactivationReason.description">
        Deactivation reason:
        {{ newestDeactivationReason.description }}
      </div>
    </b-alert>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'DeactivationReasonsAlert',

  props: {
    deactivationReasons: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      dayjs: dayjs
    };
  },

  computed: {
    newestDeactivationReason() {
      return this.deactivationReasons[this.deactivationReasons.length - 1];
    }
  }
}
</script>
