const copyToClipboard = {
  methods: {
    $_copyToClipboard(value) {
      navigator.clipboard.writeText(value);

      this.$bvToast.toast('Copied to clipboard', {
        title: 'Copied',
        variant: 'default',
        autoHideDelay: 300
      });
    }
  }
};

export default copyToClipboard;
