<template>
  <b-modal :id="modalId" :centered=true :hide-footer="true">
    <template #modal-title>
      <span class="h4 mr-2">Address watch list</span>
    </template>

    <b-form-group label="Variant">
      <b-form-select :options="variantsDropdownOptions" v-model="variant" disabled />
    </b-form-group>

    <div class="d-flex">
      <div class="flex-fill mr-2">
        <b-form-group label="Encoded value">
          <b-form-textarea v-model="encodedValue" max-rows="10" disabled />
        </b-form-group>
      </div>

      <div>
        <b-button @click="$_copyToClipboard(encodedValue)" class="mt-4">
          Copy
        </b-button>
      </div>
    </div>

    <p>Created at: <span class="font-weight-bold">{{ formattedDate(createdAt) }} (local time)</span></p>
  </b-modal>
</template>

<script>
import copyToClipboard from '@/mixins/copy-to-clipboard';
import moment from 'moment'

export default {
  name: 'addressWatchListModal',

  props: {
    addressWatchList: {
      type: Object,
      required: true
    },

    variantsDropdownOptions: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      variant: null,
      encodedValue: null,
      createdAt: null,
    }
  },

  mixins: [copyToClipboard],

  computed: {
    modalId() {
      return 'address-watch-list-modal-' + this.addressWatchList.uuid;
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
      this.variant = this.addressWatchList.variant;
      this.encodedValue = this.addressWatchList.encoded_value;
      this.createdAt = this.addressWatchList.created_at;
    },

    formattedDate(createdAt) {
      return moment(createdAt).format('MM/DD/YYYY hh:mm:ss A')
    },
  }
}
</script>

