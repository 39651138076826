<template>
  <div class="pt-3">
    <div v-if="isLoading" class="text-center">
      <b-spinner class="align-middle mr-3"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <b-table
        hover
        :items="marketplaceEpochs"
        :fields="fields"
        class="table-responsive scrollbar"
        @row-clicked="onRowClicked"
      >
        <!-- Scoped slot for the 'Epoch Ends At (approx)' column -->
        <template #cell(epoch_ends_at_approx)="data">
          <span v-if="data.item.epoch_info && data.item.epoch_info.is_current_epoch">
            {{ data.item.epoch_info.epoch_ends_at }}
          </span>
        </template>
      </b-table>
    </div>

    <b-modal v-model="showModal" title="Epoch Info" hide-footer>
      <div v-if="selectedEpochInfo">
        <p><strong>Epoch:</strong> {{ selectedEpochInfo.epoch }}</p>
        <p><strong>Is Current Epoch:</strong> {{ selectedEpochInfo.is_current_epoch }}</p>
        <p><strong>Epoch Start Slot:</strong> {{ selectedEpochInfo.epoch_start_slot }}</p>
        <p><strong>Epoch End Slot:</strong> {{ selectedEpochInfo.epoch_end_slot }}</p>
        <p><strong>Epoch Starts At:</strong> {{ selectedEpochInfo.epoch_starts_at }}</p>
        <p><strong>Epoch Ends At:</strong> {{ selectedEpochInfo.epoch_ends_at }}</p>
        <p><strong>Slots In Epoch:</strong> {{ selectedEpochInfo.slots_in_epoch }}</p>
        <p><strong>Created At:</strong> {{ selectedEpochInfo.created_at }}</p>
        <p><strong>Updated At:</strong> {{ selectedEpochInfo.updated_at }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'AdminMarketplaceEpochs',

  data() {
    return {
      marketplaceEpochs: [],
      isLoading: false,
      fields: [
        { key: 'epoch', label: 'Epoch' },
        { key: 'epoch_info.is_current_epoch', label: 'Current?' },
        { key: 'current_available_bandwidth', label: 'Available' },
        { key: 'current_floor', label: 'Current Floor' },
        { key: 'epoch_ends_at_approx', label: 'Epoch Ends At (approx)' },
        { key: 'orders_closed_at', label: 'Orders Closed At' },
      ],
      showModal: false,
      selectedEpochInfo: null,
    };
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      this.isLoading = true;
      try {
        const { data } = await http.get('/marketplace_epochs');
        this.marketplaceEpochs = data.marketplace_epochs;
      } catch (error) {
        console.error('Failed to fetch marketplace epochs:', error);
      } finally {
        this.isLoading = false;
      }
    },

    onRowClicked(item, index, event) {
      this.selectedEpochInfo = item.epoch_info;
      this.showModal = true;
    },

    // formatDate(date) {
    //   if (!date) return '';
    //   return new Date(date).toLocaleString(); // Customize the format as needed
    // },
  },
};
</script>

<style scoped>
tbody tr:hover {
  cursor: pointer;
}
</style>