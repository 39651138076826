<template>
  <div class="p-3 container-xl">
    <div class="card">
      <div class="card-header">
        <h2>Coinflow webhooks</h2>
      </div>

      <div class="card-body">
        <coinflow-webhook-requests-table />
      </div>
    </div>
  </div>
</template>

<script>
import coinflowWebhookRequestsTable from './coinflow-webhook-requests-table';

export default {
  components: { coinflowWebhookRequestsTable }
}
</script>
