<template>
  <div v-if="showInfoBanner" class="alert alert-primary m-3">
    <b-icon-info-circle-fill class="mr-2"></b-icon-info-circle-fill>
    <span v-if="currentUserAccounts.length === 0">
      This user has no accounts. Please contact us to create an account for this user.
    </span>
    <span v-else-if="currentUserSubscriptions.length === 0">
      This user has no subscriptions. Please contact us to create subscriptions for this user.
    </span>
    <span v-else-if="showHelioPaymentLink">
      Next Step:
      <a :href="helioPayStreamLink" target="_blank">
        Submit payment with Hel.io to activate your RPC
      </a>
    </span>
    <span v-else-if="showCoinflowPaymentLink">
      Next Step:
      <a :href="coinflowLink" target="_blank">
        {{ coinflowMessage }}
      </a>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'InfoBanner',

  computed: {
    ...mapState('sessions', [
      'currentUserAccounts',
      'currentUserSubscriptions'
    ]),

    ...mapGetters('sessions', [
      'currentUser',
      'helioPayStreamLink',
      'coinflowLink',
      'currentUserHelioAccounts',
      'coinflowAccounts'
    ]),

    showHelioPaymentLink() {
      if (this.currentUserAccounts.length > 0 && this.currentUserHelioAccounts.length > 0) {
        // does this user have any helio accounts that are not active?
        return this.currentUserHelioAccounts.some(account => !account.is_helio_pay_stream_active);
      }
    },

    showCoinflowPaymentLink() {
      return this.hasInactiveCoinflowAccount || this.hasUnauthorizedCoinflowAccount;
    },

    hasInactiveCoinflowAccount() {
      return this.coinflowAccounts.some(account => !account.is_active);
    },

    hasUnauthorizedCoinflowAccount() {
      return this.coinflowAccounts.some(account => account.is_coinflow_and_has_not_authorized);
    },

    showInfoBanner() {
      if (this.currentUser && !(this.currentUser.is_admin || this.currentUser.is_reseller)) {
        return this.currentUserAccounts.length === 0 || this.currentUserSubscriptions.length === 0 || this.showHelioPaymentLink || this.showCoinflowPaymentLink;
      }
    },

    coinflowMessage() {
      if (this.hasInactiveCoinflowAccount) {
        return 'Submit payment to activate your RPC';
      } else if (this.hasUnauthorizedCoinflowAccount) {
        return 'Submit payment to keep your RPC active';
      }
    }
  },
}
</script>
