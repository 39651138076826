<template>
  <div class="p-3 container-xl">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h2>Accounts</h2>

        <div class="text-center">
          <router-link to="/accounts/new-onboard" class="btn btn-primary">
            Onboard
          </router-link>
          <b-button v-b-modal="'modal-merge-accounts'" class="btn btn-primary">
            Merge
          </b-button>
          <b-button v-b-modal="'modal-create-new-account'" class="btn btn-primary">
            New
          </b-button>
        </div>
      </div>

      <merge-accounts-modal />
      <create-new-account-modal />

      <div class="card-body">
        <accounts-table />
      </div>
    </div>
  </div>
</template>

<script>
import mergeAccountsModal from '../../globals/merge-accounts-modal';
import createNewAccountModal from '../../globals/create-new-account-modal';
import accountsTable from './accounts-table'

export default {
  name: 'AdminAccounts',

  components: { mergeAccountsModal, createNewAccountModal, accountsTable }
}
</script>
