<template>
  <b-modal
    v-if="uuid && versions.length"
    centered
    class="p-3"
    :id="'versions-modal-' + uuid"
    header-bg-variant="light"
    header-class="py-3"
    size="lg"
  >
    <template #modal-title>
      <h4>{{ title }} versions</h4>
    </template>

    <b-list-group class="scroll">
      <b-list-group-item
        v-for="(version, index) in versionsReversed"
        :key="index"
        class="flex-column align-items-start"
        :variant="index % 2 === 0 ? 'secondary' : ''"
      >
        <div v-if="whodunnit(version)" class="pt-1 d-flex w-100 justify-content-between">
          <div v-if="typeof whodunnit(version) === 'string'">
            Made by: <b>{{ whodunnit(version)}} </b>
          </div>

          <div v-else>
            Made by:
            <router-link :to="{ name: 'manageUserRpc', params: { uuid: whodunnit(version).uuid } }">
              {{ whodunnit(version).username }}
            </router-link>
          </div>

          {{ parseTimestamp(foundVersion(version).created_at) }}
        </div>
        <div class="pt-1 d-flex w-100">
          <span>Event: <b>{{ foundVersion(version).event }}</b></span>
        </div>
        <hr>
        <div class="pb-1 d-flex w-100 justify-content-between">
          <ul v-if="Object.keys(objectChanges(version)).length > 0" class="pl-3">
            <li v-for="(value, key) in objectChanges(version)" :key="key">
              <strong>{{ key }}: </strong>

              <span v-if="isTimestamp(value[0])">
                <code>{{ parseTimestamp(value[0].utc) }}</code>
              </span>
              <span v-else>
                <code>{{ parseNullAndEmptyString(value[0]) }}</code>
              </span>

              <strong> to </strong>

              <span v-if="isTimestamp(value[1])">
                <code>{{ parseTimestamp(value[1].utc) }}</code>
              </span>
              <span v-else>
                <code>{{ parseNullAndEmptyString(value[1]) }}</code>
              </span>
            </li>
          </ul>
          <span v-else>No changes were recorded for this event</span>
        </div>
      </b-list-group-item>
    </b-list-group>

    <template #modal-footer>
      <b-button variant="secondary" @click="closeModal">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<style scoped>
.list-group-item.list-group-item-secondary {
  background-color: #f9fafd !important;
}
.scroll {
  overflow: scroll;
  max-height: 500px;
}
.text-success {
  color: #1d7a32 !important;
}
</style>

<script>
import dayjs from 'dayjs'
import jsyaml from 'js-yaml'

export default {
  name: 'DeactivateModal',

  props: {
    versions: {
      type: Array,
      required: false,
      default: () => ([])
    },
    uuid: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    }
  },

  computed: {
    versionsReversed() {
      return this.$clone(this.versions).reverse();
    }
  },

  methods: {
    closeModal() {
      this.$bvModal.hide(`versions-modal-${this.uuid}`);
    },

    objectChanges(version) {
      try {
        const foundVersion = this.foundVersion(version)
        let changes = {}

        if (foundVersion.object_changes) {
          changes = { ...changes, ...jsyaml.load(this.parseChanges(foundVersion.object_changes)) };
        }

        if (foundVersion.associations_changes) {
          changes = { ...changes, ...jsyaml.load(this.parseChanges(foundVersion.associations_changes)) };
        }

        return changes;
      } catch (error) {
        console.log('Error processing YAML data:', error);
        return {};
      }
    },

    foundVersion(version) {
      return version.search_results || version;
    },

    whodunnit(version) {
      return this.foundVersion(version).whodunnit;
    },

    isTimestamp(value) {
      return value && value.hasOwnProperty('utc');
    },

    parseTimestamp(timestamp) {
      return dayjs(timestamp).format('YYYY-MM-DD h:mm A Z');
    },

    parseChanges(changes) {
      return changes.replace(/!\S+:\S+/g, '');
    },

    parseNullAndEmptyString(value) {
      if (value === '') {
        return "(empty string)"
      } else if (value === null) {
        return "null"
      } else {
        return value;
      }
    }
  }
}
</script>
