<template>
  <div>
    <div class="table-responsive scrollbar">
      <b-form v-on:submit.prevent="searchTokens">
        <div class="form-group">
          <b-input v-model="searchTokensTerm" placeholder="Starts with…" type="search" />
        </div>
      </b-form>

      <b-form-checkbox
        @change="setHideDisabledStatus"
        v-model="hideDisabled"
        name="check-button"
        switch size="md"
        class="ml-1 mb-3"
      >
        Hide disabled
      </b-form-checkbox>

      <table class="table table-striped overflow-hidden">
        <thead>
          <tr class="btn-reveal-trigger">
            <th>Name</th>
            <th>Username</th>
            <th>Tier</th>
            <th>Status</th>
            <th v-if="canManageTokenActivation"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="token in displayedTokens" :key="token.uuid">
            <td>
              <b-link @click="$bvModal.show(`token-modal-${token.uuid}`)">
                {{ token.name }}
              </b-link>
            </td>
            <td>
              <b-link @click="$bvModal.show(`token-modal-${token.uuid}`)">
                {{ token.auth_username }}
              </b-link>
            </td>
            <td>{{ token.rate_tier }}</td>
            <td>
              <span
                v-if="token.is_active"
                class="badge"
                :class="isAccountAndSubscriptionActive ? 'badge-soft-success' : 'badge-soft-secondary'"
              >
                Active
              </span>
              <span
                v-else
                class="badge"
                :class="isAccountAndSubscriptionActive ? 'badge-soft-danger' : 'badge-soft-secondary'"
              >
                Inactive
              </span>
            </td>
            <td v-if="canManageTokenActivation" class="text-right" id="token-actions">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                size="sm"
                boundary="viewport"
              >
                <template #button-content>
                  <b-icon-three-dots></b-icon-three-dots>
                </template>

                <b-dropdown-item @click="showTokenActivationModal(token)">
                  <span v-if="token.is_active" class="text-danger">Deactivate</span>
                  <span v-else class="text-success">Activate</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="hasTokenDeactivationReasons(token)"
                  v-b-modal="'deactivation-reasons-modal-' + token.uuid"
                >
                  <span class="text-secondary">Deactivation reasons</span>
                </b-dropdown-item>

                <b-dropdown-item @click="copyConnectionStringToClipboard(token)">
                  <span class="text-secondary">Copy connection string</span>
                </b-dropdown-item>
              </b-dropdown>

              <activate-modal
                resource-name="token"
                :uuid="token.uuid"
                v-on:activated="toggleTokenActivation"
              />

              <deactivate-modal
                resource-name="token"
                :uuid="token.uuid"
                v-on:deactivated="setTokenDeactivationReasonAndToggleTokenActivation"
              />

              <deactivation-reasons-modal
                title="Token"
                :uuid="token.uuid"
                :deactivationReasons="token.deactivation_reasons"
              />
            </td>

            <edit-token-modal
              :key="token.uuid"
              :subscription="subscription"
              :isAccountAndSubscriptionActive="isAccountAndSubscriptionActive"
              :token="token"
              :supported-rate-tiers="supportedRateTiers"
              v-on:token-updated="updateTokenState"
            />
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="totalPages > 1" class="overflow-auto">
      <b-pagination-nav
        :link-gen="linkGen"
        v-model="currentPage"
        :number-of-pages="totalPages"
        use-router
      />
    </div>

    <p class="fs--1">
      {{ totalCount }} {{ totalCount === 1 ? 'token' : 'tokens' }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import copyToClipboard from '@/mixins/copy-to-clipboard';
import editTokenModal from './edit-token-modal';

export default {
  name: 'TokensTable',

  mixins: [copyToClipboard],

  components: { editTokenModal },

  props: {
    supportedRateTiers: {
      type: Array,
      default: () => ([])
    },

    subscription: {
      type: Object,
      required: true
    },

    tokens: {
      type: Array,
      required: false
    },

    endpoints: {
      type: Array,
      required: true
    },

    isAccountAndSubscriptionActive: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },

  watch: {
    tokens: 'setFilteredTokens',
    searchTokensTerm: 'searchTokens',
    hideDisabled: 'jumpToFirstPage'
  },

  data() {
    return {
      searchTokensTerm: null,
      filteredTokens: [],
      hideDisabled: true,
      perPage: 10,
      currentPage: 1,
      meta: {},
      isLoading: false,
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapGetters('sessions', ['canManageTokenActivation']),

    totalCount() {
      return this.hideDisabled
        ? this.filteredTokens.filter(token => token.is_active).length
        : this.filteredTokens.length;
    },

    totalPages() {
      return Math.ceil(Math.max(this.totalCount, 1) / this.perPage);
    },

    displayedTokens() {
      if (this.filteredTokens) {
        const startIndex = (this.currentPage - 1) * this.perPage;
        const endIndex = startIndex + this.perPage;

        return this.hideDisabled
          ? this.filteredTokens.filter(token => token.is_active).slice(startIndex, endIndex)
          : this.filteredTokens.slice(startIndex, endIndex);
      }
    },
  },

  methods: {
    async onCreated() {
      this.setFilteredTokens();
      this.getHideDisabledStatus();

      if (this.$route.query.tokens_page) {
        this.currentPage = parseInt(this.$route.query.tokens_page);
      }
    },

    setFilteredTokens() {
      this.filteredTokens = this.$clone(this.tokens);
    },

    getHideDisabledStatus() {
      const savedStatus = localStorage.getItem("hideDisabledTokens");

      if (savedStatus) {
        this.hideDisabled = savedStatus === 'true';
      }
    },

    setHideDisabledStatus() {
      localStorage.setItem("hideDisabledTokens", this.hideDisabled);

      this.jumpToFirstPage();
    },

    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?tokens_page=${pageNum}`;
    },

    updateTokenState(updatedToken) {
      const token = this.filteredTokens.find(t => t.uuid === updatedToken.uuid);
      token.name = updatedToken.name;
      token.auth_username = updatedToken.auth_username;
      token.rate_tier = updatedToken.rate_tier;
      token.is_active = updatedToken.is_active;
    },

    showTokenActivationModal(token) {
      if (token.is_active) {
        this.$bvModal.show(`deactivate-modal-${token.uuid}`);
      } else {
        this.$bvModal.show(`activate-modal-${token.uuid}`);
      }
    },

    toggleTokenActivation(uuid) {
      const account = this.filteredTokens.find(entry => entry.uuid === uuid);
      const i = this.filteredTokens.indexOf(account);
      this.filteredTokens[i].is_active = !this.filteredTokens[i].is_active;
    },

    setTokenDeactivationReasonAndToggleTokenActivation(reason, uuid) {
      const token = this.filteredTokens.find(entry => entry.uuid === uuid);
      const i = this.filteredTokens.indexOf(token);
      this.filteredTokens[i].deactivation_reasons.push(reason);

      this.toggleTokenActivation(uuid);
    },

    hasTokenDeactivationReasons(token) {
      return token.deactivation_reasons?.length;
    },

    searchTokens() {
      this.jumpToFirstPage();

      this.filteredTokens = this.tokens.filter(token =>
        token.name.toLowerCase().includes(this.searchTokensTerm.toLowerCase())
          || token.value.startsWith(this.searchTokensTerm)
      );
    },

    jumpToFirstPage() {
      this.currentPage = 1;

      if (String(this.$route.query.tokens_page) !== String(this.currentPage)) {
        this.$router.replace({ name: this.$route.name, query: { tokens_page: this.currentPage } });
      }
    },

    copyConnectionStringToClipboard(token) {
      const endpoint = this.endpoints.find(endpoint => endpoint.is_active && endpoint.values.length > 0);
      this.$_copyToClipboard('https://' + endpoint.values[0] + '/' + token.value);
    },
  }
}
</script>
