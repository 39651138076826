<template>
  <div v-if="hasActiveEndpoints">
    <div class="mt-4 mb-3">
      <h5 class="d-inline">Endpoints</h5>
      <span class="fs--1 ml-3">(For public-facing apps from allowed origins)</span>
    </div>

    <p v-for="endpoint in paginatedEndpoints" class="text-monospace fs--1">
      <span v-if="endpoint.name" class="font-weight-bold">{{ endpoint.name }}</span>
      https://{{ endpoint.value }}
      <b-button class="btn clipboard-btn" @click="$_copyToClipboard('https://' + endpoint.value)">
        <b-icon icon="clipboard"></b-icon>
      </b-button>
      <span v-if="endpoint.origins" class="fs--2">({{ endpoint.origins }})</span>
    </p>

    <div v-if="countPages > 1">
      <b-pagination-nav
        base-url="#"
        v-model="currentPage"
        :number-of-pages="countPages"
        use-router
      />

      <p class="fs--1">{{ activeEndpoints.length }} Endpoints</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rpc-card.card {
  .btn.clipboard-btn {
    padding: 5px;
    color: #5e6e82;
    border: none;
    background-color: inherit;
  }
}
</style>

<script>
import copyToClipboard from '@/mixins/copy-to-clipboard';
import rpcFormattedEndpoints from '@/mixins/rpc-formatted-endpoints';

export default {
  name: 'rpcTokens',

  mixins: [rpcFormattedEndpoints, copyToClipboard],

  props: {
    subscriptions: { type: Array, default: () => [] },
  },

  data() {
    return {
      tokensPaginationData: [],
      currentPage: 1,
      perPage: 8,
      rpcExpanded: true,
    }
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },

    endIndex() {
      return this.startIndex + this.perPage;
    },
    hasActiveEndpoints() {
      return this.activeEndpoints.length > 0
    },

    activeEndpoints() {
      return this.collectActiveEndpoints(this.subscriptions);
    },

    paginatedEndpoints() {
      return this.activeEndpoints.slice( this.startIndex, this.endIndex);
    },

    countPages() {
      return Math.ceil(this.activeEndpoints.length / this.perPage);
    },
  },
}
</script>
