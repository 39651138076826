<template>
  <div class="container">
    <div class="row flex-center py-6">
      <div class="login-card-width">
        <div class="card">
          <div class="logo-container">
            <triton-logo :height="85"></triton-logo>
            <p>
              <span class="logo-text">
                Experience the fastest, most reliable, <br />full-serviceRPC.
              </span>
            </p>
          </div>

          <div v-if="!isSystemFeaturesLoaded" class="d-flex justify-content-center p-5">
            <b-spinner label="Loading..." style="width: 3rem; height: 3rem" />
          </div>

          <div v-else>
            <div class="indicator-container d-flex justify-content-center">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div :class="classesForStep(1)"></div>
                </div>
                <div class="d-flex align-items-center">
                  <div :class="classesForStep(2)"></div>
                </div>
                <div class="d-flex align-items-center">
                  <div :class="classesForStep(3)"></div>
                </div>
              </div>
            </div>

            <div class="card-body p-4">
              <div class="row d-flex flex-center">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div v-if="currentStep == 1">
                    <b-alert v-if="hackathon && !confirmStep" show variant="primary">
                      Triton is proud to provide free Devnet RPC and our private Devnet faucet for
                      all hackathon participants. Sign up below and get started in minutes.
                    </b-alert>

                    <!-- <h4 class="d-flex flex-center">Get started</h4> -->

                    <transition name="shake" mode="out-in">
                      <b-alert v-if="confirmStep" show variant="primary" class="alert-two">
                        <h5>Confirm Email 💌</h5>
                        <p v-if="user">
                          Your account is not confirmed. Find the email where you received the
                          confirmation token or
                          <router-link to="/users/confirmation/new">
                            resend confirmation instructions
                          </router-link>
                          again.
                        </p>
                        <p v-else>
                          We just sent an email to the provided address. Let's get you confirmed!
                        </p>
                      </b-alert>
                    </transition>

                    <div v-if="queryToken && isLoading">
                      <div class="text-center mt-4">
                        <b-spinner class="align-middle mr-3"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </div>
                    <div v-else>
                      <b-form v-on:submit.prevent="createOrConfirmUser" class="mt-3">
                        <b-form-group>
                          <label for="email">Email</label>
                          <b-form-input
                            :disabled="confirmStep"
                            placeholder="Email address"
                            v-model="email"
                            :state="stateFor('email')"
                            :autofocus="!confirmStep && !queryToken"
                            id="email"
                            required
                          />
                          <b-form-invalid-feedback :state="stateFor('email')">
                            {{ errorsFor('email') }}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                          <label for="username">Username</label>
                          <b-form-input
                            :disabled="confirmStep"
                            placeholder="Username"
                            v-model="username"
                            :state="stateFor('username')"
                            id="username"
                            required
                          />
                          <b-form-invalid-feedback :state="stateFor('username')">
                            {{ errorsFor('username') }}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group v-if="!queryToken && !user">
                          <label for="password">Password</label>
                          <b-form-input
                            :disabled="confirmStep"
                            placeholder="Password"
                            v-model="password"
                            :state="stateFor('password')"
                            type="password"
                            id="password"
                            required
                          />
                          <b-form-invalid-feedback :state="stateFor('password')">
                            {{ errorsFor('password') }}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group v-if="confirmStep">
                          <label for="confirmtoken">Confirmation Token</label>
                          <b-form-input
                            placeholder="Confirmation token"
                            v-model="confirmationToken"
                            :state="stateFor('confirmation_token')"
                            autofocus
                            required
                          />
                          <b-form-invalid-feedback :state="stateFor('confirmation_token')">
                            {{ errorsFor('confirmation_token') }}
                          </b-form-invalid-feedback>
                        </b-form-group>

                        <b-button
                          class="primary-button d-block w-100"
                          :disabled="isLoading"
                          type="submit"
                        >
                          <span v-if="isLoading">
                            <b-spinner small></b-spinner>
                            <span class="sr-only">Loading...</span>
                          </span>
                          <span class="emboss" v-else>Get Started</span>
                        </b-button>
                      </b-form>
                    </div>
                  </div>

                  <div v-if="currentStep == 2">
                    <b-alert v-if="user" show variant="primary" class="alert-two">
                      <h5>Please complete registration</h5>
                      <p>
                        You have not completed the registration process, please add a name and proceed.
                      </p>
                    </b-alert>

                    <div class="text-container mt-4">
                      <h5 class="headline mb-2">Add a company or name</h5>
                    </div>
                    <b-form v-on:submit.prevent="createAccount" class="mt-3">
                      <b-form-group>
                        <b-form-input
                          placeholder="Your name or company name"
                          v-model="name"
                          :state="stateFor('name')"
                          autofocus
                          required
                        />

                        <b-form-invalid-feedback :state="stateFor('name')">
                          {{ errorsFor('name') }}
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <div v-if="!hackathon" class="mt-4">
                        <div class="text-container mt-2">
                          <h5 class="headline mb-2">Choose a payment method</h5>
                        </div>
                        <b-form-group class="mt-1">
                          <div class="checkbox mb-3" @click="paymentMethod = 'coinflow'" v-if="systemFeatures.coinflow_payments_enabled">
                            <div class="checkbox-item">
                              <div>
                                <div class="d-flex">
                                  <b-icon icon="credit-card-fill" class="icon"/>
                                  <span>Credit Card</span>
                                </div>
                                <p class="checkbox-text">
                                  Create a recurring subscription using a credit card.
                                </p>
                              </div>
                              <b-icon v-if="paymentMethod === 'coinflow'" icon="check-circle-fill" class="icon-checked"/>
                              <div v-else class="icon-unchecked-credit"></div>
                            </div>
                          </div>

                          <div class="checkbox" @click="paymentMethod = 'helio'">
                            <div class="checkbox-item">
                              <div>
                                <div class="d-flex">
                                  <b-icon icon="coin" class="icon"/>
                                <span>USDC</span>
                              </div>
                                <p class="checkbox-text">
                                  Pay now with a Web3 wallet for as many months as you want.
                                </p>
                              </div>
                              <b-icon v-if="paymentMethod === 'helio'" icon="check-circle-fill" class="icon-checked"/>
                              <div v-else class="icon-unchecked"></div>
                            </div>
                          </div>
                        </b-form-group>
                      </div>

                      <b-button
                        class="primary-button d-block w-100"
                        :disabled="isLoading"
                        type="submit"
                      >
                        <span v-if="isLoading">
                          <b-spinner small></b-spinner>
                          <span class="sr-only">Loading...</span>
                        </span>
                        <span v-else class="emboss">Cointinue</span>
                      </b-button>
                    </b-form>
                  </div>

                  <div v-if="currentStep == 3">
                    <b-alert v-if="user" show variant="primary" class="alert-two">
                      <h5>Please complete registration</h5>
                      <p>
                        You have not completed the registration process, please select network and proceed.
                      </p>
                    </b-alert>

                    <onboard-step-three :hackathon="hackathon" :account="account" />
                  </div>

                  <div>
                    <p class="policy-text">
                      <span v-if="this.confirmStep">
                        Didn't receive confirmation instructions?
                        <router-link to="/users/confirmation/new">Resend them now</router-link>.
                      </span>
                      By signing up you agree to our <a href="https://triton.one/legal/" target="_blank">Terms of Service</a>, <a href="https://triton.one/legal/" target="_blank">Privacy</a>, and <a href="https://triton.one/legal/" target="_blank">Cookie</a> policies.
                    </p>
                  </div>

                  <div class="d-flex justify-content-center">
                    <div class="w-100 d-flex justify-content-between">
                      <hr class="divider-right align-self-center" />
                      <span class="section-text">Already have an account?</span>
                      <hr class="divider-left align-self-center" />
                    </div>
                  </div>

                  <div class="d-flex justify-content-center">
                    <router-link to="/users/sign-in" class="secondary-btn">Login</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import http from '@/services/http';

import onboardStepThree from '@/components/pages/users/onboard-step-three';

export default {
  name: 'CreateUser',

  components: {
    onboardStepThree,
  },

  data() {
    return {
      email: null,
      username: null,
      password: null,
      confirmationToken: null,
      name: null,
      paymentMethod: 'coinflow',
      isLoading: false,
      currentStep: 1,
      errors: {},
      confirmStep: false,
      account: null,
    };
  },

  watch: {
    defaultPaymentMethod() {
      this.paymentMethod = this.defaultPaymentMethod;
    },
  },

  computed: {
    ...mapGetters('sessions', ['onboardingUser']),
    ...mapState(['systemFeatures']),

    user() {
      return this.onboardingUser;
    },

    hackathon() {
      return this.$route.query.hackathon;
    },

    queryToken() {
      return this.$route.query.confirmation_token;
    },

    isSystemFeaturesLoaded() {
      return !!this.systemFeatures;
    },

    defaultPaymentMethod() {
      return this.systemFeatures?.coinflow_payments_enabled ? 'coinflow' : 'helio';
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    async onCreated() {
      if (this.queryToken) {
        this.isLoading = true;
        this.confirmationToken = this.queryToken;

        try {
          const response = await http.get(
            `users/confirmations?confirmation_token=${this.confirmationToken}`
          );

          this.$store.commit('sessions/SET_ONBOARDING_USER', response.data.user);
        } catch (error) {
          console.log(error);

          this.$bvToast.toast(`Confirmation token is invalid or user does not exists`, {
            title: 'Error',
            variant: 'danger',
          });

          this.errors = error.data.errors;
        } finally {
          this.isLoading = false;
        }
      }

      if (this.user) {
        if (this.user.subscriptions.length > 0) {
          this.visitSignInPage();
        } else {
          this.setCurrentStep();
        }
      }
    },

    setCurrentStep() {
      if (this.user.confirmed_at) {
        if (this.user.accounts.length > 0) {
          this.account = this.user.accounts[0];
          this.currentStep = 3;
        } else {
          this.currentStep = 2;
        }
      } else {
        this.username = this.user.username;
        this.email = this.user.email;
        this.confirmStep = true;
        this.currentStep = 1;
      }
    },

    visitSignInPage() {
      this.$router.push({
        name: 'signIn',
        params: { recoveryMsg: 'Account already onboarded. Please log in using your username and password.' }
      });
    },

    stateFor(field) {
      if (this.errors && this.errors[field]) {
        return false;
      }
    },

    errorsFor(field) {
      if (this.errors) {
        const errors = this.errors[field];
        if (errors) {
          return errors.join(', ');
        }
      }
    },

    classesForStep(step) {
      const baseClasses = 'number-circle d-flex justify-content-center align-items-center';

      if (step === this.currentStep) {
        return baseClasses + ' active-step';
      } else {
        return baseClasses;
      }
    },

    async createOrConfirmUser() {
      if (this.confirmStep) {
        return await this.confirmUser();
      } else {
        return await this.createUser();
      }
    },

    async createUser() {
      this.errors = {};
      this.isLoading = true;

      // We need to sign the user out if they already had a session because UserFactory keys
      // off current_user to determine how to create the user.
      try {
        await http.delete('sessions');
      } catch (error) {}

      try {
        await http.post('users', {
          user: {
            email: this.email,
            username: this.username,
            password: this.password,
            created_via: this.hackathon ? 'hackathon' : 'self_signup',
          },
        });

        this.confirmStep = true;
      } catch (error) {
        console.log(error);

        this.$bvToast.toast(`There was an error submitting your request`, {
          title: 'Error',
          variant: 'danger',
        });

        this.errors = error.data.errors;
      } finally {
        this.isLoading = false;
      }
    },

    async confirmUser() {
      this.errors = {};
      this.isLoading = true;

      try {
        const response = await http.patch(`users/confirmations/${this.confirmationToken}/confirm`);
        this.$store.commit('sessions/SET_ONBOARDING_USER', response.data.user);
        this.confirmStep = false;
        this.currentStep = 2;
      } catch (error) {
        console.log(error);

        this.$bvToast.toast(`There was an error submitting your request`, {
          title: 'Error',
          variant: 'danger',
        });

        this.errors = error.data.errors;
      } finally {
        this.isLoading = false;
      }
    },

    async createAccount() {
      this.errors = {};
      this.isLoading = true;

      try {
        if (this.user.created_via === 'hackathon' || this.hackathon) {
          this.paymentMethod = 'manual';
        }

        const response = await http.post('accounts', {
          account: {
            name: this.name,
            user_uuid: this.user.uuid,
            payment_method: this.paymentMethod,
          },
        });

        this.account = response.data.account;
        this.$store.commit('sessions/ADD_ONBOARDING_USER_ACCOUNT', this.account);

        this.currentStep = 3;
      } catch (error) {
        console.log(error);

        this.$bvToast.toast(`There was an error submitting your request`, {
          title: 'Error',
          variant: 'danger',
        });

        this.errors = error.data.errors;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
label[for='confirmtoken'],
label[for='email'],
label[for='username'],
label[for='password'] {
  opacity: 0.5;
}

.alert-two {
  padding: 15px;
  background-color: #edefff;
  color: #8e8f99;
  border-radius: 18px;
  border: 2px dashed #d5d7e5;
  margin-top: 15px;
}

::v-deep .form-control {
  background-color: #f6f7ff;
  border: 1px solid #dddee5;
  border-radius: 10px;
  height: 45px;
}
</style>
