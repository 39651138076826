<template>
  <div>
    <h2>Active subscriptions</h2>
    <b-form-group>
      <v-select
        v-model="subscriptionType"
        :options="subscriptionTypesDropdownOptions"
        placeholder="Select a subscription type"
        :clearable="true"
      />
    </b-form-group>
    <div v-if="isLoading" class="chart-loading text-center my-4">
      <b-spinner class="align-middle mr-3"></b-spinner>
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <v-chart
        v-if="chartDataLoaded"
        :option="chartOptions"
        autoresize
        style="height: 400px;"
      />
    </div>
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';
import { mapState } from 'vuex';
import http from '@/services/http';
import { sharedOptions } from '@/services/metrics';

export default {
  name: 'SubscriptionsTab',
  components: { 'v-chart': VueECharts },

  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    lastSubmitted: { type: Number, required: true },
  },

  watch: {
    subscriptionType() {
      this.fetchSubscriptionSummaries();
    },
  },

  data() {
    return {
      chartDataLoaded: false,
      isLoading: false,
      dailySubscriptionSummaries: [],
      subscriptionType: null,
      sharedOptions
    }
  },

  watch: {
    lastSubmitted(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Re-fetch when the parent form is submitted
        this.fetchSubscriptionSummaries();
      }
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    ...mapState({
      subscriptionTypes: state => state.subscriptionTypes.subscriptionTypes
    }),

    subscriptionTypesDropdownOptions() {
      if (this.subscriptionTypes.length) {
        return this.subscriptionTypes.map(st => ({ label: st.name, value: st.uuid }));
      } else {
        return [];
      }
    },

    chartOptions() {
      return {
        tooltip: {
          trigger: 'axis',
          formatter: this.sharedOptions.tooltipOptions
        },
        dataset: { source: this.chartData, dimensions: ['date', 'num_active_subscriptions'] },
        xAxis: { type: 'category' },
        yAxis: { type: 'value' },
        grid: {
          top: 110,
          left: 75,
          right: 15,
          height: 160
        },
        textStyle: this.sharedOptions.textStyle,
        series: [
          {
            name: 'active subscriptions: ',
            type: 'bar',
            encode: { x: 'timestamp', y: 'value' },
            areaStyle: { color: '#00f', opacity: 0.5 },
            showSymbol: false,
          }
        ]
      };
    },

    chartData() {
      return this.dailySubscriptionSummaries.map((summary) => {
        return {
          date: summary.date,
          num_active_subscriptions: summary.active_subscriptions_count,
        }
      });
    },

    selectedSubscriptionTypeId() {
      if (this.subscriptionType) {
        return this.subscriptionType.id;
      } else {
        return null;
      }
    },
  },

  methods: {
    async onCreated() {
      await this.fetchSubscriptionSummaries();

      if (this.subscriptionTypes?.length === 0) {
        await this.$store.dispatch('subscriptionTypes/fetchSubscriptionTypes');
      }
    },

    async fetchSubscriptionSummaries() {
      try {
        this.isLoading = true;
        this.chartDataLoaded = false;

        const params = { start_date: this.startDate, end_date: this.endDate };
        if (this.subscriptionType) {
          params.subscription_type_uuid = this.subscriptionType.value;
        }

        const response = await http.get('/admin/daily_subscription_summaries', { params });
        this.dailySubscriptionSummaries = response.data.daily_subscription_summaries;
        this.chartDataLoaded = true;
      }
      finally {
        this.isLoading = false;
      }
    },
  },
}
</script>
