<template>
  <div>
    <div class="row flex-between-center pl-3 pr-4">
      <div class="alert alert-primary" role="alert">
        <b-icon-info-circle-fill class="mr-2"></b-icon-info-circle-fill>
        Your RPC is currently inactive. You can submit payment via Hel.io, a
        Web3 payment service we've partnered with. You can activate by
        clicking
        <a class="font-weight-bolder" :href="helioPayStreamLink" target="_blank">
          here
        </a>
        or by clicking the link in the banner. You can also learn more about
        Hel.io by visiting our
        <a class="font-weight-bolder" href="https://docs.triton.one/docs/helio" target="_blank">
          internal help site
        </a>
        .
      </div>
    </div>

    <div class="modal-footer">
      <a
        :href="helioPayStreamLink"
        target="_blank"
        class="btn btn-primary btn-sm"
        variant="primary"
      >
        Submit payment with Hel.io
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HelioPaymentModal',

  computed: {
    ...mapGetters('sessions', ['helioPayStreamLink'])
  }
}
</script>
