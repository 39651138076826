import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    roles: []
  },

  getters: {
    all(state) {
      if (state.roles.length > 0) {
        return state.roles;
      }
    },
  },

  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
  },

  actions: {
    async fetchRoles({ commit, getters }) {
      try {
        let cachedRoles = getters.all;

        if (cachedRoles) {
          return cachedRoles;
        } else {
          const response = await http.get('roles');
          commit('SET_ROLES', response.data.roles);
          return response.data.roles;
        }
      }

      catch (error) {
        console.log(error);
      }
    }
  }
}
