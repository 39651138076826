const DEFINITIONS = {
  standard: 'Read-only access.',
  operator: 'All abilities of Standard. Can also create RPC Tokens, Address Watch Lists, and REST API Tokens.',
  reseller: 'All abilities of Operator. Can also create Subscriptions and Endpoints for RPC access.',
  admin: 'Can perform all actions.'
};

const roleDefinitions = {
  methods: {
    getRoleDefinition(role) {
      return DEFINITIONS[role];
    }
  }
};

export default roleDefinitions;
