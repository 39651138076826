<template>
  <b-modal
    centered
    id="add-user-to-account"
    class="p-3"
    :hide-footer="true"
    size="lg"
    header-bg-variant="light"
    header-class="py-3"
    content-class="min-vh-90"
  >
    <template #modal-title>
      <h4>Add user to account</h4>
    </template>

    <div class="mb-2">
      <b-form v-on:submit.prevent="searchUsers">
        <div class="form-group">
          <b-input
            v-model="searchUsersTerm"
            placeholder="Search by username"
            type="search"
          ></b-input>
        </div>
      </b-form>
    </div>

    <div class="mb-2">
      <b-link v-b-modal.create-new-user>Create new user</b-link>
      <create-new-user-modal v-on:user-created="onUserCreated" />
    </div>

    <table class="table table-hover table-sm">
      <thead class="border-bottom">
        <tr>
          <th scope="col">Username</th>
          <th scope="col">Email</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="align-middle" v-for="user in allUsers">
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>
            <span v-if="user.is_admin" class="badge badge-soft-primary">
              Admin
            </span>
          </td>
          <td class='text-center'>
            <b-link v-if="accountHasUser(user)" @click="removeUserFromAccount(user, account)">
              <span v-if="addRemoveStatus[user.uuid] === 'loading'">
                <b-spinner small></b-spinner>
                <span class="sr-only">Loading...</span>
              </span>
              <span v-else>
                Remove
              </span>
            </b-link>
            <b-link v-else @click="addUserToAccount(user, account)">
              <span v-if="addRemoveStatus[user.uuid] === 'loading'">
                <b-spinner small></b-spinner>
                <span class="sr-only">Loading...</span>
              </span>
              <span v-else>
                Add
              </span>
            </b-link>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>

    <div class="modal-footer">
      <b-button variant="secondary" @click="closeAddUserToAccountModal">
        Done
      </b-button>
    </div>
  </b-modal>
</template>

<style>
  .min-vh-90 {
    min-height: 90vh;
  }
</style>

<script>
import http from '@/services/http';
import debounce from 'lodash.debounce';

export default {
  name: 'AddUsersToAccountModal',

  props: ['account', 'usersForAccount'],

  data() {
    return {
      allUsers: [],
      allUsersMeta: {},
      searchUsersTerm: null,
      currentPage: 1,
      perPage: 20,
      totalRows: null,
      addRemoveStatus: {}
    }
  },

  created() {
    this.onCreated();
  },

  watch: {
    searchUsersTerm: 'debouncedSearchUsers',
    currentPage: 'getAllUsers'
  },

  methods: {
    async onCreated() {
      await this.getAllUsers();
    },

    async onUserCreated() {
      await this.fetchUsers();
    },

    async fetchUsers() {
      try {
        const response = await http.get(`users?per=${this.perPage}&page=${this.value}`);
        this.allUsers = response.data.users;
        this.allUsersMeta = response.data.meta;
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    closeAddUserToAccountModal() {
      this.$bvModal.hide('add-user-to-account');
      this.currentPage = 1;
      this.searchUsersTerm = null;
    },

    async getAllUsers() {
      try {
        const response = await http.get(`users?per=${this.perPage}&page=${this.currentPage}`);
        this.allUsers = response.data.users;
        this.allUsersMeta = response.data.meta;
        this.totalRows = this.allUsersMeta.total_count;

        this.allUsers.forEach((u) => {
          this.$set(this.addRemoveStatus, u.uuid, null)
        });
      }

      catch (error) {
        console.log(error);
      }
    },

    debouncedSearchUsers: debounce(function () {
      this.searchUsers();
    }, 600),

    async searchUsers() {
      this.currentPage = 1;
      const response = await http.get(`users?per=${this.perPage}&username=${this.searchUsersTerm}`);
      this.allUsers = response.data.users;
    },

    accountHasUser(user) {
      const ids = this.usersForAccount.map(u => u.uuid);
      return ids.includes(user.uuid);
    },

    async addUserToAccount(user, account) {
      this.addRemoveStatus[user.uuid] = 'loading';

      try {
        await http.post('admin/accounts_users', {
          accounts_user: {
            account_uuid: account.uuid,
            user_uuid: user.uuid
          }
        });

        this.usersForAccount.push(user);
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.addRemoveStatus[user.uuid] = null;
      }
    },

    async removeUserFromAccount(user, account) {
      this.addRemoveStatus[user.uuid] = 'loading';

      try {
        await http.delete(`admin/accounts_users?account_uuid=${account.uuid}&user_uuid=${user.uuid}`);

        const uuids = this.usersForAccount.map(s => s.uuid);
        const i = uuids.indexOf(user.uuid);
        this.usersForAccount.splice(i, 1);
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.addRemoveStatus[user.uuid] = null;
      }
    },
  }
}
</script>
