<template>
  <b-modal
    centered
    class="p-3"
    :id="'sign-in-modal'"
    header-bg-variant="light"
    header-class="py-3"
    size="md"
    hide-footer
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-title>
      <h4>Log In</h4>
    </template>

    <sign-in-form :is-modal="true" />
  </b-modal>
</template>

<style scoped>
.modal-footer {
  margin: 0 -15px -15px -15px;
}
</style>

<script>
import http from '@/services/http';
import signInForm from './sign-in-form';

export default {
  name: 'SignInModal',

  components: { signInForm },
}
</script>
