<template>
  <div>
    <b-form @submit.prevent="submitForm" class="mb-4">
      <b-input-group prepend="Filter by">
        <b-form-select v-model="selected" :options="options" class="mr-2" />
        <b-form-input v-model="searchTerm" placeholder="Search" type="search" />
      </b-input-group>
    </b-form>

    <div class="table-responsive table-hover scrollbar">
      <table class="table table-sm">
        <thead class="border-bottom">
          <tr>
            <th>Event Type</th>
            <th>Email</th>
            <th>Account</th>
            <th>Customer ID</th>
            <th>Payment ID</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading" role="row">
            <td colspan="6">
              <div class="text-center my-4">
                <b-spinner class="align-middle mr-3"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </td>
          </tr>

          <tr
            v-for="r in coinflowWebhookRequests"
            :key="r.uuid"
            class="align-middle"
            @click="openModal(r)"
            style="cursor: pointer;"
          >
            <td>{{ r.event_type }}</td>
            <td>{{ r.email }}</td>
            <td>{{ r.account?.name }}</td>
            <td>{{ r.wallet }}</td>
            <td>{{ r.coinflow_id }}</td>
            <td>{{ formatDate(r.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal
      v-if="selectedRow"
      @hide="selectedRow = null"
      id="details-modal"
      centered
      size="lg"
      class="p-3"
      header-bg-variant="light"
      header-class="py-3"
      title="Coinflow event details"
      :ok-only="true"
      ok-title="Close"
    >
      <b-card-text>
        <div class="mb-3">
          <b>Account UUID: </b>
          <router-link :to="{ name: 'editAccount', params: { uuid: selectedRow.account_uuid } }">
            {{ selectedRow.account_uuid }}
          </router-link>
        </div>
        <div class="mb-3"><b>Account Name:</b> {{ selectedRow.account?.name }}</div>
        <div class="mb-3"><b>Email:</b> {{ selectedRow.email }}</div>
        <div class="mb-3"><b>Wallet/Customer ID:</b> {{ selectedRow.wallet }}</div>
        <div class="mb-3"><b>Payment ID:</b> {{ selectedRow.coinflow_id }}</div>
        <div class="mb-3"><b>Event Type:</b> {{ selectedRow.event_type }}</div>
        <div class="mb-3"><b>Processed At:</b> {{ formatDate(selectedRow.processed_at) }}</div>
        <div class="mb-3"><b>Created At:</b> {{ formatDate(selectedRow.created_at) }}</div>
        <div class="mb-3"><b>Updated At:</b> {{ formatDate(selectedRow.updated_at) }}</div>
        <div class="mb-3"><b>Body:</b></div>
        <pre class="json-body mb-3">{{ formatBody(selectedRow.body) }}</pre>
      </b-card-text>
    </b-modal>

    <div v-if="meta.total_pages > 1" class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        @change="changePage"
        :total-rows="meta.total_count"
        :per-page="per"
      />
    </div>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'CoinflowWebhookRequestsTable',

  data() {
    return {
      isLoading: false,
      searchTerm: null,
      selected: 'account_uuid',
      selectedRow: null,
      currentPage: 1,
      per: 25,
      meta: {},
      options: [
        { value: 'account_uuid', text: 'Account UUID' },
        { value: 'email', text: 'Email' },
        { value: 'event_type', text: 'Event Type' },
        { value: 'wallet', text: 'Wallet/Customer ID' },
        { value: 'coinflow_id', text: 'Payment ID' },
      ],
      coinflowWebhookRequests: [],
    }
  },

  created() {
    this.onCreated();
  },

  computed: {
    queryParams() {
      let base = `page=${this.currentPage}&per=${this.per}`;
      if (this.searchTerm) { base += `&${this.selected}=${this.searchTerm}`; }
      return base;
    },
  },

  methods: {
    async onCreated() {
      await this.fetchCoinflowWebhookRequests();
    },

    async submitForm() {
      this.currentPage = 1;
      await this.fetchCoinflowWebhookRequests();
    },

    async fetchCoinflowWebhookRequests() {
      try {
        this.isLoading = true;
        const response = await http.get(`/admin/coinflow_webhook_requests?${this.queryParams}`);
        this.coinflowWebhookRequests = response.data.coinflow_webhook_requests;
        this.meta = response.data.meta;
      } catch (error) {
        this.$bvToast.toast('Could not fetch any coinflow events', {
          variant: 'danger'
        });
      } finally {
        this.isLoading = false;
      }
    },

    async changePage(newPage) {
      this.currentPage = newPage;
      await this.fetchCoinflowWebhookRequests();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    formatBody(body) {
      try {
        return JSON.stringify(JSON.parse(body), null, 2)
      } catch (e) {
        return body
      }
    },

    formatDate(date) {
      if (!date) return ''
      return date.replace('T', ' ')
    },

    openModal(row) {
      this.selectedRow = row;

      this.$nextTick(() => {
        this.$bvModal.show('details-modal');
      });
    },

    closeModal() {
      this.$bvModal.hide('details-modal');
      this.selectedRow = null;
    },
  },
};
</script>
