<template>
  <div class="row">
    <div class="col-12">
      <h3 class='border-bottom pb-1 mt-3 mb-0'>Allowed Origins</h3>

      <ul class="list-unstyled">
        <div v-for="allowedOrigin in searchResults">
          <li class="p-3 border-bottom">
            <div class="row">
              <div class="col">
                <router-link :to="`/subscriptions/${allowedOrigin.subscription_uuid}`">
                  {{ allowedOrigin.value }}
                </router-link>
              </div>
              <div class="col">
                For endpoint:
                <router-link
                  :to="`/subscriptions/${allowedOrigin.subscription_uuid}`"
                  :focus="`${allowedOrigin.endpoint_uuid}`"
                >
                  {{ allowedOrigin.endpoint_name }}
                </router-link>
              </div>
              <div class="col">
                <div class="float-end text-right">
                  <span v-if="allowedOrigin.is_active" class="text-success">
                    Enabled
                  </span>
                  <span v-else class="text-danger">
                    Disabled
                  </span>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AllowedOriginSearchResults',

  props: {
    searchResults: [],
  }
}
</script>
